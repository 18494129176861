import clsx from 'clsx';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { BreadCrumbsInfo } from '../../interfaces/props';

import styles from './GradientHeader.module.scss';
interface GradientHeaderProps {
  title: string | React.ReactNode;
  subTitle?: string;
  hasImage?: boolean;
  imageUrl?: string;
  breadCrumbs?: boolean;
  breadCrumbsInfo?: BreadCrumbsInfo | null;
  isDrillIn?: boolean;
  children?: React.ReactNode;
}

const GradientHeader: FC<GradientHeaderProps> = ({
  title,
  subTitle,
  hasImage,
  breadCrumbs,
  breadCrumbsInfo,
  imageUrl,
  children,
  isDrillIn,
}): JSX.Element => {
  const history = useHistory();

  return (
    <div className={clsx(styles.container, isDrillIn && styles.drillIn)}>
      {breadCrumbs && breadCrumbsInfo && (
        <section
          className={clsx(
            styles.breadcrumbs,
            isDrillIn && styles.drillInMargin,
          )}
        >
          <span
            className={styles.previous}
            onClick={() => {
              history.push(breadCrumbsInfo.previousRoute);
            }}
          >
            {`${breadCrumbsInfo.previousLabel}`}
          </span>
          <span>{' > '}</span>
          <span className={styles.current}>{breadCrumbsInfo.currentLabel}</span>
        </section>
      )}
      <div
        className={clsx(styles.mainContent, isDrillIn && styles.drillInMargin)}
      >
        {hasImage && (
          <div className={styles.imageOuterContainer}>
            <div className={styles.imageContainer}>
              <img
                className={styles.brandLogo}
                src={
                  imageUrl ? imageUrl : '/assets/images/empty-brand-logo.svg'
                }
                alt=""
              />
            </div>
          </div>
        )}
        <div className={styles.headerTitleContainer}>
          <div>
            <span className={styles.header}>{title}</span>
          </div>
          <div>
            {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
          </div>
        </div>
        <div className={styles.childrenContainer}>{children}</div>
      </div>
    </div>
  );
};

export default GradientHeader;
