import { Redirect, Route } from 'react-router-dom';

import { useAuthValue } from '../services/Auth/Auth';
import Loading from '../ui/Loading/Loading';
import QueryError from '../ui/QueryError/QueryError';
import { getAdditionalOrganizations, getCurrentUser } from 'graphql/queries';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { GetCurrentUserQuery, GetCurrentUserQueryVariables } from 'API';

const AuthedRoute = ({ component: Component, ...rest }): JSX.Element => {
  const context = useAuthValue();
  const { pathname, search } = window.location;
  const from = `${pathname}${search}`;
  const params = new URLSearchParams(window.location.search);
  const talentIDParam = Number(params.get('talentID'));

  if (context.isSignedIn === false) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from },
        }}
      />);
  }

  if (talentIDParam > 0) {
    try {
      const { data, error, loading } = useQuery<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables>(
          gql(getCurrentUser), {
          fetchPolicy: 'network-only',
          variables: { UserID: talentIDParam },
        });


      let { data: additionalOrganizations, error: additionalError, loading: additionalLoading } = useQuery(
        gql(getAdditionalOrganizations),
        {
          fetchPolicy: 'network-only',
          variables: {
            UserID: context.dbUser?.UserID, // Add null check for context.dbUser
          },
        },
      );

      if (loading || additionalLoading) {
        return <Loading />;
      }

      if (error || !data) {
        return (
          <>
            <QueryError
              error={error}
              parentName="AuthedRoute"
              privateMessage="From AuthedRoute"
              defaultMessage="User is inactive"
            />
            <Redirect to="/" />
          </>
        );
      }

      if (data?.getCurrentUser) {
        return (
          <Route
            {...rest}
            render={(props): JSX.Element => {
              if (context.isSignedIn) {
                if (context.dbUser?.UserIsAdmin || context.dbUser?.UserIsManager) {
                  if (loading) {
                    return <Loading />;
                  }

                  if (error || !data) {
                    return (
                      <>
                        <QueryError
                          error={error}
                          parentName="AuthedRoute"
                          privateMessage="From AuthedRoute"
                          defaultMessage="User is inactive"
                        />
                        <Redirect to="/" />
                      </>
                    );
                  }

                  if (data?.getCurrentUser) {
                    const talentIsPartOfOrganization =
                      context.dbUser?.OrganizationID ===
                      data.getCurrentUser.OrganizationID;


                    let isPartOfAdditionalOrganizations = false;

                    if (!talentIsPartOfOrganization) {

                      isPartOfAdditionalOrganizations = additionalOrganizations?.getAdditionalOrganizations?.some(
                        (org) => org.OrganizationID === data.getCurrentUser.OrganizationID,
                      );

                      if (isPartOfAdditionalOrganizations) {
                        let newDBUser = { ...context.dbUser, OrganizationID: data.getCurrentUser.OrganizationID, DownloadAccess: false };
                        context.setCurrentUser(newDBUser);
                      }

                    }


                    const talentIsPartOfAdditionalOrganization =
                      context.dbUser?.OrganizationID ===
                      data.getCurrentUser.OrganizationID;


                    if (!data.getCurrentUser || (!talentIsPartOfOrganization && !talentIsPartOfAdditionalOrganization && !isPartOfAdditionalOrganizations)) {
                      return (
                        <>
                          <QueryError
                            error={error}
                            parentName="AuthedRoute"
                            privateMessage="From AuthedRoute"
                            defaultMessage="This operation is not permitted!"
                          />
                          <Redirect to="/" />
                        </>
                      );
                    }



                    if (!data.getCurrentUser.IsActive) {
                      return (
                        <>
                          <QueryError
                            error={error}
                            parentName="AuthedRoute"
                            privateMessage="From AuthedRoute"
                            defaultMessage="User is inactive"
                          />
                          <Redirect to="/" />
                        </>
                      );
                    }

                    return <Component {...props}></Component>;
                  } else {
                    return (
                      <>
                        <QueryError
                          error={error}
                          parentName="AuthedRoute"
                          privateMessage="From GetCurrentUserQuery"
                        />
                        <Redirect to="/" />
                      </>
                    );
                  }
                } else {
                  return <Component {...props}></Component>;
                }
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from },
                    }}
                  />
                );
              }
            }}
          />
        )
      }
    } catch (err) {
      const error = err as ApolloError;
      return (
        <>
          <QueryError
            error={error}
            parentName="AuthedRoute"
            privateMessage="1From AuthedRoute"
            defaultMessage="User is inactive"
          />
          <Redirect to="/" />
        </>
      );
    }
  } else {

    return <Component ></Component>;
  }

  return <Component ></Component>;
};

export default AuthedRoute;
