/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCurrentUser = /* GraphQL */ `query GetCurrentUser($UserID: Int) {
  getCurrentUser(UserID: $UserID) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsAdmin
    UserIsManager
    UserIsTalent
    UserAcceptedInvitation
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserEmail
    UserSMS
    UserCognitoUID
    UserBio
    UserCompletedOnboarding
    OrganizationID
    AudienceID
    IsMediaOptIn
    BirthDate
    HasFinishedQuestions
    IsActive
    TemporaryEmail
    TotalTalents
    ProfileCompletion
    DownloadAccess
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCurrentUserQueryVariables,
  APITypes.GetCurrentUserQuery
>;
export const getOrganizations = /* GraphQL */ `query GetOrganizations {
  getOrganizations {
    OrganizationID
    OrganizationName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationsQueryVariables,
  APITypes.GetOrganizationsQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($OrganizationID: Int!) {
  getOrganization(OrganizationID: $OrganizationID) {
    OrganizationID
    OrganizationName
    OrganizationAdded
    OrganizationUpdated
    OrganizationLogo
    OrganizationBrandColor
    MediaKitHeaderType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const getOrganizationAndActiveSeats = /* GraphQL */ `query GetOrganizationAndActiveSeats($OrganizationID: Int!) {
  getOrganizationAndActiveSeats(OrganizationID: $OrganizationID) {
    OrganizationID
    OrganizationName
    OrganizationAdded
    OrganizationUpdated
    ActiveSeats
    MaximumLicensedSeats
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationAndActiveSeatsQueryVariables,
  APITypes.GetOrganizationAndActiveSeatsQuery
>;
export const getOrganizationActiveSeatsCount = /* GraphQL */ `query GetOrganizationActiveSeatsCount($OrganizationID: Int!) {
  getOrganizationActiveSeatsCount(OrganizationID: $OrganizationID)
}
` as GeneratedQuery<
  APITypes.GetOrganizationActiveSeatsCountQueryVariables,
  APITypes.GetOrganizationActiveSeatsCountQuery
>;
export const getOrganizationAccounts = /* GraphQL */ `query GetOrganizationAccounts($IsActive: Boolean!, $Query: String, $Page: Int) {
  getOrganizationAccounts(IsActive: $IsActive, Query: $Query, Page: $Page) {
    OrganizationID
    OrganizationName
    TotalOrgAdminsCount
    TotalTalentCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationAccountsQueryVariables,
  APITypes.GetOrganizationAccountsQuery
>;
export const getTalentRosterFilteredUsers = /* GraphQL */ `query GetTalentRosterFilteredUsers(
  $OrganizationID: Int!
  $ManagerID: Int
  $SearchTerm: String
  $Filters: String!
  $Env: String!
) {
  getTalentRosterFilteredUsers(
    OrganizationID: $OrganizationID
    ManagerID: $ManagerID
    SearchTerm: $SearchTerm
    Filters: $Filters
    Env: $Env
  ) {
    users
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentRosterFilteredUsersQueryVariables,
  APITypes.GetTalentRosterFilteredUsersQuery
>;
export const getOrganizationAccountsCount = /* GraphQL */ `query GetOrganizationAccountsCount($IsActive: Boolean, $Query: String) {
  getOrganizationAccountsCount(IsActive: $IsActive, Query: $Query)
}
` as GeneratedQuery<
  APITypes.GetOrganizationAccountsCountQueryVariables,
  APITypes.GetOrganizationAccountsCountQuery
>;
export const getTalentsKeyAdminFilteredUsers = /* GraphQL */ `query GetTalentsKeyAdminFilteredUsers(
  $SearchTerm: String
  $Filters: String!
  $Env: String!
) {
  getTalentsKeyAdminFilteredUsers(
    SearchTerm: $SearchTerm
    Filters: $Filters
    Env: $Env
  ) {
    users
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentsKeyAdminFilteredUsersQueryVariables,
  APITypes.GetTalentsKeyAdminFilteredUsersQuery
>;
export const getTalentRosterData = /* GraphQL */ `query GetTalentRosterData($Users: String!) {
  getTalentRosterData(Users: $Users) {
    UserID
    UserFullName
    UserEmail
    IsMediaOptIn
    UserAvatar
    TotalEmails
    InstagramFollowers
    TiktokFollowers
    TwitterFollowers
    FacebookFollowers
    YoutubeSubscribers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentRosterDataQueryVariables,
  APITypes.GetTalentRosterDataQuery
>;
export const getTalentsKeyAdmin = /* GraphQL */ `query GetTalentsKeyAdmin($Users: String!) {
  getTalentsKeyAdmin(Users: $Users) {
    UserID
    UserFullName
    AudienceID
    OrganizationID
    OrganizationName
    KeychainUsername
    UserAvatar
    TotalEmails
    TotalSocialReach
    BrandSteps
    TalentSteps
    ReportAvailability
    InstagramFollowers
    TiktokFollowers
    TwitterFollowers
    FacebookFollowers
    YoutubeSubscribers
    LastOnboarded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentsKeyAdminQueryVariables,
  APITypes.GetTalentsKeyAdminQuery
>;
export const getUserFirstLastName = /* GraphQL */ `query GetUserFirstLastName($UserID: Int!) {
  getUserFirstLastName(UserID: $UserID) {
    UserFirstName
    UserLastName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserFirstLastNameQueryVariables,
  APITypes.GetUserFirstLastNameQuery
>;
export const getUserLogin = /* GraphQL */ `query GetUserLogin($UserLoginJWT: String!) {
  getUserLogin(UserLoginJWT: $UserLoginJWT) {
    UserLoginID
    User {
      UserID
      UserFirstName
      UserLastName
      UserAvatar
      UserBackgroundImage
      UserAdded
      UserIsAdmin
      UserIsManager
      UserIsTalent
      UserAcceptedInvitation
      UserIsVerifiedForEventCreation
      UserIsTeam
      UserEmail
      UserSMS
      UserCognitoUID
      UserBio
      UserCompletedOnboarding
      OrganizationID
      AudienceID
      IsMediaOptIn
      BirthDate
      HasFinishedQuestions
      IsActive
      TemporaryEmail
      TotalTalents
      ProfileCompletion
      DownloadAccess
      __typename
    }
    AccessToken
    IdToken
    RefreshToken
    UserLoginJWT
    UserLoginTime
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserLoginQueryVariables,
  APITypes.GetUserLoginQuery
>;
export const getUserPayments = /* GraphQL */ `query GetUserPayments {
  getUserPayments {
    UserPaymentID
    User {
      UserID
      UserFirstName
      UserLastName
      UserAvatar
      UserBackgroundImage
      UserAdded
      UserIsTalent
      UserIsVerifiedForEventCreation
      UserIsTeam

      ... on CurrentUser {
        UserIsAdmin
        UserIsManager
        UserAcceptedInvitation
        UserEmail
        UserSMS
        UserCognitoUID
        UserBio
        UserCompletedOnboarding
        OrganizationID
        AudienceID
        IsMediaOptIn
        BirthDate
        HasFinishedQuestions
        IsActive
        TemporaryEmail
        TotalTalents
        ProfileCompletion
        DownloadAccess
      }
      ... on Talent {
        UserCompletedOnboarding
        UserCognitoUID
        UserBio
        IsMediaOptIn
        OrganizationID
        UserEmail
      }
    }
    UserPaymentAccount
    UserPaymentToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPaymentsQueryVariables,
  APITypes.GetUserPaymentsQuery
>;
export const getUserPaymentCards = /* GraphQL */ `query GetUserPaymentCards($Env: String!) {
  getUserPaymentCards(Env: $Env) {
    data {
      account
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserPaymentCardsQueryVariables,
  APITypes.GetUserPaymentCardsQuery
>;
export const getTalentMetrics = /* GraphQL */ `query GetTalentMetrics {
  getTalentMetrics {
    TotalCount
    TotalThisWeekCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentMetricsQueryVariables,
  APITypes.GetTalentMetricsQuery
>;
export const getTalents = /* GraphQL */ `query GetTalents($Name: String!) {
  getTalents(Name: $Name) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserCompletedOnboarding
    UserIsTalent
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserCognitoUID
    UserBio
    IsMediaOptIn
    OrganizationID
    UserEmail
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentsQueryVariables,
  APITypes.GetTalentsQuery
>;
export const getKeyLinks = /* GraphQL */ `query GetKeyLinks(
  $TalentID: Int
  $GetForAllTalents: Boolean
  $Limit: Int
  $Page: Int
  $SearchTerm: String
  $OnlyCaptureLinks: Boolean
  $Filters: String
  $EmailFilter: String
  $TagsFilter: String
  $IsHidden: Boolean
  $KeychainTalentID: Int
) {
  getKeyLinks(
    TalentID: $TalentID
    GetForAllTalents: $GetForAllTalents
    Limit: $Limit
    Page: $Page
    SearchTerm: $SearchTerm
    OnlyCaptureLinks: $OnlyCaptureLinks
    Filters: $Filters
    EmailFilter: $EmailFilter
    TagsFilter: $TagsFilter
    IsHidden: $IsHidden
    KeychainTalentID: $KeychainTalentID
  ) {
    KeyLinkID
    TalentID
    KeyLinkType
    KeyLinkName
    KeyLinkDescription
    KeyLinkImageURL
    isHidden
    StartDate
    EndDate
    DisplayRank
    PhoneNumberRequired
    KeyLinkAdded
    KeyLinkUpdated
    KeyLinkDeleted
    UpdatedByUser
    LinkDetailsID
    LinkDetailsDescription
    LinkDetailsURL
    KeyLinkPixel
    CustomConsentHyperlinkLabel
    CustomConsentURL
    CustomConsentCallToAction
    CustomConsentUpdated
    IsDarkMode
    ProfilePageColor
    KeyLinkRegistrationEmail
    URLFriendlyName
    RedirectURL
    TalentName
    BackgroundType
    ProfilePageSecondColor
    FontID
    FontColor
    BackgroundImageURL
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyLinksQueryVariables,
  APITypes.GetKeyLinksQuery
>;
export const getKeyCaptureLinks = /* GraphQL */ `query GetKeyCaptureLinks($TalentID: Int) {
  getKeyCaptureLinks(TalentID: $TalentID) {
    KeyCaptureLinkID
    UserID
    CaptureLinkURL
    CaptureLinkCustomDomain
    RedirectURL
    RebrandlyLinkID
    AudienceID
    CapturePagePixel
    KeyCaptureLinkAdded
    KeyCaptureLinkAddedBy
    KeyCaptureLinkDeleted
    KeyCaptureLinkDeletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyCaptureLinksQueryVariables,
  APITypes.GetKeyCaptureLinksQuery
>;
export const getKeyCaptureLink = /* GraphQL */ `query GetKeyCaptureLink($KeyCaptureLinkID: Int!) {
  getKeyCaptureLink(KeyCaptureLinkID: $KeyCaptureLinkID) {
    KeyCaptureLinkID
    UserID
    CaptureLinkURL
    CaptureLinkCustomDomain
    RedirectURL
    RebrandlyLinkID
    AudienceID
    CapturePagePixel
    KeyCaptureLinkAdded
    KeyCaptureLinkAddedBy
    KeyCaptureLinkDeleted
    KeyCaptureLinkDeletedBy
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyCaptureLinkQueryVariables,
  APITypes.GetKeyCaptureLinkQuery
>;
export const getFanManagerData = /* GraphQL */ `query GetFanManagerData($limit: Int, $talentID: Int) {
  getFanManagerData(limit: $limit, talentID: $talentID) {
    FanGroupName
    KeyLinkID
    FansAdded
    GroupType
    StartDate
    EndDate
    DateCreated
    DateDeleted
    isHidden
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFanManagerDataQueryVariables,
  APITypes.GetFanManagerDataQuery
>;
export const getUploadedFanGroupData = /* GraphQL */ `query GetUploadedFanGroupData($limit: Int, $talentID: Int) {
  getUploadedFanGroupData(limit: $limit, talentID: $talentID) {
    FanGroupName
    EmailUploadGroupID
    FansAdded
    DateCreated
    DateUpdated
    SignedUpForKeychain
    UserIsTalent
    UserIsVerifiedForEventCreation
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadedFanGroupDataQueryVariables,
  APITypes.GetUploadedFanGroupDataQuery
>;
export const getTotalFanPoolData = /* GraphQL */ `query GetTotalFanPoolData($TalentID: Int) {
  getTotalFanPoolData(TalentID: $TalentID) {
    TotalFans
    FansAddedToday
    FansAddedYesterday
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTotalFanPoolDataQueryVariables,
  APITypes.GetTotalFanPoolDataQuery
>;
export const getIsKeychainProfileURLValid = /* GraphQL */ `query GetIsKeychainProfileURLValid(
  $KeychainProfileURL: String!
  $KeychainCustomURL: String!
) {
  getIsKeychainProfileURLValid(
    KeychainProfileURL: $KeychainProfileURL
    KeychainCustomURL: $KeychainCustomURL
  )
}
` as GeneratedQuery<
  APITypes.GetIsKeychainProfileURLValidQueryVariables,
  APITypes.GetIsKeychainProfileURLValidQuery
>;
export const getOrganizationCustomURLs = /* GraphQL */ `query GetOrganizationCustomURLs($OrganizationID: Int!) {
  getOrganizationCustomURLs(OrganizationID: $OrganizationID) {
    OrganizationURLID
    OrganizationID
    CustomURL
    DateAdded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationCustomURLsQueryVariables,
  APITypes.GetOrganizationCustomURLsQuery
>;
export const getTalentCustomURLs = /* GraphQL */ `query GetTalentCustomURLs($TalentID: Int) {
  getTalentCustomURLs(TalentID: $TalentID) {
    TalentURLID
    TalentID
    CustomURL
    DateAdded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentCustomURLsQueryVariables,
  APITypes.GetTalentCustomURLsQuery
>;
export const getIsOrganizationNameValid = /* GraphQL */ `query GetIsOrganizationNameValid($OrganizationName: String!) {
  getIsOrganizationNameValid(OrganizationName: $OrganizationName)
}
` as GeneratedQuery<
  APITypes.GetIsOrganizationNameValidQueryVariables,
  APITypes.GetIsOrganizationNameValidQuery
>;
export const getIsKeyCaptureLinkURLValid = /* GraphQL */ `query GetIsKeyCaptureLinkURLValid(
  $CaptureLinkURL: String!
  $CaptureLinkCustomDomain: String!
) {
  getIsKeyCaptureLinkURLValid(
    CaptureLinkURL: $CaptureLinkURL
    CaptureLinkCustomDomain: $CaptureLinkCustomDomain
  )
}
` as GeneratedQuery<
  APITypes.GetIsKeyCaptureLinkURLValidQueryVariables,
  APITypes.GetIsKeyCaptureLinkURLValidQuery
>;
export const getKeychainTalent = /* GraphQL */ `query GetKeychainTalent($TalentID: Int) {
  getKeychainTalent(TalentID: $TalentID) {
    KeychainTalentID
    UserID
    KeychainProfileURL
    KeychainCustomURL
    AudienceID
    TalentPagePixel
    TalentDisplayName
    TalentDisplayBio
    IsTrialUser
    HasFinishedQuestions
    BackgroundType
    ProfilePageColor
    ProfilePageSecondColor
    ProfilePageImageURL
    IsDarkMode
    UserAvatar
    UserFirstName
    UserLastName
    KeyLinkRegistrationEmail
    KeyLinkHeader
    WebpageLinkHeader
    FontColor
    FontName
    FontID
    TalentDisplayPhoto
    ProfilePhotoShape
    LinkBackgroundColor
    LinkBackgroundOpacity
    WebLinksLayoutTypeID
    URLFriendlyName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentQueryVariables,
  APITypes.GetKeychainTalentQuery
>;
export const getKeychainSocialMedia = /* GraphQL */ `query GetKeychainSocialMedia($TalentID: Int) {
  getKeychainSocialMedia(TalentID: $TalentID) {
    KeychainSocialMediaID
    UserID
    SocialMediaType
    SocialMediaUserID
    DisplayRank
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainSocialMediaQueryVariables,
  APITypes.GetKeychainSocialMediaQuery
>;
export const getKeychainRegisteredFan = /* GraphQL */ `query GetKeychainRegisteredFan($email: String!, $keyLinkID: Int!) {
  getKeychainRegisteredFan(email: $email, keyLinkID: $keyLinkID) {
    KeychainFanRegistrationID
    KeyLinkID
    Email
    FirstName
    LastName
    PhoneNumber
    AddedToLinkFanGroup
    CustomConsentAccepted
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainRegisteredFanQueryVariables,
  APITypes.GetKeychainRegisteredFanQuery
>;
export const getKeyLink = /* GraphQL */ `query GetKeyLink($KeyLinkID: Int!, $TalentID: Int) {
  getKeyLink(KeyLinkID: $KeyLinkID, TalentID: $TalentID) {
    KeyLinkID
    TalentID
    KeyLinkType
    KeyLinkName
    KeyLinkDescription
    KeyLinkImageURL
    isHidden
    StartDate
    EndDate
    DisplayRank
    PhoneNumberRequired
    KeyLinkAdded
    KeyLinkUpdated
    KeyLinkDeleted
    UpdatedByUser
    LinkDetailsID
    LinkDetailsDescription
    LinkDetailsURL
    KeyLinkPixel
    CustomConsentHyperlinkLabel
    CustomConsentURL
    CustomConsentCallToAction
    CustomConsentUpdated
    IsDarkMode
    ProfilePageColor
    KeyLinkRegistrationEmail
    URLFriendlyName
    RedirectURL
    TalentName
    BackgroundType
    ProfilePageSecondColor
    FontID
    FontColor
    BackgroundImageURL
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyLinkQueryVariables,
  APITypes.GetKeyLinkQuery
>;
export const getKeyLinkInfo = /* GraphQL */ `query GetKeyLinkInfo($KeyLinkID: Int!) {
  getKeyLinkInfo(KeyLinkID: $KeyLinkID) {
    KeyLinkName
    UserID
    UserFirstName
    UserLastName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyLinkInfoQueryVariables,
  APITypes.GetKeyLinkInfoQuery
>;
export const getUploadedFanGroup = /* GraphQL */ `query GetUploadedFanGroup($UploadGroupID: Int!) {
  getUploadedFanGroup(UploadGroupID: $UploadGroupID) {
    FanGroupName
    EmailUploadGroupID
    FansAdded
    DateCreated
    UserID
    UserFirstName
    UserLastName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadedFanGroupQueryVariables,
  APITypes.GetUploadedFanGroupQuery
>;
export const getFanGroups = /* GraphQL */ `query GetFanGroups($TalentID: Int!, $Page: Int) {
  getFanGroups(TalentID: $TalentID, Page: $Page) {
    FanGroupName
    EmailUploadGroupID
    FansAdded
    DateCreated
    DateDeleted
    LastUpdated
    TalentID
    KeyLinkID
    isHidden
    GroupType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFanGroupsQueryVariables,
  APITypes.GetFanGroupsQuery
>;
export const getFanGroupsCount = /* GraphQL */ `query GetFanGroupsCount($TalentID: Int!) {
  getFanGroupsCount(TalentID: $TalentID)
}
` as GeneratedQuery<
  APITypes.GetFanGroupsCountQueryVariables,
  APITypes.GetFanGroupsCountQuery
>;
export const getTalentKeychainProfile = /* GraphQL */ `query GetTalentKeychainProfile($profileURL: String!, $domainURL: String!) {
  getTalentKeychainProfile(profileURL: $profileURL, domainURL: $domainURL) {
    KeychainTalentID
    UserID
    KeychainProfileURL
    TalentPagePixel
    TalentDisplayBio
    TalentDisplayName
    ProfilePageColor
    UserAvatar
    OrganizationID
    KeyLinks {
      KeyLinkID
      TalentID
      KeyLinkType
      KeyLinkName
      KeyLinkDescription
      KeyLinkImageURL
      isHidden
      StartDate
      EndDate
      DisplayRank
      PhoneNumberRequired
      KeyLinkAdded
      KeyLinkUpdated
      KeyLinkDeleted
      UpdatedByUser
      LinkDetailsID
      LinkDetailsDescription
      LinkDetailsURL
      KeyLinkPixel
      CustomConsentHyperlinkLabel
      CustomConsentURL
      CustomConsentCallToAction
      CustomConsentUpdated
      IsDarkMode
      ProfilePageColor
      KeyLinkRegistrationEmail
      URLFriendlyName
      RedirectURL
      TalentName
      BackgroundType
      ProfilePageSecondColor
      FontID
      FontColor
      BackgroundImageURL
      __typename
    }
    KeychainSocialMedia {
      KeychainSocialMediaID
      UserID
      SocialMediaType
      SocialMediaUserID
      DisplayRank
      __typename
    }
    IsDarkMode
    KeyLinkRegistrationEmail
    KeyLinkHeader
    WebpageLinkHeader
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentKeychainProfileQueryVariables,
  APITypes.GetTalentKeychainProfileQuery
>;
export const getTalentHomepageData = /* GraphQL */ `query GetTalentHomepageData($UserID: Int) {
  getTalentHomepageData(UserID: $UserID) {
    TotalUpcomingEvents
    TotalUpcomingEventSignUps
    TotalActiveKeyLinks
    TotalKeyCaptureLinks
    HadKeyLink
    IsSignedUpKeychain
    HasPreviousEvent
    HasUploadedFanGroups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentHomepageDataQueryVariables,
  APITypes.GetTalentHomepageDataQuery
>;
export const getEmailUserIsTalent = /* GraphQL */ `query GetEmailUserIsTalent($email: String!) {
  getEmailUserIsTalent(email: $email)
}
` as GeneratedQuery<
  APITypes.GetEmailUserIsTalentQueryVariables,
  APITypes.GetEmailUserIsTalentQuery
>;
export const getAudienceInsights = /* GraphQL */ `query GetAudienceInsights($TalentID: Int, $Category: String!, $Env: String!) {
  getAudienceInsights(TalentID: $TalentID, Category: $Category, Env: $Env) {
    behaviorName
    percentage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAudienceInsightsQueryVariables,
  APITypes.GetAudienceInsightsQuery
>;
export const getAudienceBehaviors = /* GraphQL */ `query GetAudienceBehaviors(
  $TalentID: Int
  $IndustryVertical: String
  $BehaviorName: String
  $Env: String!
  $TalentDash: String
) {
  getAudienceBehaviors(
    TalentID: $TalentID
    IndustryVertical: $IndustryVertical
    BehaviorName: $BehaviorName
    Env: $Env
    TalentDash: $TalentDash
  ) {
    behaviorName
    behaviorPath
    compositeIndex
    industryVerticals
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAudienceBehaviorsQueryVariables,
  APITypes.GetAudienceBehaviorsQuery
>;
export const getAudienceUniques = /* GraphQL */ `query GetAudienceUniques($TalentID: Int, $Env: String!) {
  getAudienceUniques(TalentID: $TalentID, Env: $Env)
}
` as GeneratedQuery<
  APITypes.GetAudienceUniquesQueryVariables,
  APITypes.GetAudienceUniquesQuery
>;
export const getAudienceUniquesSnapshot = /* GraphQL */ `query GetAudienceUniquesSnapshot($TalentID: Int) {
  getAudienceUniquesSnapshot(TalentID: $TalentID)
}
` as GeneratedQuery<
  APITypes.GetAudienceUniquesSnapshotQueryVariables,
  APITypes.GetAudienceUniquesSnapshotQuery
>;
export const getTotalKeychainFans = /* GraphQL */ `query GetTotalKeychainFans($TalentID: Int) {
  getTotalKeychainFans(TalentID: $TalentID)
}
` as GeneratedQuery<
  APITypes.GetTotalKeychainFansQueryVariables,
  APITypes.GetTotalKeychainFansQuery
>;
export const getKeychainSubscriptionDetails = /* GraphQL */ `query GetKeychainSubscriptionDetails($Env: String!, $TalentID: Int) {
  getKeychainSubscriptionDetails(Env: $Env, TalentID: $TalentID) {
    amountDue
    dateCreated
    dateDue
    previousInvoiceAmountPaid
    previousInvoiceDateCreated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainSubscriptionDetailsQueryVariables,
  APITypes.GetKeychainSubscriptionDetailsQuery
>;
export const checkKeychainPromocode = /* GraphQL */ `query CheckKeychainPromocode($promocode: String, $Env: String!) {
  checkKeychainPromocode(promocode: $promocode, Env: $Env) {
    promocodeID
    active
    percentOff
    durationInMonths
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckKeychainPromocodeQueryVariables,
  APITypes.CheckKeychainPromocodeQuery
>;
export const getUploadEmailDropdownOptions = /* GraphQL */ `query GetUploadEmailDropdownOptions($talentID: Int!) {
  getUploadEmailDropdownOptions(talentID: $talentID) {
    FanGroupName
    EmailUploadGroupAdded
    EmailUploadGroupID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadEmailDropdownOptionsQueryVariables,
  APITypes.GetUploadEmailDropdownOptionsQuery
>;
export const getKeychainSignUpQuestions = /* GraphQL */ `query GetKeychainSignUpQuestions {
  getKeychainSignUpQuestions {
    KeychainTalentQuestionID
    Description
    IsRequired
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainSignUpQuestionsQueryVariables,
  APITypes.GetKeychainSignUpQuestionsQuery
>;
export const getKeychainSignUpPrimaryAnswers = /* GraphQL */ `query GetKeychainSignUpPrimaryAnswers {
  getKeychainSignUpPrimaryAnswers {
    KeychainTalentPrimaryAnswerID
    KeychainTalentQuestionID
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainSignUpPrimaryAnswersQueryVariables,
  APITypes.GetKeychainSignUpPrimaryAnswersQuery
>;
export const getKeychainSignUpSecondaryAnswers = /* GraphQL */ `query GetKeychainSignUpSecondaryAnswers {
  getKeychainSignUpSecondaryAnswers {
    KeychainTalentSecondaryAnswerID
    KeychainTalentPrimaryAnswerID
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainSignUpSecondaryAnswersQueryVariables,
  APITypes.GetKeychainSignUpSecondaryAnswersQuery
>;
export const getTalentDisplayName = /* GraphQL */ `query GetTalentDisplayName($UserID: Int) {
  getTalentDisplayName(UserID: $UserID) {
    TalentDisplayName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentDisplayNameQueryVariables,
  APITypes.GetTalentDisplayNameQuery
>;
export const getTalentDisplayBio = /* GraphQL */ `query GetTalentDisplayBio($UserID: Int) {
  getTalentDisplayBio(UserID: $UserID) {
    UserBio
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentDisplayBioQueryVariables,
  APITypes.GetTalentDisplayBioQuery
>;
export const getTalentAdmin = /* GraphQL */ `query GetTalentAdmin {
  getTalentAdmin {
    UserID
    UserEmail
    UserFirstName
    UserLastName
    UserIsAdmin
    UserIsManager
    OrganizationID
    OrganizationName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentAdminQueryVariables,
  APITypes.GetTalentAdminQuery
>;
export const getTalentAdminsOfOrganization = /* GraphQL */ `query GetTalentAdminsOfOrganization(
  $OrganizationID: Int!
  $Query: String
  $Page: Int
  $Offset: Int
) {
  getTalentAdminsOfOrganization(
    OrganizationID: $OrganizationID
    Query: $Query
    Page: $Page
    Offset: $Offset
  ) {
    UserID
    UserEmail
    UserFirstName
    UserLastName
    UserIsAdmin
    UserIsManager
    OrganizationName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentAdminsOfOrganizationQueryVariables,
  APITypes.GetTalentAdminsOfOrganizationQuery
>;
export const getTalentAdminsOfOrganizationCount = /* GraphQL */ `query GetTalentAdminsOfOrganizationCount(
  $OrganizationID: Int!
  $Query: String
) {
  getTalentAdminsOfOrganizationCount(
    OrganizationID: $OrganizationID
    Query: $Query
  )
}
` as GeneratedQuery<
  APITypes.GetTalentAdminsOfOrganizationCountQueryVariables,
  APITypes.GetTalentAdminsOfOrganizationCountQuery
>;
export const getTalentAdminAndTalents = /* GraphQL */ `query GetTalentAdminAndTalents($OrganizationID: Int) {
  getTalentAdminAndTalents(OrganizationID: $OrganizationID) {
    UserID
    OrganizationID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentAdminAndTalentsQueryVariables,
  APITypes.GetTalentAdminAndTalentsQuery
>;
export const getTalentsWithSameAnswer = /* GraphQL */ `query GetTalentsWithSameAnswer(
  $getTalentsWithSameAnswerInput: GetTalentsWithSameAnswerInput!
) {
  getTalentsWithSameAnswer(
    getTalentsWithSameAnswerInput: $getTalentsWithSameAnswerInput
  ) {
    UserID
    UserFirstName
    UserLastName
    TotalFans
    EngagementStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentsWithSameAnswerQueryVariables,
  APITypes.GetTalentsWithSameAnswerQuery
>;
export const getTotalTalents = /* GraphQL */ `query GetTotalTalents($OrganizationID: Int!) {
  getTotalTalents(OrganizationID: $OrganizationID)
}
` as GeneratedQuery<
  APITypes.GetTotalTalentsQueryVariables,
  APITypes.GetTotalTalentsQuery
>;
export const getTalent = /* GraphQL */ `query GetTalent($talentID: Int!) {
  getTalent(talentID: $talentID) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserCompletedOnboarding
    UserIsTalent
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserCognitoUID
    UserBio
    IsMediaOptIn
    OrganizationID
    UserEmail
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTalentQueryVariables, APITypes.GetTalentQuery>;
export const getBrandCategories = /* GraphQL */ `query GetBrandCategories {
  getBrandCategories {
    BrandCategoryID
    BrandCategoryName
    DisplayRank
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandCategoriesQueryVariables,
  APITypes.GetBrandCategoriesQuery
>;
export const getBrands = /* GraphQL */ `query GetBrands {
  getBrands {
    BrandID
    BrandName
    __typename
  }
}
` as GeneratedQuery<APITypes.GetBrandsQueryVariables, APITypes.GetBrandsQuery>;
export const getHasTalentBrandAffinity = /* GraphQL */ `query GetHasTalentBrandAffinity($TalentID: Int) {
  getHasTalentBrandAffinity(TalentID: $TalentID)
}
` as GeneratedQuery<
  APITypes.GetHasTalentBrandAffinityQueryVariables,
  APITypes.GetHasTalentBrandAffinityQuery
>;
export const getAggregationPageBrandData = /* GraphQL */ `query GetAggregationPageBrandData(
  $OrganizationID: Int
  $Limit: Int
  $ManagerUserID: Int
) {
  getAggregationPageBrandData(
    OrganizationID: $OrganizationID
    Limit: $Limit
    ManagerUserID: $ManagerUserID
  ) {
    TalentID
    BrandID
    BrandCategoryID
    BrandName
    Category
    BrandTotal
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAggregationPageBrandDataQueryVariables,
  APITypes.GetAggregationPageBrandDataQuery
>;
export const getTalentBrandAffinities = /* GraphQL */ `query GetTalentBrandAffinities($TalentID: Int) {
  getTalentBrandAffinities(TalentID: $TalentID) {
    TalentBrandAffinityID
    BrandName
    BrandCategoryName
    EngagementStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentBrandAffinitiesQueryVariables,
  APITypes.GetTalentBrandAffinitiesQuery
>;
export const getAllTalentBrandAffinities = /* GraphQL */ `query GetAllTalentBrandAffinities($TalentID: Int) {
  getAllTalentBrandAffinities(TalentID: $TalentID) {
    TalentBrandAffinityID
    BrandName
    BrandCategoryName
    EngagementStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllTalentBrandAffinitiesQueryVariables,
  APITypes.GetAllTalentBrandAffinitiesQuery
>;
export const getTalentBrandAffinitiesByBrandCategory = /* GraphQL */ `query GetTalentBrandAffinitiesByBrandCategory(
  $TalentID: Int
  $BrandCategoryID: Int!
) {
  getTalentBrandAffinitiesByBrandCategory(
    TalentID: $TalentID
    BrandCategoryID: $BrandCategoryID
  ) {
    TalentBrandAffinityID
    BrandID
    BrandName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentBrandAffinitiesByBrandCategoryQueryVariables,
  APITypes.GetTalentBrandAffinitiesByBrandCategoryQuery
>;
export const getTalentBrandAffinitiesTotalTalents = /* GraphQL */ `query GetTalentBrandAffinitiesTotalTalents(
  $OrganizationID: Int
  $ManagerUserID: Int
) {
  getTalentBrandAffinitiesTotalTalents(
    OrganizationID: $OrganizationID
    ManagerUserID: $ManagerUserID
  )
}
` as GeneratedQuery<
  APITypes.GetTalentBrandAffinitiesTotalTalentsQueryVariables,
  APITypes.GetTalentBrandAffinitiesTotalTalentsQuery
>;
export const getTalentBrandRelationships = /* GraphQL */ `query GetTalentBrandRelationships($TalentID: Int) {
  getTalentBrandRelationships(TalentID: $TalentID) {
    TalentBrandRelationshipID
    BrandID
    BrandName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentBrandRelationshipsQueryVariables,
  APITypes.GetTalentBrandRelationshipsQuery
>;
export const getUserIDFromEmail = /* GraphQL */ `query GetUserIDFromEmail($Email: String!) {
  getUserIDFromEmail(Email: $Email)
}
` as GeneratedQuery<
  APITypes.GetUserIDFromEmailQueryVariables,
  APITypes.GetUserIDFromEmailQuery
>;
export const getSocialBrandSearchRequest = /* GraphQL */ `query GetSocialBrandSearchRequest($SocialBrandSearchRequestID: Int!) {
  getSocialBrandSearchRequest(
    SocialBrandSearchRequestID: $SocialBrandSearchRequestID
  ) {
    SocialBrandSearchRequestID
    OrganizationID
    RequestName
    Description
    Status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialBrandSearchRequestQueryVariables,
  APITypes.GetSocialBrandSearchRequestQuery
>;
export const getSocialBrandSearchResults = /* GraphQL */ `query GetSocialBrandSearchResults(
  $Where: String
  $OrderBy: String
  $Limit: String
  $Offset: String
) {
  getSocialBrandSearchResults(
    Where: $Where
    OrderBy: $OrderBy
    Limit: $Limit
    Offset: $Offset
  ) {
    SocialBrandSearchResultID
    BehaviorName
    IndustryVertical
    Path
    BrandIndex
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialBrandSearchResultsQueryVariables,
  APITypes.GetSocialBrandSearchResultsQuery
>;
export const getSocialBrandSearchResultsCount = /* GraphQL */ `query GetSocialBrandSearchResultsCount($Where: String) {
  getSocialBrandSearchResultsCount(Where: $Where)
}
` as GeneratedQuery<
  APITypes.GetSocialBrandSearchResultsCountQueryVariables,
  APITypes.GetSocialBrandSearchResultsCountQuery
>;
export const getKeychainTalentAnswers = /* GraphQL */ `query GetKeychainTalentAnswers($TalentID: Int) {
  getKeychainTalentAnswers(TalentID: $TalentID) {
    KeychainTalentAnswerID
    TalentID
    KeychainTalentQuestionID
    OtherAnswer
    KeychainTalentPrimaryAnswerID
    KeychainTalentSecondaryAnswerID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentAnswersQueryVariables,
  APITypes.GetKeychainTalentAnswersQuery
>;
export const getKeychainTalentAnswersFromTalent = /* GraphQL */ `query GetKeychainTalentAnswersFromTalent($TalentID: Int!) {
  getKeychainTalentAnswersFromTalent(TalentID: $TalentID) {
    PrimaryDescription
    OtherPrimaryAnswer
    SecondaryDescription
    OtherSecondaryAnswer
    TertiaryDescription
    OtherTertiaryAnswer
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentAnswersFromTalentQueryVariables,
  APITypes.GetKeychainTalentAnswersFromTalentQuery
>;
export const getOrganizationAudienceOverlapRequests = /* GraphQL */ `query GetOrganizationAudienceOverlapRequests(
  $OrganizationID: Int!
  $Limit: Int
  $ManagerUserID: Int
) {
  getOrganizationAudienceOverlapRequests(
    OrganizationID: $OrganizationID
    Limit: $Limit
    ManagerUserID: $ManagerUserID
  ) {
    SocialBrandSearchRequestID
    OrganizationID
    Talents
    RequestName
    Description
    DateAdded
    AddedBy
    Status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationAudienceOverlapRequestsQueryVariables,
  APITypes.GetOrganizationAudienceOverlapRequestsQuery
>;
export const getUserIsActiveAndOrgID = /* GraphQL */ `query GetUserIsActiveAndOrgID($UserEmail: String!) {
  getUserIsActiveAndOrgID(UserEmail: $UserEmail) {
    IsActive
    OrganizationID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserIsActiveAndOrgIDQueryVariables,
  APITypes.GetUserIsActiveAndOrgIDQuery
>;
export const getIsOrganizationActive = /* GraphQL */ `query GetIsOrganizationActive($OrganizationID: Int!) {
  getIsOrganizationActive(OrganizationID: $OrganizationID)
}
` as GeneratedQuery<
  APITypes.GetIsOrganizationActiveQueryVariables,
  APITypes.GetIsOrganizationActiveQuery
>;
export const getIsActiveUserFromId = /* GraphQL */ `query GetIsActiveUserFromId($UserID: Int!) {
  getIsActiveUserFromId(UserID: $UserID)
}
` as GeneratedQuery<
  APITypes.GetIsActiveUserFromIdQueryVariables,
  APITypes.GetIsActiveUserFromIdQuery
>;
export const getSocialHandle = /* GraphQL */ `query GetSocialHandle($TalentID: Int!, $SocialNetwork: String!) {
  getSocialHandle(TalentID: $TalentID, SocialNetwork: $SocialNetwork)
}
` as GeneratedQuery<
  APITypes.GetSocialHandleQueryVariables,
  APITypes.GetSocialHandleQuery
>;
export const getSocialMediaReachResult = /* GraphQL */ `query GetSocialMediaReachResult($TalentID: Int!, $SocialNetwork: String!) {
  getSocialMediaReachResult(
    TalentID: $TalentID
    SocialNetwork: $SocialNetwork
  ) {
    Payload
    Timestamp
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialMediaReachResultQueryVariables,
  APITypes.GetSocialMediaReachResultQuery
>;
export const scrapeSocialMediaAccount = /* GraphQL */ `query ScrapeSocialMediaAccount(
  $talentID: Int!
  $socialHandle: String!
  $socialNetwork: String!
  $Env: String!
) {
  scrapeSocialMediaAccount(
    talentID: $talentID
    socialHandle: $socialHandle
    socialNetwork: $socialNetwork
    Env: $Env
  )
}
` as GeneratedQuery<
  APITypes.ScrapeSocialMediaAccountQueryVariables,
  APITypes.ScrapeSocialMediaAccountQuery
>;
export const getSocialHandles = /* GraphQL */ `query GetSocialHandles($TalentID: Int!) {
  getSocialHandles(TalentID: $TalentID) {
    OtherAnswer
    SocialHandle
    KeychainTalentQuestionID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialHandlesQueryVariables,
  APITypes.GetSocialHandlesQuery
>;
export const getCaptureLinkClicks = /* GraphQL */ `query GetCaptureLinkClicks($CaptureLinkID: Int!) {
  getCaptureLinkClicks(CaptureLinkID: $CaptureLinkID) {
    TotalClicks
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCaptureLinkClicksQueryVariables,
  APITypes.GetCaptureLinkClicksQuery
>;
export const checkTemporaryEmail = /* GraphQL */ `query CheckTemporaryEmail($UserEmail: String!) {
  checkTemporaryEmail(UserEmail: $UserEmail) {
    TemporaryEmail
    UserEmail
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckTemporaryEmailQueryVariables,
  APITypes.CheckTemporaryEmailQuery
>;
export const getGoodPurposeInitiatives = /* GraphQL */ `query GetGoodPurposeInitiatives {
  getGoodPurposeInitiatives {
    GoodPurposeInitiativeID
    GoodPurposeInitiativeName
    ParentTagID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGoodPurposeInitiativesQueryVariables,
  APITypes.GetGoodPurposeInitiativesQuery
>;
export const getTalentGoodPurposeInitiativeAffinitiesByTalentID = /* GraphQL */ `query GetTalentGoodPurposeInitiativeAffinitiesByTalentID($TalentID: Int) {
  getTalentGoodPurposeInitiativeAffinitiesByTalentID(TalentID: $TalentID) {
    TalentGoodPurposeInitiativeID
    GoodPurposeInitiativeID
    GoodPurposeInitiativeName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentGoodPurposeInitiativeAffinitiesByTalentIDQueryVariables,
  APITypes.GetTalentGoodPurposeInitiativeAffinitiesByTalentIDQuery
>;
export const getIsEmailTaken = /* GraphQL */ `query GetIsEmailTaken($TemporaryEmail: String!) {
  getIsEmailTaken(TemporaryEmail: $TemporaryEmail)
}
` as GeneratedQuery<
  APITypes.GetIsEmailTakenQueryVariables,
  APITypes.GetIsEmailTakenQuery
>;
export const getExportRequests = /* GraphQL */ `query GetExportRequests($UserID: Int!) {
  getExportRequests(UserID: $UserID) {
    ExportName
    FileNamePath
    ExportRequestAdded
    TalentFullName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetExportRequestsQueryVariables,
  APITypes.GetExportRequestsQuery
>;
export const getSingleTalentAccounts = /* GraphQL */ `query GetSingleTalentAccounts($IsActive: Boolean) {
  getSingleTalentAccounts(IsActive: $IsActive) {
    UserID
    TalentName
    TalentEmail
    StartDate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSingleTalentAccountsQueryVariables,
  APITypes.GetSingleTalentAccountsQuery
>;
export const getUserAndOrganizationStatusForCaptureLink = /* GraphQL */ `query GetUserAndOrganizationStatusForCaptureLink($CaptureLinkID: Int!) {
  getUserAndOrganizationStatusForCaptureLink(CaptureLinkID: $CaptureLinkID) {
    UserIsActive
    OrganizationIsActive
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAndOrganizationStatusForCaptureLinkQueryVariables,
  APITypes.GetUserAndOrganizationStatusForCaptureLinkQuery
>;
export const getBrandQuestionnaireCompletionPercentageData = /* GraphQL */ `query GetBrandQuestionnaireCompletionPercentageData(
  $OrganizationID: Int!
  $ManagerUserID: Int
) {
  getBrandQuestionnaireCompletionPercentageData(
    OrganizationID: $OrganizationID
    ManagerUserID: $ManagerUserID
  ) {
    Percentage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandQuestionnaireCompletionPercentageDataQueryVariables,
  APITypes.GetBrandQuestionnaireCompletionPercentageDataQuery
>;
export const getKeychainTalentQuestions = /* GraphQL */ `query GetKeychainTalentQuestions {
  getKeychainTalentQuestions {
    KeychainTalentQuestionID
    Description
    InputType
    Placeholder
    DisplayRank
    IsHidden
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentQuestionsQueryVariables,
  APITypes.GetKeychainTalentQuestionsQuery
>;
export const getKeychainTalentPrimaryAnswers = /* GraphQL */ `query GetKeychainTalentPrimaryAnswers(
  $KeychainTalentQuestionID: Int!
  $Keyword: String
) {
  getKeychainTalentPrimaryAnswers(
    KeychainTalentQuestionID: $KeychainTalentQuestionID
    Keyword: $Keyword
  ) {
    KeychainTalentPrimaryAnswerID
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentPrimaryAnswersQueryVariables,
  APITypes.GetKeychainTalentPrimaryAnswersQuery
>;
export const getKeychainTalentSecondaryAnswers = /* GraphQL */ `query GetKeychainTalentSecondaryAnswers($KeychainTalentPrimaryAnswerID: Int!) {
  getKeychainTalentSecondaryAnswers(
    KeychainTalentPrimaryAnswerID: $KeychainTalentPrimaryAnswerID
  ) {
    KeychainTalentSecondaryAnswerID
    Description
    Label
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentSecondaryAnswersQueryVariables,
  APITypes.GetKeychainTalentSecondaryAnswersQuery
>;
export const getKeychainTalentTertiaryAnswers = /* GraphQL */ `query GetKeychainTalentTertiaryAnswers($KeychainTalentSecondaryAnswerID: Int!) {
  getKeychainTalentTertiaryAnswers(
    KeychainTalentSecondaryAnswerID: $KeychainTalentSecondaryAnswerID
  ) {
    KeychainTalentTertiaryAnswerID
    Description
    Label
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentTertiaryAnswersQueryVariables,
  APITypes.GetKeychainTalentTertiaryAnswersQuery
>;
export const getKeychainTalentAnswer = /* GraphQL */ `query GetKeychainTalentAnswer(
  $KeychainTalentQuestionID: Int!
  $TalentID: Int!
) {
  getKeychainTalentAnswer(
    KeychainTalentQuestionID: $KeychainTalentQuestionID
    TalentID: $TalentID
  ) {
    KeychainTalentAnswerID
    KeychainTalentPrimaryAnswerID
    KeychainTalentSecondaryAnswerID
    OtherAnswer
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentAnswerQueryVariables,
  APITypes.GetKeychainTalentAnswerQuery
>;
export const getKeychainTalentPrimaryAnswerDescription = /* GraphQL */ `query GetKeychainTalentPrimaryAnswerDescription(
  $KeychainTalentPrimaryAnswerID: Int!
) {
  getKeychainTalentPrimaryAnswerDescription(
    KeychainTalentPrimaryAnswerID: $KeychainTalentPrimaryAnswerID
  )
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentPrimaryAnswerDescriptionQueryVariables,
  APITypes.GetKeychainTalentPrimaryAnswerDescriptionQuery
>;
export const getIndustryPrimaryAnswers = /* GraphQL */ `query GetIndustryPrimaryAnswers {
  getIndustryPrimaryAnswers {
    KeychainTalentPrimaryAnswerID
    Description
    HasSecondaryAnswers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndustryPrimaryAnswersQueryVariables,
  APITypes.GetIndustryPrimaryAnswersQuery
>;
export const getIndustryTertiaryAnswers = /* GraphQL */ `query GetIndustryTertiaryAnswers($KeychainTalentPrimaryAnswerID: Int!) {
  getIndustryTertiaryAnswers(
    KeychainTalentPrimaryAnswerID: $KeychainTalentPrimaryAnswerID
  ) {
    KeychainTalentTertiaryAnswerID
    Description
    Label
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndustryTertiaryAnswersQueryVariables,
  APITypes.GetIndustryTertiaryAnswersQuery
>;
export const getKeyLinkFormAdditionalQuestions = /* GraphQL */ `query GetKeyLinkFormAdditionalQuestions($KeyLinkID: Int!) {
  getKeyLinkFormAdditionalQuestions(KeyLinkID: $KeyLinkID) {
    KeyLinkID
    QuestionLabel
    Enabled
    Required
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyLinkFormAdditionalQuestionsQueryVariables,
  APITypes.GetKeyLinkFormAdditionalQuestionsQuery
>;
export const getKeyLinkFormCustomQuestion = /* GraphQL */ `query GetKeyLinkFormCustomQuestion($KeyLinkID: Int!) {
  getKeyLinkFormCustomQuestion(KeyLinkID: $KeyLinkID) {
    CustomQuestionID
    Question
    Required
    DeletedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyLinkFormCustomQuestionQueryVariables,
  APITypes.GetKeyLinkFormCustomQuestionQuery
>;
export const getProfileSectionsCompletion = /* GraphQL */ `query GetProfileSectionsCompletion($TalentID: Int) {
  getProfileSectionsCompletion(TalentID: $TalentID) {
    ProfileSection
    StepsCompleted
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileSectionsCompletionQueryVariables,
  APITypes.GetProfileSectionsCompletionQuery
>;
export const getAudienceRefreshDate = /* GraphQL */ `query GetAudienceRefreshDate($TalentID: Int!) {
  getAudienceRefreshDate(TalentID: $TalentID) {
    RequestDate
    FulfillmentDate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAudienceRefreshDateQueryVariables,
  APITypes.GetAudienceRefreshDateQuery
>;
export const getLastLotameAudienceDataPullRequestFulfillmentDate = /* GraphQL */ `query GetLastLotameAudienceDataPullRequestFulfillmentDate($TalentID: Int!) {
  getLastLotameAudienceDataPullRequestFulfillmentDate(TalentID: $TalentID)
}
` as GeneratedQuery<
  APITypes.GetLastLotameAudienceDataPullRequestFulfillmentDateQueryVariables,
  APITypes.GetLastLotameAudienceDataPullRequestFulfillmentDateQuery
>;
export const getLastFulfilledLotameAudienceDataPullRequestFulfillmentDate = /* GraphQL */ `query GetLastFulfilledLotameAudienceDataPullRequestFulfillmentDate(
  $TalentID: Int!
) {
  getLastFulfilledLotameAudienceDataPullRequestFulfillmentDate(
    TalentID: $TalentID
  )
}
` as GeneratedQuery<
  APITypes.GetLastFulfilledLotameAudienceDataPullRequestFulfillmentDateQueryVariables,
  APITypes.GetLastFulfilledLotameAudienceDataPullRequestFulfillmentDateQuery
>;
export const getOrganizationAdminsAndManagers = /* GraphQL */ `query GetOrganizationAdminsAndManagers(
  $OrganizationID: Int!
  $IsActive: Boolean!
  $SearchTerm: String
  $Page: Int
) {
  getOrganizationAdminsAndManagers(
    OrganizationID: $OrganizationID
    IsActive: $IsActive
    SearchTerm: $SearchTerm
    Page: $Page
  ) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsAdmin
    UserIsManager
    UserIsTalent
    UserAcceptedInvitation
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserEmail
    UserSMS
    UserCognitoUID
    UserBio
    UserCompletedOnboarding
    OrganizationID
    AudienceID
    IsMediaOptIn
    BirthDate
    HasFinishedQuestions
    IsActive
    TemporaryEmail
    TotalTalents
    ProfileCompletion
    DownloadAccess
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationAdminsAndManagersQueryVariables,
  APITypes.GetOrganizationAdminsAndManagersQuery
>;
export const getOrgAdminsAndManagersData = /* GraphQL */ `query GetOrgAdminsAndManagersData(
  $OrganizationID: Int!
  $IsActive: Boolean!
  $SearchTerm: String
) {
  getOrgAdminsAndManagersData(
    OrganizationID: $OrganizationID
    IsActive: $IsActive
    SearchTerm: $SearchTerm
  ) {
    TotalOrgAdminsAndManagers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrgAdminsAndManagersDataQueryVariables,
  APITypes.GetOrgAdminsAndManagersDataQuery
>;
export const getKeychainPerformance = /* GraphQL */ `query GetKeychainPerformance(
  $TalentID: Int!
  $keychainProfileURL: String!
  $Env: String!
) {
  getKeychainPerformance(
    TalentID: $TalentID
    keychainProfileURL: $keychainProfileURL
    Env: $Env
  ) {
    TotalKeyLinkSignUps
    ReturningFans
    AVGKeyLinkRegistrationFan
    TotalPhoneNumbers
    TotalFanRegistration {
      TotalFanRegistration
      KeychainFanRegistrationAddedMonth
      __typename
    }
    RegistrationWithWithoutPhone {
      TotalPhoneNumbers
      TotalRegistrationsWithPhone
      TotalRegistrationsWithoutPhone
      __typename
    }
    NewVsReturningFans {
      TotalFanRegistration
      ReturningFans
      NewFans
      __typename
    }
    TotalPageViews
    TotalAvgSessionTime
    ClicksByWeek {
      DateWeek
      TotalContentUploadClicks
      TotalGeneralPromotionClicks
      TotalSocialMediaClicks
      TotalWebsiteClicks
      __typename
    }
    BreakdownClicks {
      TotalContentUploadClicks
      TotalGeneralPromotionClicks
      TotalSocialMediaClicks
      TotalWebsiteClicks
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainPerformanceQueryVariables,
  APITypes.GetKeychainPerformanceQuery
>;
export const getTalentsOfOrganization = /* GraphQL */ `query GetTalentsOfOrganization(
  $OrganizationID: Int!
  $Query: String
  $Page: Int
) {
  getTalentsOfOrganization(
    OrganizationID: $OrganizationID
    Query: $Query
    Page: $Page
  ) {
    UserID
    UserFirstName
    UserLastName
    UserEmail
    UserAcceptedInvitation
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentsOfOrganizationQueryVariables,
  APITypes.GetTalentsOfOrganizationQuery
>;
export const getTalentsOfOrganizationCount = /* GraphQL */ `query GetTalentsOfOrganizationCount($OrganizationID: Int!, $Query: String) {
  getTalentsOfOrganizationCount(OrganizationID: $OrganizationID, Query: $Query)
}
` as GeneratedQuery<
  APITypes.GetTalentsOfOrganizationCountQueryVariables,
  APITypes.GetTalentsOfOrganizationCountQuery
>;
export const getTalentsOfManager = /* GraphQL */ `query GetTalentsOfManager($ManagerID: Int!, $Query: String, $Page: Int) {
  getTalentsOfManager(ManagerID: $ManagerID, Query: $Query, Page: $Page) {
    UserID
    UserFirstName
    UserLastName
    UserEmail
    UserAcceptedInvitation
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentsOfManagerQueryVariables,
  APITypes.GetTalentsOfManagerQuery
>;
export const getTalentsOfManagerCount = /* GraphQL */ `query GetTalentsOfManagerCount($ManagerID: Int!, $Query: String) {
  getTalentsOfManagerCount(ManagerID: $ManagerID, Query: $Query)
}
` as GeneratedQuery<
  APITypes.GetTalentsOfManagerCountQueryVariables,
  APITypes.GetTalentsOfManagerCountQuery
>;
export const getManagersOfTalent = /* GraphQL */ `query GetManagersOfTalent($TalentID: Int!) {
  getManagersOfTalent(TalentID: $TalentID) {
    UserID
    UserFirstName
    UserLastName
    UserEmail
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetManagersOfTalentQueryVariables,
  APITypes.GetManagersOfTalentQuery
>;
export const getTalentProfileAccess = /* GraphQL */ `query GetTalentProfileAccess($ManagerID: Int!, $OrganizationID: Int!) {
  getTalentProfileAccess(
    ManagerID: $ManagerID
    OrganizationID: $OrganizationID
  ) {
    TalentID
    AccountManagerID
    UserFirstName
    UserLastName
    UserEmail
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentProfileAccessQueryVariables,
  APITypes.GetTalentProfileAccessQuery
>;
export const getAvailableTalentsForManager = /* GraphQL */ `query GetAvailableTalentsForManager($OrganizationID: Int!, $ManagerID: Int!) {
  getAvailableTalentsForManager(
    OrganizationID: $OrganizationID
    ManagerID: $ManagerID
  ) {
    UserID
    UserFullName
    UserEmail
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAvailableTalentsForManagerQueryVariables,
  APITypes.GetAvailableTalentsForManagerQuery
>;
export const getIsTalentPartOfManagersRoster = /* GraphQL */ `query GetIsTalentPartOfManagersRoster($TalentID: Int!, $ManagerID: Int!) {
  getIsTalentPartOfManagersRoster(TalentID: $TalentID, ManagerID: $ManagerID)
}
` as GeneratedQuery<
  APITypes.GetIsTalentPartOfManagersRosterQueryVariables,
  APITypes.GetIsTalentPartOfManagersRosterQuery
>;
export const getOrganizationManagers = /* GraphQL */ `query GetOrganizationManagers($OrganizationID: Int!, $TalentID: Int!) {
  getOrganizationManagers(
    OrganizationID: $OrganizationID
    TalentID: $TalentID
  ) {
    UserID
    ManagerFullName
    UserEmail
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationManagersQueryVariables,
  APITypes.GetOrganizationManagersQuery
>;
export const getDeactivatedAccounts = /* GraphQL */ `query GetDeactivatedAccounts(
  $OrganizationID: Int!
  $IsActive: Boolean!
  $SearchTerm: String
  $Page: Int
) {
  getDeactivatedAccounts(
    OrganizationID: $OrganizationID
    IsActive: $IsActive
    SearchTerm: $SearchTerm
    Page: $Page
  ) {
    UserID
    UserFirstName
    UserLastName
    UserAvatar
    UserBackgroundImage
    UserAdded
    UserIsAdmin
    UserIsManager
    UserIsTalent
    UserAcceptedInvitation
    UserIsVerifiedForEventCreation
    UserIsTeam
    UserEmail
    UserSMS
    UserCognitoUID
    UserBio
    UserCompletedOnboarding
    OrganizationID
    AudienceID
    IsMediaOptIn
    BirthDate
    HasFinishedQuestions
    IsActive
    TemporaryEmail
    TotalTalents
    ProfileCompletion
    DownloadAccess
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeactivatedAccountsQueryVariables,
  APITypes.GetDeactivatedAccountsQuery
>;
export const getDeactivatedAccountsData = /* GraphQL */ `query GetDeactivatedAccountsData(
  $OrganizationID: Int!
  $IsActive: Boolean!
  $SearchTerm: String
) {
  getDeactivatedAccountsData(
    OrganizationID: $OrganizationID
    IsActive: $IsActive
    SearchTerm: $SearchTerm
  ) {
    TotalDeactivatedAccounts
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeactivatedAccountsDataQueryVariables,
  APITypes.GetDeactivatedAccountsDataQuery
>;
export const getTalentInsights = /* GraphQL */ `query GetTalentInsights($TalentID: Int!, $Env: String!) {
  getTalentInsights(TalentID: $TalentID, Env: $Env) {
    behaviors {
      __typename
    }
    topRegionsCountries {
      behaviorName
      percentage
      __typename
    }
    topRegionsUSDMA {
      behaviorName
      percentage
      __typename
    }
    uniques
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentInsightsQueryVariables,
  APITypes.GetTalentInsightsQuery
>;
export const getTalentRosterFilters = /* GraphQL */ `query GetTalentRosterFilters($Where: String) {
  getTalentRosterFilters(Where: $Where) {
    FilterID
    TalentSearchFieldName
    FilterLabel
    SourceID
    SummaryTableColumnName
    SourceType
    DisplayType
    InputType
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentRosterFiltersQueryVariables,
  APITypes.GetTalentRosterFiltersQuery
>;
export const getBrandsOfOrganization = /* GraphQL */ `query GetBrandsOfOrganization(
  $OrganizationID: Int!
  $Query: String
  $Page: Int
) {
  getBrandsOfOrganization(
    OrganizationID: $OrganizationID
    Query: $Query
    Page: $Page
  ) {
    BrandID
    BrandName
    Logo
    Industry
    TwitterURL
    YoutubeURL
    InstagramURL
    LinkedinURL
    Contacts
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandsOfOrganizationQueryVariables,
  APITypes.GetBrandsOfOrganizationQuery
>;
export const getBrandsOfOrganizationCount = /* GraphQL */ `query GetBrandsOfOrganizationCount($OrganizationID: Int!, $Query: String) {
  getBrandsOfOrganizationCount(OrganizationID: $OrganizationID, Query: $Query)
}
` as GeneratedQuery<
  APITypes.GetBrandsOfOrganizationCountQueryVariables,
  APITypes.GetBrandsOfOrganizationCountQuery
>;
export const getTopBrandsOfOrganization = /* GraphQL */ `query GetTopBrandsOfOrganization($OrganizationID: Int!) {
  getTopBrandsOfOrganization(OrganizationID: $OrganizationID) {
    BrandID
    BrandName
    Logo
    ContactCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTopBrandsOfOrganizationQueryVariables,
  APITypes.GetTopBrandsOfOrganizationQuery
>;
export const getProfileQuestionsOptions = /* GraphQL */ `query GetProfileQuestionsOptions($sourceID: Int!, $filterLabel: String!) {
  getProfileQuestionsOptions(sourceID: $sourceID, filterLabel: $filterLabel) {
    KeychainTalentPrimaryAnswerID
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQuestionsOptionsQueryVariables,
  APITypes.GetProfileQuestionsOptionsQuery
>;
export const getBrandContacts = /* GraphQL */ `query GetBrandContacts($BrandID: Int!, $SearchTerm: String, $Page: Int) {
  getBrandContacts(BrandID: $BrandID, SearchTerm: $SearchTerm, Page: $Page) {
    BrandID
    UpleadContactID
    FirstName
    LastName
    Title
    JobFunction
    ManagementLevel
    Gender
    Email
    EmailStatus
    Phone
    MobileDirectDial
    Address
    City
    State
    Country
    LinkedInURL
    Active
    LastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandContactsQueryVariables,
  APITypes.GetBrandContactsQuery
>;
export const getBrandContactsData = /* GraphQL */ `query GetBrandContactsData($BrandID: Int!, $SearchTerm: String) {
  getBrandContactsData(BrandID: $BrandID, SearchTerm: $SearchTerm) {
    TotalBrandContacts
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandContactsDataQueryVariables,
  APITypes.GetBrandContactsDataQuery
>;
export const getBrandCompanyInfo = /* GraphQL */ `query GetBrandCompanyInfo($BrandID: Int!) {
  getBrandCompanyInfo(BrandID: $BrandID) {
    BrandID
    BrandName
    UpleadBrandID
    Domain
    Address
    City
    State
    Zip
    Country
    PhoneNumber
    Employees
    Revenue
    Industry
    SICCode
    SICDescription
    NAICSCode
    NAICSDescription
    Description
    YearFounded
    Logo
    LinkedInURL
    TwitterURL
    FacebookURL
    YoutubeURL
    CrunchbaseURL
    YelpURL
    InstagramURL
    Type
    Ticker
    Exchange
    AlexaRank
    NoContactsFound
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBrandCompanyInfoQueryVariables,
  APITypes.GetBrandCompanyInfoQuery
>;
export const getCohorts = /* GraphQL */ `query GetCohorts($Query: String, $Page: Int, $Limit: Int, $Offset: Int) {
  getCohorts(Query: $Query, Page: $Page, Limit: $Limit, Offset: $Offset) {
    CohortID
    Name
    ExportName
    TotalSocialMediaReach
    InstagramFollowers
    InstagramLikes
    InstagramTotalPosts
    TiktokFollowers
    TiktokTotalShares
    TiktokTotalVideos
    TwitterFollowers
    TwitterLikes
    TwitterTotalTweets
    FacebookFollowers
    YoutubeSubscribers
    TotalEmails
    ProjectedMaxMediaSpend
    EstimatedTakeHomeProfit
    ConfidenceScore
    Version
    DateAdded
    DateLastUpdated
    TotalAudienceReach
    Description
    CohortImage
    InDashboard
    ShowKeyScores
    ShowTopDomains
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCohortsQueryVariables,
  APITypes.GetCohortsQuery
>;
export const getCohortsCount = /* GraphQL */ `query GetCohortsCount($Query: String) {
  getCohortsCount(Query: $Query)
}
` as GeneratedQuery<
  APITypes.GetCohortsCountQueryVariables,
  APITypes.GetCohortsCountQuery
>;
export const getCohort = /* GraphQL */ `query GetCohort($CohortID: Int!) {
  getCohort(CohortID: $CohortID) {
    CohortID
    Name
    ExportName
    TotalSocialMediaReach
    InstagramFollowers
    InstagramLikes
    InstagramTotalPosts
    TiktokFollowers
    TiktokTotalShares
    TiktokTotalVideos
    TwitterFollowers
    TwitterLikes
    TwitterTotalTweets
    FacebookFollowers
    YoutubeSubscribers
    TotalEmails
    ProjectedMaxMediaSpend
    EstimatedTakeHomeProfit
    ConfidenceScore
    Version
    DateAdded
    DateLastUpdated
    TotalAudienceReach
    Description
    CohortImage
    InDashboard
    ShowKeyScores
    ShowTopDomains
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCohortQueryVariables, APITypes.GetCohortQuery>;
export const getCohortTalents = /* GraphQL */ `query GetCohortTalents($CohortID: Int!, $Page: Int, $SearchTerm: String) {
  getCohortTalents(CohortID: $CohortID, Page: $Page, SearchTerm: $SearchTerm) {
    UserID
    UserFullName
    SegmentID
    SegmentName
    IsMediaOptIn
    UserAvatar
    HeroStatus
    TotalEmails
    TotalSocialMediaReach
    InstagramFollowers
    TiktokFollowers
    TwitterFollowers
    FacebookFollowers
    YoutubeSubscribers
    SegmentIndustries
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCohortTalentsQueryVariables,
  APITypes.GetCohortTalentsQuery
>;
export const getCohortTalentsCount = /* GraphQL */ `query GetCohortTalentsCount($CohortID: Int!, $SearchTerm: String) {
  getCohortTalentsCount(CohortID: $CohortID, SearchTerm: $SearchTerm)
}
` as GeneratedQuery<
  APITypes.GetCohortTalentsCountQueryVariables,
  APITypes.GetCohortTalentsCountQuery
>;
export const getProfileQuestionsAndQuestionnaireOptions = /* GraphQL */ `query GetProfileQuestionsAndQuestionnaireOptions(
  $sourceType: String!
  $filterLabel: String!
  $searchTerm: String
) {
  getProfileQuestionsAndQuestionnaireOptions(
    sourceType: $sourceType
    filterLabel: $filterLabel
    searchTerm: $searchTerm
  ) {
    ID
    Label
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileQuestionsAndQuestionnaireOptionsQueryVariables,
  APITypes.GetProfileQuestionsAndQuestionnaireOptionsQuery
>;
export const getKeychainAdditionalQuestions = /* GraphQL */ `query GetKeychainAdditionalQuestions($KeyLinkID: Int!, $TalentID: Int) {
  getKeychainAdditionalQuestions(KeyLinkID: $KeyLinkID, TalentID: $TalentID) {
    KeychainAdditionalQuestionID
    KeyLinkID
    QuestionLabel
    Enabled
    Required
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainAdditionalQuestionsQueryVariables,
  APITypes.GetKeychainAdditionalQuestionsQuery
>;
export const getKeychainCustomQuestion = /* GraphQL */ `query GetKeychainCustomQuestion($KeyLinkID: Int!, $TalentID: Int) {
  getKeychainCustomQuestion(KeyLinkID: $KeyLinkID, TalentID: $TalentID) {
    CustomQuestionID
    KeyLinkID
    Question
    Required
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainCustomQuestionQueryVariables,
  APITypes.GetKeychainCustomQuestionQuery
>;
export const getSocialOverlapInsightsOfOrganization = /* GraphQL */ `query GetSocialOverlapInsightsOfOrganization(
  $OrganizationID: Int!
  $SocialOverlapRequestID: Int!
  $RollUp: Boolean!
  $LevelClause: String!
  $CategoryClause: String
  $BehaviorNameClause: String
  $GroupByClause: String!
  $OrderByClause: String!
  $Level: String
) {
  getSocialOverlapInsightsOfOrganization(
    OrganizationID: $OrganizationID
    SocialOverlapRequestID: $SocialOverlapRequestID
    RollUp: $RollUp
    LevelClause: $LevelClause
    CategoryClause: $CategoryClause
    BehaviorNameClause: $BehaviorNameClause
    GroupByClause: $GroupByClause
    OrderByClause: $OrderByClause
    Level: $Level
  ) {
    Level2
    Level3
    Level4
    Level5
    Level6
    Level7
    Level8
    Percentage
    BehaviorIndex
    BehaviorName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialOverlapInsightsOfOrganizationQueryVariables,
  APITypes.GetSocialOverlapInsightsOfOrganizationQuery
>;
export const getSocialOverlapInsightsOfOrganizationData = /* GraphQL */ `query GetSocialOverlapInsightsOfOrganizationData(
  $OrganizationID: Int!
  $SocialOverlapRequestID: Int!
  $RollUp: Boolean!
  $LevelClause: String!
  $GroupByClause: String!
) {
  getSocialOverlapInsightsOfOrganizationData(
    OrganizationID: $OrganizationID
    SocialOverlapRequestID: $SocialOverlapRequestID
    RollUp: $RollUp
    LevelClause: $LevelClause
    GroupByClause: $GroupByClause
  ) {
    TotalBehaviorsPerCategory
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialOverlapInsightsOfOrganizationDataQueryVariables,
  APITypes.GetSocialOverlapInsightsOfOrganizationDataQuery
>;
export const getSocialOverlapRequestName = /* GraphQL */ `query GetSocialOverlapRequestName($SocialOverlapRequestID: Int!) {
  getSocialOverlapRequestName(SocialOverlapRequestID: $SocialOverlapRequestID) {
    RequestName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialOverlapRequestNameQueryVariables,
  APITypes.GetSocialOverlapRequestNameQuery
>;
export const getSocialOverlapRequestID = /* GraphQL */ `query GetSocialOverlapRequestID($TalentID: Int!) {
  getSocialOverlapRequestID(TalentID: $TalentID) {
    SocialOverlapRequestID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialOverlapRequestIDQueryVariables,
  APITypes.GetSocialOverlapRequestIDQuery
>;
export const getSocialOverlapInsightsSearchResults = /* GraphQL */ `query GetSocialOverlapInsightsSearchResults($Where: String, $OrderBy: String) {
  getSocialOverlapInsightsSearchResults(Where: $Where, OrderBy: $OrderBy) {
    SocialOverlapInsightID
    BehaviorName
    BehaviorIndex
    Percentage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialOverlapInsightsSearchResultsQueryVariables,
  APITypes.GetSocialOverlapInsightsSearchResultsQuery
>;
export const getSocialOverlapInsightsSearchResultsBySubCategory = /* GraphQL */ `query GetSocialOverlapInsightsSearchResultsBySubCategory(
  $RequestID: Int!
  $SubCategory: String!
) {
  getSocialOverlapInsightsSearchResultsBySubCategory(
    RequestID: $RequestID
    SubCategory: $SubCategory
  ) {
    SocialOverlapInsightID
    BehaviorName
    BehaviorIndex
    Percentage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialOverlapInsightsSearchResultsBySubCategoryQueryVariables,
  APITypes.GetSocialOverlapInsightsSearchResultsBySubCategoryQuery
>;
export const getTalentsTiedToManagerOrOrg = /* GraphQL */ `query GetTalentsTiedToManagerOrOrg($OrganizationID: Int!, $ManagerID: Int) {
  getTalentsTiedToManagerOrOrg(
    OrganizationID: $OrganizationID
    ManagerID: $ManagerID
  ) {
    UserID
    UserFullName
    UserEmail
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentsTiedToManagerOrOrgQueryVariables,
  APITypes.GetTalentsTiedToManagerOrOrgQuery
>;
export const getSocialOverlapRequestStatus = /* GraphQL */ `query GetSocialOverlapRequestStatus($SocialOverlapRequestID: Int) {
  getSocialOverlapRequestStatus(
    SocialOverlapRequestID: $SocialOverlapRequestID
  ) {
    Status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialOverlapRequestStatusQueryVariables,
  APITypes.GetSocialOverlapRequestStatusQuery
>;
export const getSocialOverlapRequestStatusByName = /* GraphQL */ `query GetSocialOverlapRequestStatusByName($RequestName: String) {
  getSocialOverlapRequestStatusByName(RequestName: $RequestName) {
    Status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSocialOverlapRequestStatusByNameQueryVariables,
  APITypes.GetSocialOverlapRequestStatusByNameQuery
>;
export const getCohortHeroes = /* GraphQL */ `query GetCohortHeroes($CohortID: Int!) {
  getCohortHeroes(CohortID: $CohortID) {
    UserID
    UserFullName
    UserAvatar
    IndustryAnswer
    UserBio
    UserKeyScore
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCohortHeroesQueryVariables,
  APITypes.GetCohortHeroesQuery
>;
export const getCohortHeroIDs = /* GraphQL */ `query GetCohortHeroIDs($CohortID: Int!) {
  getCohortHeroIDs(CohortID: $CohortID) {
    UserID
    UserFullName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCohortHeroIDsQueryVariables,
  APITypes.GetCohortHeroIDsQuery
>;
export const getLotameDemographicInfoOptions = /* GraphQL */ `query GetLotameDemographicInfoOptions($Demographic: String!) {
  getLotameDemographicInfoOptions(Demographic: $Demographic) {
    LotameDemographicInfoID
    Value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLotameDemographicInfoOptionsQueryVariables,
  APITypes.GetLotameDemographicInfoOptionsQuery
>;
export const getLotameAudienceDataOptions = /* GraphQL */ `query GetLotameAudienceDataOptions(
  $sourceType: String!
  $filterLabel: String!
  $searchTerm: String
) {
  getLotameAudienceDataOptions(
    sourceType: $sourceType
    filterLabel: $filterLabel
    searchTerm: $searchTerm
  ) {
    ID
    Label
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLotameAudienceDataOptionsQueryVariables,
  APITypes.GetLotameAudienceDataOptionsQuery
>;
export const triggerCohortFilters = /* GraphQL */ `query TriggerCohortFilters($filters: String!, $CohortID: Int!, $Env: String!) {
  triggerCohortFilters(filters: $filters, CohortID: $CohortID, Env: $Env)
}
` as GeneratedQuery<
  APITypes.TriggerCohortFiltersQueryVariables,
  APITypes.TriggerCohortFiltersQuery
>;
export const getTalentCohortPoolData = /* GraphQL */ `query GetTalentCohortPoolData(
  $Users: String!
  $CohortID: Int!
  $ShowEmails: String
  $ShowSocials: String
  $ShowBoth: String
) {
  getTalentCohortPoolData(
    Users: $Users
    CohortID: $CohortID
    ShowEmails: $ShowEmails
    ShowSocials: $ShowSocials
    ShowBoth: $ShowBoth
  ) {
    UserID
    UserFullName
    UserAvatar
    IsMediaOptIn
    TotalEmails
    TotalInstagramFollowers
    TotalTiktokFollowers
    TotalTwitterFollowers
    TotalFacebookFollowers
    TotalYoutubeSubscribers
    FirstPartyFilter
    ThirdPartyFilter
    PrimaryDescription
    SecondaryDescription
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentCohortPoolDataQueryVariables,
  APITypes.GetTalentCohortPoolDataQuery
>;
export const getTalentCohortUserIDs = /* GraphQL */ `query GetTalentCohortUserIDs($CohortID: Int!, $SearchTerm: String) {
  getTalentCohortUserIDs(CohortID: $CohortID, SearchTerm: $SearchTerm) {
    UserID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentCohortUserIDsQueryVariables,
  APITypes.GetTalentCohortUserIDsQuery
>;
export const getTalentCohortStatus = /* GraphQL */ `query GetTalentCohortStatus($CohortID: Int!) {
  getTalentCohortStatus(CohortID: $CohortID) {
    Status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentCohortStatusQueryVariables,
  APITypes.GetTalentCohortStatusQuery
>;
export const getAllBehaviors = /* GraphQL */ `query GetAllBehaviors($Page: Int, $SearchTerm: String) {
  getAllBehaviors(Page: $Page, SearchTerm: $SearchTerm) {
    BehaviorName
    Path
    IndustryVertical
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllBehaviorsQueryVariables,
  APITypes.GetAllBehaviorsQuery
>;
export const getAllBehaviorsCount = /* GraphQL */ `query GetAllBehaviorsCount($SearchTerm: String) {
  getAllBehaviorsCount(SearchTerm: $SearchTerm)
}
` as GeneratedQuery<
  APITypes.GetAllBehaviorsCountQueryVariables,
  APITypes.GetAllBehaviorsCountQuery
>;
export const getLotameBehaviorsIndustryVerticals = /* GraphQL */ `query GetLotameBehaviorsIndustryVerticals {
  getLotameBehaviorsIndustryVerticals {
    IndustryVerticalID
    IndustryVerticalName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLotameBehaviorsIndustryVerticalsQueryVariables,
  APITypes.GetLotameBehaviorsIndustryVerticalsQuery
>;
export const getLotameBehaviorsInsights = /* GraphQL */ `query GetLotameBehaviorsInsights($UserID: Int!, $IndustryVertical: Int!) {
  getLotameBehaviorsInsights(
    UserID: $UserID
    IndustryVertical: $IndustryVertical
  ) {
    BehaviorName
    BehaviorPath
    BehaviorIndex
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLotameBehaviorsInsightsQueryVariables,
  APITypes.GetLotameBehaviorsInsightsQuery
>;
export const getLotameDemographicsInsights = /* GraphQL */ `query GetLotameDemographicsInsights(
  $UserID: Int!
  $Category: LotameDemographicsInsightsCategory!
) {
  getLotameDemographicsInsights(UserID: $UserID, Category: $Category) {
    Demographic
    Value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLotameDemographicsInsightsQueryVariables,
  APITypes.GetLotameDemographicsInsightsQuery
>;
export const getAppliedCohortFilters = /* GraphQL */ `query GetAppliedCohortFilters($CohortID: Int!) {
  getAppliedCohortFilters(CohortID: $CohortID) {
    FilterLabel
    SourceType
    DisplayType
    Values
    Index
    Exclude
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppliedCohortFiltersQueryVariables,
  APITypes.GetAppliedCohortFiltersQuery
>;
export const getAppliedCohortFiltersV2 = /* GraphQL */ `query GetAppliedCohortFiltersV2($CohortID: Int!) {
  getAppliedCohortFiltersV2(CohortID: $CohortID)
}
` as GeneratedQuery<
  APITypes.GetAppliedCohortFiltersV2QueryVariables,
  APITypes.GetAppliedCohortFiltersV2Query
>;
export const getMultiselectFilterOptions = /* GraphQL */ `query GetMultiselectFilterOptions($Query: String!) {
  getMultiselectFilterOptions(Query: $Query) {
    OptionID
    OptionLabel
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMultiselectFilterOptionsQueryVariables,
  APITypes.GetMultiselectFilterOptionsQuery
>;
export const getTalentsTiedToAudienceOverlapReport = /* GraphQL */ `query GetTalentsTiedToAudienceOverlapReport($SocialBrandSearchRequestID: Int!) {
  getTalentsTiedToAudienceOverlapReport(
    SocialBrandSearchRequestID: $SocialBrandSearchRequestID
  ) {
    UserID
    UserFullName
    UserEmail
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentsTiedToAudienceOverlapReportQueryVariables,
  APITypes.GetTalentsTiedToAudienceOverlapReportQuery
>;
export const getTalentReportingData = /* GraphQL */ `query GetTalentReportingData(
  $ChartProperty: String!
  $TimeFrame: String!
  $BeginDate: String
  $EndDate: String
  $AppliedFilters: String
) {
  getTalentReportingData(
    ChartProperty: $ChartProperty
    TimeFrame: $TimeFrame
    BeginDate: $BeginDate
    EndDate: $EndDate
    AppliedFilters: $AppliedFilters
  ) {
    Date
    ChartProperty
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentReportingDataQueryVariables,
  APITypes.GetTalentReportingDataQuery
>;
export const getTalentSocialReachSummary = /* GraphQL */ `query GetTalentSocialReachSummary($TalentID: Int!) {
  getTalentSocialReachSummary(TalentID: $TalentID) {
    SocialReachSummaryID
    OrganizationID
    UserID
    FacebookFollowers
    FacebookLikes
    InstagramFollowers
    InstagramLikes
    TiktokFollowers
    TiktokLikes
    TwitterFollowers
    TwitterLikes
    YoutubeSubscribers
    YoutubeTotalViews
    LastUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentSocialReachSummaryQueryVariables,
  APITypes.GetTalentSocialReachSummaryQuery
>;
export const getTalentTotalSocialMediaReach = /* GraphQL */ `query GetTalentTotalSocialMediaReach($UserID: Int!) {
  getTalentTotalSocialMediaReach(UserID: $UserID)
}
` as GeneratedQuery<
  APITypes.GetTalentTotalSocialMediaReachQueryVariables,
  APITypes.GetTalentTotalSocialMediaReachQuery
>;
export const getOrgReportingData = /* GraphQL */ `query GetOrgReportingData($ChartProperty: String!) {
  getOrgReportingData(ChartProperty: $ChartProperty) {
    ChartProperty
    Date
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrgReportingDataQueryVariables,
  APITypes.GetOrgReportingDataQuery
>;
export const getDateFilterLabel = /* GraphQL */ `query GetDateFilterLabel($KeychainTalentPrimaryAnswerID: Int!) {
  getDateFilterLabel(
    KeychainTalentPrimaryAnswerID: $KeychainTalentPrimaryAnswerID
  ) {
    Label
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDateFilterLabelQueryVariables,
  APITypes.GetDateFilterLabelQuery
>;
export const getActivityLogs = /* GraphQL */ `query GetActivityLogs($Limit: Int!, $Page: Int, $Filters: String) {
  getActivityLogs(Limit: $Limit, Page: $Page, Filters: $Filters) {
    ActivityLogID
    ActivityDoneBy
    OrganizationName
    ActivityName
    ActivityDetail
    ActivitySubDetail
    Timestamp
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivityLogsQueryVariables,
  APITypes.GetActivityLogsQuery
>;
export const getActivityLogsCount = /* GraphQL */ `query GetActivityLogsCount($Filters: String) {
  getActivityLogsCount(Filters: $Filters)
}
` as GeneratedQuery<
  APITypes.GetActivityLogsCountQueryVariables,
  APITypes.GetActivityLogsCountQuery
>;
export const getActivityLogsFilters = /* GraphQL */ `query GetActivityLogsFilters {
  getActivityLogsFilters {
    FilterID
    FilterLabel
    FilterType
    DataSourceColumnName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivityLogsFiltersQueryVariables,
  APITypes.GetActivityLogsFiltersQuery
>;
export const getTalentDemographicInfo = /* GraphQL */ `query GetTalentDemographicInfo($TalentID: Int) {
  getTalentDemographicInfo(TalentID: $TalentID) {
    QuestionID
    Answer
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentDemographicInfoQueryVariables,
  APITypes.GetTalentDemographicInfoQuery
>;
export const getNotifications = /* GraphQL */ `query GetNotifications(
  $UserID: Int!
  $Limit: Int!
  $Page: Int
  $Filters: String
) {
  getNotifications(
    UserID: $UserID
    Limit: $Limit
    Page: $Page
    Filters: $Filters
  ) {
    NotificationID
    TalentName
    Notification
    Timestamp
    SeenByUser
    ClickRoute
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationsQueryVariables,
  APITypes.GetNotificationsQuery
>;
export const getUnseenNotificationsCount = /* GraphQL */ `query GetUnseenNotificationsCount($UserID: Int!) {
  getUnseenNotificationsCount(UserID: $UserID)
}
` as GeneratedQuery<
  APITypes.GetUnseenNotificationsCountQueryVariables,
  APITypes.GetUnseenNotificationsCountQuery
>;
export const getNotificationsCount = /* GraphQL */ `query GetNotificationsCount($UserID: Int!, $Filters: String) {
  getNotificationsCount(UserID: $UserID, Filters: $Filters)
}
` as GeneratedQuery<
  APITypes.GetNotificationsCountQueryVariables,
  APITypes.GetNotificationsCountQuery
>;
export const getNotificationCenterFilters = /* GraphQL */ `query GetNotificationCenterFilters {
  getNotificationCenterFilters {
    FilterID
    FilterLabel
    FilterType
    DataSourceColumnName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationCenterFiltersQueryVariables,
  APITypes.GetNotificationCenterFiltersQuery
>;
export const getProfilePreviewData = /* GraphQL */ `query GetProfilePreviewData($TalentID: Int) {
  getProfilePreviewData(TalentID: $TalentID) {
    TalentName
    TalentAvatar
    EmailCount
    SocialReachCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfilePreviewDataQueryVariables,
  APITypes.GetProfilePreviewDataQuery
>;
export const getIndustryAnswers = /* GraphQL */ `query GetIndustryAnswers($TalentID: Int!) {
  getIndustryAnswers(TalentID: $TalentID) {
    KeychainTalentAnswerID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndustryAnswersQueryVariables,
  APITypes.GetIndustryAnswersQuery
>;
export const getIndustryAnswer = /* GraphQL */ `query GetIndustryAnswer($KeychainTalentAnswerID: Int!) {
  getIndustryAnswer(KeychainTalentAnswerID: $KeychainTalentAnswerID) {
    KeychainTalentAnswerID
    TalentID
    KeychainTalentPrimaryAnswerID
    OtherPrimaryAnswer
    KeychainTalentSecondaryAnswerID
    OtherSecondaryAnswer
    KeychainTalentTertiaryAnswerID
    OtherTertiaryAnswer
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndustryAnswerQueryVariables,
  APITypes.GetIndustryAnswerQuery
>;
export const isIndustrySecondaryAnswerAllOfTheAbove = /* GraphQL */ `query IsIndustrySecondaryAnswerAllOfTheAbove($KeychainTalentAnswerID: Int!) {
  isIndustrySecondaryAnswerAllOfTheAbove(
    KeychainTalentAnswerID: $KeychainTalentAnswerID
  )
}
` as GeneratedQuery<
  APITypes.IsIndustrySecondaryAnswerAllOfTheAboveQueryVariables,
  APITypes.IsIndustrySecondaryAnswerAllOfTheAboveQuery
>;
export const getIndustryAnswersForTalentDashboard = /* GraphQL */ `query GetIndustryAnswersForTalentDashboard($TalentID: Int) {
  getIndustryAnswersForTalentDashboard(TalentID: $TalentID) {
    PrimaryAnswer
    SecondaryAnswer
    TertiaryAnswer
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIndustryAnswersForTalentDashboardQueryVariables,
  APITypes.GetIndustryAnswersForTalentDashboardQuery
>;
export const getFirstFiveEmailUploadRows = /* GraphQL */ `query GetFirstFiveEmailUploadRows($S3Key: String!, $Env: String!) {
  getFirstFiveEmailUploadRows(S3Key: $S3Key, Env: $Env) {
    FirstColumn
    SecondColumn
    ThirdColumn
    FourthColumn
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFirstFiveEmailUploadRowsQueryVariables,
  APITypes.GetFirstFiveEmailUploadRowsQuery
>;
export const getTalentNames = /* GraphQL */ `query GetTalentNames($SearchTerm: String!) {
  getTalentNames(SearchTerm: $SearchTerm) {
    Name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentNamesQueryVariables,
  APITypes.GetTalentNamesQuery
>;
export const getTalentCohortXrefs = /* GraphQL */ `query GetTalentCohortXrefs($CohortID: Int!) {
  getTalentCohortXrefs(CohortID: $CohortID) {
    UserID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentCohortXrefsQueryVariables,
  APITypes.GetTalentCohortXrefsQuery
>;
export const getCohortsDashboardData = /* GraphQL */ `query GetCohortsDashboardData($SearchTerm: String) {
  getCohortsDashboardData(SearchTerm: $SearchTerm) {
    CohortID
    ImageURL
    Name
    Description
    ActiveUniques
    SocialReach
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCohortsDashboardDataQueryVariables,
  APITypes.GetCohortsDashboardDataQuery
>;
export const getAllAvailableCohorts = /* GraphQL */ `query GetAllAvailableCohorts($SearchTerm: String) {
  getAllAvailableCohorts(SearchTerm: $SearchTerm) {
    CohortID
    Name
    ExportName
    TotalSocialMediaReach
    InstagramFollowers
    InstagramLikes
    InstagramTotalPosts
    TiktokFollowers
    TiktokTotalShares
    TiktokTotalVideos
    TwitterFollowers
    TwitterLikes
    TwitterTotalTweets
    FacebookFollowers
    YoutubeSubscribers
    TotalEmails
    ProjectedMaxMediaSpend
    EstimatedTakeHomeProfit
    ConfidenceScore
    Version
    DateAdded
    DateLastUpdated
    TotalAudienceReach
    Description
    CohortImage
    InDashboard
    ShowKeyScores
    ShowTopDomains
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllAvailableCohortsQueryVariables,
  APITypes.GetAllAvailableCohortsQuery
>;
export const getKeychainTalentQuestionByID = /* GraphQL */ `query GetKeychainTalentQuestionByID($KeychainTalentQuestionID: Int!) {
  getKeychainTalentQuestionByID(
    KeychainTalentQuestionID: $KeychainTalentQuestionID
  ) {
    KeychainTalentQuestionID
    Description
    InputType
    Placeholder
    DisplayRank
    IsHidden
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentQuestionByIDQueryVariables,
  APITypes.GetKeychainTalentQuestionByIDQuery
>;
export const getKeychainTalentIndustryAnswers = /* GraphQL */ `query GetKeychainTalentIndustryAnswers($TalentID: Int!) {
  getKeychainTalentIndustryAnswers(TalentID: $TalentID) {
    PrimaryAnswerDescription
    PrimaryOther
    SecondaryAnswerDescription
    SecondaryOther
    TertiaryAnswerDescription
    TertiaryOther
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentIndustryAnswersQueryVariables,
  APITypes.GetKeychainTalentIndustryAnswersQuery
>;
export const getTalentSocialMetrics = /* GraphQL */ `query GetTalentSocialMetrics($TalentID: Int!) {
  getTalentSocialMetrics(TalentID: $TalentID) {
    FacebookHandle
    FacebookFollowers
    InstagramHandle
    InstagramFollowers
    TiktokHandle
    TiktokFollowers
    TwitterHandle
    TwitterFollowers
    YoutubeHandle
    YoutubeSubscribers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentSocialMetricsQueryVariables,
  APITypes.GetTalentSocialMetricsQuery
>;
export const getTotalUserUniques = /* GraphQL */ `query GetTotalUserUniques($UserID: Int!) {
  getTotalUserUniques(UserID: $UserID) {
    TotalUniques
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTotalUserUniquesQueryVariables,
  APITypes.GetTotalUserUniquesQuery
>;
export const getTotalUserEmails = /* GraphQL */ `query GetTotalUserEmails($TalentID: Int!) {
  getTotalUserEmails(TalentID: $TalentID) {
    TotalEmails
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTotalUserEmailsQueryVariables,
  APITypes.GetTotalUserEmailsQuery
>;
export const getTalentDashboardSource = /* GraphQL */ `query GetTalentDashboardSource($TalentID: Int!) {
  getTalentDashboardSource(TalentID: $TalentID) {
    TalentID
    Gender
    Ethnicity
    Age
    Region
    Education
    Income
    Children
    TopAudienceInsights
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentDashboardSourceQueryVariables,
  APITypes.GetTalentDashboardSourceQuery
>;
export const getTalentStatSocialData = /* GraphQL */ `query GetTalentStatSocialData($TalentID: Int!, $SqlStmtExtension: String!) {
  getTalentStatSocialData(
    TalentID: $TalentID
    SqlStmtExtension: $SqlStmtExtension
  ) {
    BehaviorName
    AudiencePercentage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentStatSocialDataQueryVariables,
  APITypes.GetTalentStatSocialDataQuery
>;
export const getHasSocialEngagers = /* GraphQL */ `query GetHasSocialEngagers($TalentID: Int!) {
  getHasSocialEngagers(TalentID: $TalentID) {
    HasSocialEngagers
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHasSocialEngagersQueryVariables,
  APITypes.GetHasSocialEngagersQuery
>;
export const getTalentProfileExportDetails = /* GraphQL */ `query GetTalentProfileExportDetails($TalentID: Int!) {
  getTalentProfileExportDetails(TalentID: $TalentID) {
    UserFirstName
    UserLastName
    OrganizationName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentProfileExportDetailsQueryVariables,
  APITypes.GetTalentProfileExportDetailsQuery
>;
export const getCohortExportDownloadStatus = /* GraphQL */ `query GetCohortExportDownloadStatus(
  $UserID: Int!
  $CohortID: Int!
  $TimezoneOffset: Int!
  $Env: String!
) {
  getCohortExportDownloadStatus(
    UserID: $UserID
    CohortID: $CohortID
    TimezoneOffset: $TimezoneOffset
    Env: $Env
  )
}
` as GeneratedQuery<
  APITypes.GetCohortExportDownloadStatusQueryVariables,
  APITypes.GetCohortExportDownloadStatusQuery
>;
export const getInstagramInsightsCredentials = /* GraphQL */ `query GetInstagramInsightsCredentials($TalentID: Int!) {
  getInstagramInsightsCredentials(TalentID: $TalentID) {
    FacebookToken
    InstagramID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInstagramInsightsCredentialsQueryVariables,
  APITypes.GetInstagramInsightsCredentialsQuery
>;
export const getTagsByTalentID = /* GraphQL */ `query GetTagsByTalentID($TalentID: Int!) {
  getTagsByTalentID(TalentID: $TalentID) {
    UserTagID
    UserID
    Tag
    TagTypeID
    Active
    DateAdded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagsByTalentIDQueryVariables,
  APITypes.GetTagsByTalentIDQuery
>;
export const getTagsFromTagBank = /* GraphQL */ `query GetTagsFromTagBank {
  getTagsFromTagBank {
    TagID
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagsFromTagBankQueryVariables,
  APITypes.GetTagsFromTagBankQuery
>;
export const getTalentReportAvailability = /* GraphQL */ `query GetTalentReportAvailability($UserID: Int!) {
  getTalentReportAvailability(UserID: $UserID) {
    ReportAvailability
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentReportAvailabilityQueryVariables,
  APITypes.GetTalentReportAvailabilityQuery
>;
export const getTagsFromSuggestBank = /* GraphQL */ `query GetTagsFromSuggestBank($TalentID: Int, $KeyLinkID: Int) {
  getTagsFromSuggestBank(TalentID: $TalentID, KeyLinkID: $KeyLinkID) {
    SuggestedTagID
    KeyLinkID
    UserID
    Tag
    DateAdded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTagsFromSuggestBankQueryVariables,
  APITypes.GetTagsFromSuggestBankQuery
>;
export const getAllCaptureLinksCount = /* GraphQL */ `query GetAllCaptureLinksCount(
  $SearchTerm: String
  $Filters: String
  $EmailFilter: String
  $TagsFilter: String
  $OrgFilter: Boolean
) {
  getAllCaptureLinksCount(
    SearchTerm: $SearchTerm
    Filters: $Filters
    EmailFilter: $EmailFilter
    TagsFilter: $TagsFilter
    OrgFilter: $OrgFilter
  )
}
` as GeneratedQuery<
  APITypes.GetAllCaptureLinksCountQueryVariables,
  APITypes.GetAllCaptureLinksCountQuery
>;
export const getTotalEmailsByKeyLinkID = /* GraphQL */ `query GetTotalEmailsByKeyLinkID($KeyLinkID: Int!) {
  getTotalEmailsByKeyLinkID(KeyLinkID: $KeyLinkID)
}
` as GeneratedQuery<
  APITypes.GetTotalEmailsByKeyLinkIDQueryVariables,
  APITypes.GetTotalEmailsByKeyLinkIDQuery
>;
export const getKeyLinkTagsByKeyLinkID = /* GraphQL */ `query GetKeyLinkTagsByKeyLinkID($KeyLinkID: Int!) {
  getKeyLinkTagsByKeyLinkID(KeyLinkID: $KeyLinkID) {
    KeyLinkTagID
    KeyLinkID
    Tag
    TagTypeID
    Active
    DateAdded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeyLinkTagsByKeyLinkIDQueryVariables,
  APITypes.GetKeyLinkTagsByKeyLinkIDQuery
>;
export const getSegment = /* GraphQL */ `query GetSegment($SegmentID: Int!) {
  getSegment(SegmentID: $SegmentID) {
    Name
    Description
    SegmentImageURL
    AudienceReach
    Industries
    Tags
    IncludedItems
    SegmentStatus
    DateUpdated
    FiltersPayload
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSegmentQueryVariables,
  APITypes.GetSegmentQuery
>;
export const getSegmentTalents = /* GraphQL */ `query GetSegmentTalents($SegmentID: Int!, $SearchTerm: String, $Page: Int!) {
  getSegmentTalents(
    SegmentID: $SegmentID
    SearchTerm: $SearchTerm
    Page: $Page
  ) {
    UserID
    UserAvatar
    UserName
    TotalEmails
    TotalSocialMediaReach
    InstagramFollowers
    TiktokFollowers
    TwitterFollowers
    YoutubeSubscribers
    FacebookFollowers
    FirstIndustry
    SecondIndustry
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSegmentTalentsQueryVariables,
  APITypes.GetSegmentTalentsQuery
>;
export const getSegmentTalentsCount = /* GraphQL */ `query GetSegmentTalentsCount($SegmentID: Int!, $SearchTerm: String) {
  getSegmentTalentsCount(SegmentID: $SegmentID, SearchTerm: $SearchTerm)
}
` as GeneratedQuery<
  APITypes.GetSegmentTalentsCountQueryVariables,
  APITypes.GetSegmentTalentsCountQuery
>;
export const getSegmentKeylinks = /* GraphQL */ `query GetSegmentKeylinks($SegmentID: Int!, $SearchTerm: String, $Page: Int!) {
  getSegmentKeylinks(
    SegmentID: $SegmentID
    SearchTerm: $SearchTerm
    Page: $Page
  ) {
    KeyLinkID
    KeyLinkName
    KeyLinkImageURL
    UserName
    TotalEmails
    MediaOptIn
    Tags
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSegmentKeylinksQueryVariables,
  APITypes.GetSegmentKeylinksQuery
>;
export const getSegmentKeylinksCount = /* GraphQL */ `query GetSegmentKeylinksCount($SegmentID: Int!, $SearchTerm: String) {
  getSegmentKeylinksCount(SegmentID: $SegmentID, SearchTerm: $SearchTerm)
}
` as GeneratedQuery<
  APITypes.GetSegmentKeylinksCountQueryVariables,
  APITypes.GetSegmentKeylinksCountQuery
>;
export const getRemovedSegmentTalents = /* GraphQL */ `query GetRemovedSegmentTalents($SegmentID: Int!) {
  getRemovedSegmentTalents(SegmentID: $SegmentID) {
    UserID
    UserAvatar
    UserName
    TotalEmails
    TotalSocialMediaReach
    InstagramFollowers
    TiktokFollowers
    TwitterFollowers
    YoutubeSubscribers
    FacebookFollowers
    FirstIndustry
    SecondIndustry
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRemovedSegmentTalentsQueryVariables,
  APITypes.GetRemovedSegmentTalentsQuery
>;
export const getRemovedSegmentKeylinks = /* GraphQL */ `query GetRemovedSegmentKeylinks($SegmentID: Int!) {
  getRemovedSegmentKeylinks(SegmentID: $SegmentID) {
    KeyLinkID
    KeyLinkName
    KeyLinkImageURL
    UserName
    TotalEmails
    MediaOptIn
    Tags
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRemovedSegmentKeylinksQueryVariables,
  APITypes.GetRemovedSegmentKeylinksQuery
>;
export const getRemovedSegmentItemsCount = /* GraphQL */ `query GetRemovedSegmentItemsCount($SegmentID: Int!) {
  getRemovedSegmentItemsCount(SegmentID: $SegmentID)
}
` as GeneratedQuery<
  APITypes.GetRemovedSegmentItemsCountQueryVariables,
  APITypes.GetRemovedSegmentItemsCountQuery
>;
export const getSegmentsData = /* GraphQL */ `query GetSegmentsData($SearchTerm: String) {
  getSegmentsData(SearchTerm: $SearchTerm) {
    SegmentID
    SegmentName
    SegmentImageURL
    Profiles
    CaptureLinks
    AudienceReach
    Description
    Status
    Industries
    DateAdded
    DateUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSegmentsDataQueryVariables,
  APITypes.GetSegmentsDataQuery
>;
export const getAllSegments = /* GraphQL */ `query GetAllSegments($SearchTerm: String) {
  getAllSegments(SearchTerm: $SearchTerm) {
    SegmentID
    SegmentName
    SegmentImageURL
    AudienceReach
    Description
    Status
    Industries
    DateAdded
    DateUpdated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllSegmentsQueryVariables,
  APITypes.GetAllSegmentsQuery
>;
export const getFeaturedSegmentsByCohortID = /* GraphQL */ `query GetFeaturedSegmentsByCohortID($CohortID: Int!) {
  getFeaturedSegmentsByCohortID(CohortID: $CohortID) {
    SegmentID
    SegmentName
    Industry
    Description
    SegmentKeyScore
    SegmentImageURL
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeaturedSegmentsByCohortIDQueryVariables,
  APITypes.GetFeaturedSegmentsByCohortIDQuery
>;
export const getPageSettings = /* GraphQL */ `query GetPageSettings($CohortID: Int!) {
  getPageSettings(CohortID: $CohortID) {
    ShowKeyScores
    ShowTopDomains
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPageSettingsQueryVariables,
  APITypes.GetPageSettingsQuery
>;
export const getTopDomainsByCohortID = /* GraphQL */ `query GetTopDomainsByCohortID($CohortID: Int!) {
  getTopDomainsByCohortID(CohortID: $CohortID) {
    CohortTopDomainTrafficID
    Domain
    AvailableImpressions
    CPM
    ShowTopDomains
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTopDomainsByCohortIDQueryVariables,
  APITypes.GetTopDomainsByCohortIDQuery
>;
export const getSegmentDataForCohort = /* GraphQL */ `query GetSegmentDataForCohort($CohortID: Int!) {
  getSegmentDataForCohort(CohortID: $CohortID) {
    SegmentID
    SegmentName
    ImageUrl
    AudienceReach
    Description
    Industries
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSegmentDataForCohortQueryVariables,
  APITypes.GetSegmentDataForCohortQuery
>;
export const getSuggestedTags = /* GraphQL */ `query GetSuggestedTags {
  getSuggestedTags {
    SuggestedTagID
    Tag
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSuggestedTagsQueryVariables,
  APITypes.GetSuggestedTagsQuery
>;
export const getAllInactiveUserIDs = /* GraphQL */ `query GetAllInactiveUserIDs {
  getAllInactiveUserIDs {
    UserID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllInactiveUserIDsQueryVariables,
  APITypes.GetAllInactiveUserIDsQuery
>;
export const getCaptureLinkFilters = /* GraphQL */ `query GetCaptureLinkFilters {
  getCaptureLinkFilters {
    FilterID
    FilterLabel
    FilterType
    DataSourceColumnName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCaptureLinkFiltersQueryVariables,
  APITypes.GetCaptureLinkFiltersQuery
>;
export const getKeychainTalentDisplayRank = /* GraphQL */ `query GetKeychainTalentDisplayRank($KeychainTalentID: Int!) {
  getKeychainTalentDisplayRank(KeychainTalentID: $KeychainTalentID) {
    KeychainTalentID
    Section
    Rank
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentDisplayRankQueryVariables,
  APITypes.GetKeychainTalentDisplayRankQuery
>;
export const getKeychainTalentID = /* GraphQL */ `query GetKeychainTalentID($TalentID: Int!) {
  getKeychainTalentID(TalentID: $TalentID)
}
` as GeneratedQuery<
  APITypes.GetKeychainTalentIDQueryVariables,
  APITypes.GetKeychainTalentIDQuery
>;
export const getKeychainEmbedLinks = /* GraphQL */ `query GetKeychainEmbedLinks($KeychainTalentID: Int) {
  getKeychainEmbedLinks(KeychainTalentID: $KeychainTalentID) {
    KeychainEmbedsID
    KeychainTalentID
    KeychainEmbedSourceID
    URL
    Label
    StartDate
    EndDate
    isHidden
    DisplayRank
    MediaType
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainEmbedLinksQueryVariables,
  APITypes.GetKeychainEmbedLinksQuery
>;
export const getKeychainEmbedSources = /* GraphQL */ `query GetKeychainEmbedSources {
  getKeychainEmbedSources {
    KeychainEmbedSourceID
    MediaType
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainEmbedSourcesQueryVariables,
  APITypes.GetKeychainEmbedSourcesQuery
>;
export const getAllFonts = /* GraphQL */ `query GetAllFonts {
  getAllFonts {
    FontID
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllFontsQueryVariables,
  APITypes.GetAllFontsQuery
>;
export const getUserAudienceExpirationDate = /* GraphQL */ `query GetUserAudienceExpirationDate($UserID: Int) {
  getUserAudienceExpirationDate(UserID: $UserID) {
    AudienceExpirationDate
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserAudienceExpirationDateQueryVariables,
  APITypes.GetUserAudienceExpirationDateQuery
>;
export const getEmailDrillInForEmails = /* GraphQL */ `query GetEmailDrillInForEmails(
  $OrgFilter: String
  $KeylinkDate: String
  $UFGDate: String
  $Page: Int
) {
  getEmailDrillInForEmails(
    OrgFilter: $OrgFilter
    KeylinkDate: $KeylinkDate
    UFGDate: $UFGDate
    Page: $Page
  ) {
    UserID
    UserFirstName
    UserLastName
    ProfileImage
    OrganizationName
    CombinedEmails
    UploadedEmails
    KeylinkEmails
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmailDrillInForEmailsQueryVariables,
  APITypes.GetEmailDrillInForEmailsQuery
>;
export const getEmailDrillInForEmailsNoDate = /* GraphQL */ `query GetEmailDrillInForEmailsNoDate($UserID: Int) {
  getEmailDrillInForEmailsNoDate(UserID: $UserID) {
    UserID
    TotalEmailCount
    TotalEmailUploads
    TotalKeychainSignups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmailDrillInForEmailsNoDateQueryVariables,
  APITypes.GetEmailDrillInForEmailsNoDateQuery
>;
export const getEmailDrillInForEmailsCount = /* GraphQL */ `query GetEmailDrillInForEmailsCount(
  $OrgFilter: String
  $KeylinkDate: String
  $UFGDate: String
) {
  getEmailDrillInForEmailsCount(
    OrgFilter: $OrgFilter
    KeylinkDate: $KeylinkDate
    UFGDate: $UFGDate
  ) {
    EmailCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmailDrillInForEmailsCountQueryVariables,
  APITypes.GetEmailDrillInForEmailsCountQuery
>;
export const getKeychainEmailDrillInForKeychain = /* GraphQL */ `query GetKeychainEmailDrillInForKeychain(
  $OrgFilter: String
  $KeylinkDate: String
  $UFGDate: String
  $Page: Int
) {
  getKeychainEmailDrillInForKeychain(
    OrgFilter: $OrgFilter
    KeylinkDate: $KeylinkDate
    UFGDate: $UFGDate
    Page: $Page
  ) {
    UserID
    UserFirstName
    UserLastName
    ProfileImage
    OrganizationName
    CombinedEmails
    UploadedEmails
    KeylinkEmails
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainEmailDrillInForKeychainQueryVariables,
  APITypes.GetKeychainEmailDrillInForKeychainQuery
>;
export const getKeychainEmailDrillInForKeychainCount = /* GraphQL */ `query GetKeychainEmailDrillInForKeychainCount(
  $OrgFilter: String
  $KeylinkDate: String
) {
  getKeychainEmailDrillInForKeychainCount(
    OrgFilter: $OrgFilter
    KeylinkDate: $KeylinkDate
  ) {
    EmailCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainEmailDrillInForKeychainCountQueryVariables,
  APITypes.GetKeychainEmailDrillInForKeychainCountQuery
>;
export const getUploadedEmailDrillInForUploaded = /* GraphQL */ `query GetUploadedEmailDrillInForUploaded(
  $OrgFilter: String
  $KeylinkDate: String
  $UFGDate: String
  $Page: Int
) {
  getUploadedEmailDrillInForUploaded(
    OrgFilter: $OrgFilter
    KeylinkDate: $KeylinkDate
    UFGDate: $UFGDate
    Page: $Page
  ) {
    UserID
    UserFirstName
    UserLastName
    ProfileImage
    OrganizationName
    CombinedEmails
    UploadedEmails
    KeylinkEmails
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadedEmailDrillInForUploadedQueryVariables,
  APITypes.GetUploadedEmailDrillInForUploadedQuery
>;
export const getUploadedEmailDrillInForUploadedCount = /* GraphQL */ `query GetUploadedEmailDrillInForUploadedCount(
  $OrgFilter: String
  $UFGDate: String
) {
  getUploadedEmailDrillInForUploadedCount(
    OrgFilter: $OrgFilter
    UFGDate: $UFGDate
  ) {
    EmailCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUploadedEmailDrillInForUploadedCountQueryVariables,
  APITypes.GetUploadedEmailDrillInForUploadedCountQuery
>;
export const getProfilesDrillInData = /* GraphQL */ `query GetProfilesDrillInData(
  $OrgFilter: String
  $KeylinkDate: String
  $ProfileAddedDate: String
  $UFGDate: String
  $Page: Int
) {
  getProfilesDrillInData(
    OrgFilter: $OrgFilter
    KeylinkDate: $KeylinkDate
    ProfileAddedDate: $ProfileAddedDate
    UFGDate: $UFGDate
    Page: $Page
  ) {
    UserID
    UserFirstName
    UserLastName
    ProfileImage
    OrganizationName
    CombinedEmails
    DateProfileCreated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfilesDrillInDataQueryVariables,
  APITypes.GetProfilesDrillInDataQuery
>;
export const getProfilesDrillInDataCount = /* GraphQL */ `query GetProfilesDrillInDataCount(
  $OrgFilter: String
  $ProfileAddedDate: String
) {
  getProfilesDrillInDataCount(
    OrgFilter: $OrgFilter
    ProfileAddedDate: $ProfileAddedDate
  ) {
    ProfilesCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfilesDrillInDataCountQueryVariables,
  APITypes.GetProfilesDrillInDataCountQuery
>;
export const getAllDemoAccounts = /* GraphQL */ `query GetAllDemoAccounts {
  getAllDemoAccounts {
    UserID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllDemoAccountsQueryVariables,
  APITypes.GetAllDemoAccountsQuery
>;
export const getMailchimpKlaviyoAPIKeys = /* GraphQL */ `query GetMailchimpKlaviyoAPIKeys($UserID: Int!) {
  getMailchimpKlaviyoAPIKeys(UserID: $UserID) {
    MailchimpAPIKey
    KlaviyoAPIKey
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMailchimpKlaviyoAPIKeysQueryVariables,
  APITypes.GetMailchimpKlaviyoAPIKeysQuery
>;
export const getMailchimpExportData = /* GraphQL */ `query GetMailchimpExportData($UserID: Int!) {
  getMailchimpExportData(UserID: $UserID) {
    MailchimpExportID
    UserID
    ExportID
    EmailUploadGroupID
    FanGroupName
    DownloadURL
    DateAdded
    DateDownloaded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMailchimpExportDataQueryVariables,
  APITypes.GetMailchimpExportDataQuery
>;
export const getKlaviyoListsForUser = /* GraphQL */ `query GetKlaviyoListsForUser($TalentID: Int!, $Env: String!) {
  getKlaviyoListsForUser(TalentID: $TalentID, Env: $Env)
}
` as GeneratedQuery<
  APITypes.GetKlaviyoListsForUserQueryVariables,
  APITypes.GetKlaviyoListsForUserQuery
>;
export const getAllWebLinksLayout = /* GraphQL */ `query GetAllWebLinksLayout {
  getAllWebLinksLayout {
    WebLinksLayoutID
    Description
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllWebLinksLayoutQueryVariables,
  APITypes.GetAllWebLinksLayoutQuery
>;
export const getCaptureLinkDashboard = /* GraphQL */ `query GetCaptureLinkDashboard($TalentID: Int!) {
  getCaptureLinkDashboard(TalentID: $TalentID) {
    UserID
    CaptureLinkID
    CaptureLinkName
    Date
    Impressions
    UniqueImpressions
    Signups
    UniqueSignups
    Phones
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCaptureLinkDashboardQueryVariables,
  APITypes.GetCaptureLinkDashboardQuery
>;
export const getCaptureLinkDashboardReferrals = /* GraphQL */ `query GetCaptureLinkDashboardReferrals($TalentID: Int!) {
  getCaptureLinkDashboardReferrals(TalentID: $TalentID) {
    UserID
    CaptureLinkID
    Date
    ReferralDomain
    Impressions
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCaptureLinkDashboardReferralsQueryVariables,
  APITypes.GetCaptureLinkDashboardReferralsQuery
>;
export const getCaptureLinkAvgSessionTime = /* GraphQL */ `query GetCaptureLinkAvgSessionTime($TalentID: Int!) {
  getCaptureLinkAvgSessionTime(TalentID: $TalentID) {
    AvgSessionTime
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCaptureLinkAvgSessionTimeQueryVariables,
  APITypes.GetCaptureLinkAvgSessionTimeQuery
>;
export const getKeychainDashboard = /* GraphQL */ `query GetKeychainDashboard($TalentID: Int!) {
  getKeychainDashboard(TalentID: $TalentID) {
    UserID
    Impressions
    UniqueImpressions
    TotalClicks
    TotalUniqueClicks
    KeyLinkClicks
    KeyLinkUniqueClicks
    WebLinkClicks
    WebLinkUniqueClicks
    SocialLinkClicks
    SocialLinkUniqueClicks
    Date
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainDashboardQueryVariables,
  APITypes.GetKeychainDashboardQuery
>;
export const getKeychainAvgSessionTime = /* GraphQL */ `query GetKeychainAvgSessionTime($TalentID: Int!) {
  getKeychainAvgSessionTime(TalentID: $TalentID) {
    AvgSessionTime
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainAvgSessionTimeQueryVariables,
  APITypes.GetKeychainAvgSessionTimeQuery
>;
export const getKeychainTopLinks = /* GraphQL */ `query GetKeychainTopLinks($TalentID: Int!) {
  getKeychainTopLinks(TalentID: $TalentID) {
    OrganizationID
    UserID
    KeychainTalentID
    Date
    LinkID
    LinkType
    LinkName
    Clicks
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTopLinksQueryVariables,
  APITypes.GetKeychainTopLinksQuery
>;
export const getKeychainTopReferrals = /* GraphQL */ `query GetKeychainTopReferrals($TalentID: Int!) {
  getKeychainTopReferrals(TalentID: $TalentID) {
    OrganizationID
    UserID
    KeychainTalentID
    Date
    ReferralDomain
    Impressions
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetKeychainTopReferralsQueryVariables,
  APITypes.GetKeychainTopReferralsQuery
>;
export const getMediaKitsDetails = /* GraphQL */ `query GetMediaKitsDetails($TalentID: Int!) {
  getMediaKitsDetails(TalentID: $TalentID) {
    MediaKitID
    UUID
    TalentID
    MediaKitName
    PrimaryKit
    DateAdded
    ExpirationDate
    ShareableStatus
    Active
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMediaKitsDetailsQueryVariables,
  APITypes.GetMediaKitsDetailsQuery
>;
export const getUserIDFromUserCognitoUID = /* GraphQL */ `query GetUserIDFromUserCognitoUID($UserCognitoUID: String!) {
  getUserIDFromUserCognitoUID(UserCognitoUID: $UserCognitoUID)
}
` as GeneratedQuery<
  APITypes.GetUserIDFromUserCognitoUIDQueryVariables,
  APITypes.GetUserIDFromUserCognitoUIDQuery
>;
export const getPhylloDataSocialMetrics = /* GraphQL */ `query GetPhylloDataSocialMetrics(
  $OrganizationID: Int!
  $PhylloParams: String!
  $Env: String!
) {
  getPhylloDataSocialMetrics(
    OrganizationID: $OrganizationID
    PhylloParams: $PhylloParams
    Env: $Env
  ) {
    EngagementRate
    AverageLikes
    AverageComments
    Age {
      gender
      age_range
      value
      __typename
    }
    Gender {
      gender
      value
      __typename
    }
    Ethnicity {
      name
      value
      __typename
    }
    DMA {
      name
      value
      __typename
    }
    Region {
      code
      value
      __typename
    }
    FollowerCount
    SubscriberCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPhylloDataSocialMetricsQueryVariables,
  APITypes.GetPhylloDataSocialMetricsQuery
>;
export const getPhylloDataRecentPosts = /* GraphQL */ `query GetPhylloDataRecentPosts($PhylloParams: String!, $Env: String!) {
  getPhylloDataRecentPosts(PhylloParams: $PhylloParams, Env: $Env) {
    PlatformName
    LikeCount
    CommentCount
    Description
    Url
    ThumbnailUrl
    DatePublished
    PostID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPhylloDataRecentPostsQueryVariables,
  APITypes.GetPhylloDataRecentPostsQuery
>;
export const getCountryNameFromCode = /* GraphQL */ `query GetCountryNameFromCode($CountryCode: String!) {
  getCountryNameFromCode(CountryCode: $CountryCode)
}
` as GeneratedQuery<
  APITypes.GetCountryNameFromCodeQueryVariables,
  APITypes.GetCountryNameFromCodeQuery
>;
export const getAdditionalOrganizations = /* GraphQL */ `query GetAdditionalOrganizations($UserID: Int!) {
  getAdditionalOrganizations(UserID: $UserID) {
    OrganizationID
    OrganizationName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdditionalOrganizationsQueryVariables,
  APITypes.GetAdditionalOrganizationsQuery
>;
export const getOrganizationRosterStatistics = /* GraphQL */ `query GetOrganizationRosterStatistics($OrganizationID: Int!, $ManagerID: Int) {
  getOrganizationRosterStatistics(
    OrganizationID: $OrganizationID
    ManagerID: $ManagerID
  ) {
    TotalSocialFollowers
    TotalEmails
    TotalProfiles
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationRosterStatisticsQueryVariables,
  APITypes.GetOrganizationRosterStatisticsQuery
>;
export const getOrganizationPrimaryIndustryCounts = /* GraphQL */ `query GetOrganizationPrimaryIndustryCounts(
  $OrganizationID: Int!
  $ManagerID: Int
) {
  getOrganizationPrimaryIndustryCounts(
    OrganizationID: $OrganizationID
    ManagerID: $ManagerID
  ) {
    KeychainTalentPrimaryAnswerID
    Industry
    ProfileCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationPrimaryIndustryCountsQueryVariables,
  APITypes.GetOrganizationPrimaryIndustryCountsQuery
>;
export const getSharedRosterGroups = /* GraphQL */ `query GetSharedRosterGroups($UserID: Int!, $SearchTerm: String) {
  getSharedRosterGroups(UserID: $UserID, SearchTerm: $SearchTerm) {
    TalentRosterGroupID
    TalentRosterGroupName
    UUID
    UserID
    DateAdded
    ExpirationDate
    ShareableStatus
    Active
    TalentCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharedRosterGroupsQueryVariables,
  APITypes.GetSharedRosterGroupsQuery
>;
export const getSharedRoster = /* GraphQL */ `query GetSharedRoster(
  $TalentRosterGroupID: Int!
  $SharedRosterSearchTerm: String
) {
  getSharedRoster(
    TalentRosterGroupID: $TalentRosterGroupID
    SharedRosterSearchTerm: $SharedRosterSearchTerm
  ) {
    TalentID
    Industry
    SubIndustry
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharedRosterQueryVariables,
  APITypes.GetSharedRosterQuery
>;
export const getSharedRosterDetails = /* GraphQL */ `query GetSharedRosterDetails($TalentRosterGroupID: Int!) {
  getSharedRosterDetails(TalentRosterGroupID: $TalentRosterGroupID) {
    TalentRosterGroupID
    TalentRosterGroupName
    UUID
    ExpirationDate
    ShareableStatus
    Active
    RosterViewStats
    UserID
    OrganizationID
    IndustryGroups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharedRosterDetailsQueryVariables,
  APITypes.GetSharedRosterDetailsQuery
>;
export const getMediaKit = /* GraphQL */ `query GetMediaKit($MediaKitID: Int!) {
  getMediaKit(MediaKitID: $MediaKitID) {
    MediaKitID
    UUID
    TalentID
    MediaKitSettings
    SocialHandles
    MediaKitName
    ProfileInformation
    AudienceDemographics
    SocialEngagement
    BrandData
    GeneralText
    RecentPosts
    SponsoredPosts
    PrimaryKit
    DateAdded
    ExpirationDate
    ShareableStatus
    KitViewStats
    Active
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMediaKitQueryVariables,
  APITypes.GetMediaKitQuery
>;
export const getMediaKitUUID = /* GraphQL */ `query GetMediaKitUUID($MediaKitUUID: String!) {
  getMediaKitUUID(MediaKitUUID: $MediaKitUUID) {
    MediaKitID
    UUID
    TalentID
    MediaKitSettings
    SocialHandles
    MediaKitName
    ProfileInformation
    AudienceDemographics
    SocialEngagement
    BrandData
    GeneralText
    RecentPosts
    SponsoredPosts
    PrimaryKit
    DateAdded
    ExpirationDate
    ShareableStatus
    KitViewStats
    Active
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMediaKitUUIDQueryVariables,
  APITypes.GetMediaKitUUIDQuery
>;
export const getTalentMediaKitCount = /* GraphQL */ `query GetTalentMediaKitCount($TalentID: Int!) {
  getTalentMediaKitCount(TalentID: $TalentID)
}
` as GeneratedQuery<
  APITypes.GetTalentMediaKitCountQueryVariables,
  APITypes.GetTalentMediaKitCountQuery
>;
export const getMediaKitShares = /* GraphQL */ `query GetMediaKitShares($MediaKitID: Int!) {
  getMediaKitShares(MediaKitID: $MediaKitID) {
    TalentID
    MediaKitID
    ViewDate
    ViewerEmail
    ViewerName
    TalentRosterGroupID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMediaKitSharesQueryVariables,
  APITypes.GetMediaKitSharesQuery
>;
export const getSharedRosterDetailsUUID = /* GraphQL */ `query GetSharedRosterDetailsUUID($UUID: String!) {
  getSharedRosterDetailsUUID(UUID: $UUID) {
    TalentRosterGroupID
    TalentRosterGroupName
    UUID
    ExpirationDate
    ShareableStatus
    Active
    RosterViewStats
    UserID
    OrganizationID
    IndustryGroups
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharedRosterDetailsUUIDQueryVariables,
  APITypes.GetSharedRosterDetailsUUIDQuery
>;
export const getSharedRosterUUID = /* GraphQL */ `query GetSharedRosterUUID($UUID: String!) {
  getSharedRosterUUID(UUID: $UUID) {
    TalentID
    Industry
    SubIndustry
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharedRosterUUIDQueryVariables,
  APITypes.GetSharedRosterUUIDQuery
>;
export const getSharedRosterTalentInfo = /* GraphQL */ `query GetSharedRosterTalentInfo($TalentID: Int!) {
  getSharedRosterTalentInfo(TalentID: $TalentID) {
    UserFirstName
    UserLastName
    UserAvatar
    MediaKitUUID
    ProfileInformation
    UserBio
    FacebookReach
    InstagramReach
    TwitterReach
    YouTubeReach
    TikTokReach
    TotalSocialReach
    InstagramHandle
    TwitterHandle
    TiktokHandle
    FacebookHandle
    YoutubeHandle
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharedRosterTalentInfoQueryVariables,
  APITypes.GetSharedRosterTalentInfoQuery
>;
export const getTalentRosterGroupShares = /* GraphQL */ `query GetTalentRosterGroupShares($TalentRosterGroupID: Int!) {
  getTalentRosterGroupShares(TalentRosterGroupID: $TalentRosterGroupID) {
    TalentRosterGroupID
    TalentRosterGroupShareID
    DateViewed
    ViewerEmail
    ViewerName
    NumberOfMediaKitsViewed
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentRosterGroupSharesQueryVariables,
  APITypes.GetTalentRosterGroupSharesQuery
>;
export const getProfileViewsTalentData = /* GraphQL */ `query GetProfileViewsTalentData($TalentRosterGroupShareID: Int!) {
  getProfileViewsTalentData(
    TalentRosterGroupShareID: $TalentRosterGroupShareID
  ) {
    TalentRosterGroupShareID
    TalentID
    TalentName
    UserAvatar
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProfileViewsTalentDataQueryVariables,
  APITypes.GetProfileViewsTalentDataQuery
>;
export const getTalentAudienceID = /* GraphQL */ `query GetTalentAudienceID($TalentID: Int!) {
  getTalentAudienceID(TalentID: $TalentID) {
    AudienceID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTalentAudienceIDQueryVariables,
  APITypes.GetTalentAudienceIDQuery
>;
