import gql from 'graphql-tag';
import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation } from 'react-router';

import {
  GetProfilePreviewDataQuery,
  GetProfilePreviewDataQueryVariables,
  ProfilePreviewData,
} from '../../API';
import { getProfilePreviewData } from '../../graphql/queries';
import { useAvatar } from '../../utils/hooks';
import { largeNumberFormatter } from '../../utils/number';
import { ImageDiv } from '../../utils/styles';

import styles from './ProfilePreview.module.scss';
import { WithApolloClient } from '@apollo/client/react/hoc';

interface ProfilePreviewProps {
  talentID: number;
  history: RouteComponentProps['history'];
}

const ProfilePreview: FC<WithApolloClient<ProfilePreviewProps>> = ({
  history,
  talentID,
  client,
}): JSX.Element => {
  const { t } = useTranslation();

  const location = useLocation();

  const [talentData, setTalentData] = useState<ProfilePreviewData>();

  const [avatarUrl, setAvatarKey] = useAvatar(null, {
    reRenderOnEqualKey: true,
  });
  const [pictureChangedCounter, setPictureChangedCounter] = useState<number>(0);

  const ProfilePicture = memo(ImageDiv);

  const loadTalentData = async (firstLoad: boolean) => {
    try {
      const result = await client?.query<
        GetProfilePreviewDataQuery,
        GetProfilePreviewDataQueryVariables
      >({
        query: gql(getProfilePreviewData), 
        fetchPolicy: 'network-only',
        variables: {
          TalentID: talentID,
        },
      });

      const { error, data } = result || {};

      if (error || !data) {
        window.Rollbar.error('From GetProfilePreviewDataQuery', {
          error,
          data,
        });
      }

      const profilePreviewData = data?.getProfilePreviewData;

      profilePreviewData && setTalentData(profilePreviewData);
      !firstLoad && setPictureChangedCounter(pictureChangedCounter + 1);
    } catch (error) {
      window.Rollbar.error('From GetProfilePreviewDataQuery', {
        error,
      });
    }
  };

  useEffect(() => {
    loadTalentData(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    talentData && setAvatarKey(talentData.TalentAvatar || null);
  }, [talentData, pictureChangedCounter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get('profileUpdated') === 'true') {
      loadTalentData(false);

      params.delete('profileUpdated');
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  }, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!talentData) {
    return <></>;
  }

  const { TalentName, EmailCount, SocialReachCount } = talentData;

  return (
    <div className={styles.container}>
      <div 
        className={styles.leftSide}
        onClick={() => {
            history.push(
              `/?talentID=${talentID}`
            )
        } }
      >
        <div className={styles.avatar}>
          <ProfilePicture image={avatarUrl} className={styles.profilePicture} />
        </div>
        <div className={styles.userName}>{TalentName}</div>
      </div>
      <div className={styles.rightSide}>
        <div className={styles.stat}>
          <img
            className={styles.icon}
            src="/assets/images/mail-icon-rounded.svg"
            alt={t('alt-text.mail')}
          />
          <div className={styles.content}>
            <div className={styles.label}>{t('profile-preview.emails')}</div>
            <div className={styles.number}>
              {largeNumberFormatter(EmailCount, { upperCase: true })}
            </div>
          </div>
        </div>
        <div className={styles.stat}>
          <img
            className={styles.icon}
            src="/assets/images/social-metrics-icon-2.svg"
            alt={t('alt-text.social-metrics-icon')}
          />
          <div className={styles.content}>
            <div className={styles.label}>
              {t('profile-preview.social-reach')}
            </div>
            <div className={styles.number}>
              {largeNumberFormatter(SocialReachCount, { upperCase: true })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePreview;
