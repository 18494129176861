// eslint-disable-next-line import/no-named-as-default
import clsx from 'clsx';
import gql from 'graphql-tag';
import React, { useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';

import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetTalentAdminAndTalentsQueryVariables,
  CurrentUser,
  GetOrganizationQueryVariables,
  GetKeychainTalentQuery,
  GetKeychainTalentQueryVariables,
  GetUserAudienceExpirationDateQuery,
  GetUserAudienceExpirationDateQueryVariables,
  GetTalentAdminAndTalentsQuery,
  GetTalentMediaKitCountQuery,
  GetTalentMediaKitCountQueryVariables,
} from '../../API';
import {
  getCurrentUser as getCurrentUserQuery,
  getKeychainTalent,
  getOrganization,
  getTalentAdminAndTalents,
  getTalentMediaKitCount,
  getUserAudienceExpirationDate,
} from '../../graphql/queries';
import { Organization, PlatformUserType } from '../../interfaces/props';
import { useAuthValue } from '../../services/Auth/Auth';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import OrganizationsDropdown from './OrganizationsDropdown';

import AppVersionBanner from './AppVersionBanner';
import styles from './Navbar.module.scss';
import Navigator from './Navigator';
import Notifications from './Notifications';
import ProfilePreview from './ProfilePreview';
import UserSettings from './UserSettings';
import { WithApolloClient } from '@apollo/client/react/hoc';
import { useQuery } from '@apollo/client';

interface NavbarProps extends WithApolloClient<RouteComponentProps> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentUser: CurrentUser | null;
  isSignedIn: boolean;
}

const Navbar: FC<NavbarProps> = ({
  currentUser,
  history,
  isSignedIn,
  client,
}): JSX.Element => {
  const { t } = useTranslation();
  const { user, dbUser, setCurrentUser } = useAuthValue();
  const [isKeyAdmin, setIsKeyAdmin] = useState<boolean>(false);
  const [talentHasOneOrMoreMediaKit, setTalentHasOneOrMoreMediaKit] = useState<boolean>(false);

  useEffect(() => {
    const groups: string[] = [];
    if (user) {
      groups.push(user.tokens?.idToken?.payload['cognito:groups']?.toString() || '');
    }

    setIsKeyAdmin(groups.indexOf('admin') > -1);

  }, [user]);


  const loggedInUser = dbUser
    ? {
      UserID: dbUser.UserID,
      OrganizationID: dbUser.OrganizationID,
      IsTalentAdmin: dbUser.UserIsAdmin,
      IsManager: dbUser.UserIsManager,
      IsKeyAdmin: isKeyAdmin,
      IsTalent: dbUser.UserIsTalent,
      UserName: `${dbUser.UserFirstName} ${dbUser.UserLastName}`,
      IsTalentAdminOrManager: dbUser.UserIsAdmin || dbUser.UserIsManager,
      hasEmailAccess: dbUser.DownloadAccess,
    }
    : null;
  const [organizationName, setOrganizationName] = useState<
    string | undefined
  >();



  // from URL parameter
  const [talentID, setTalentID] = useState<number | undefined>(undefined);

  const [showLogoutConfirm, setShowLogoutConfirm] = useState<boolean>(false);

  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const talentIDParam = Number(params.get('talentID'));
  const [hasKeychain, setHasKeychain] = useState<boolean>(false);
  const [audienceExpirationDate, setAudienceExpirationDate] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUserAudienceExpirationDateField = async (
    id: number,
  ): Promise<void> => {
    try {
      const result = await client?.query<
        GetUserAudienceExpirationDateQuery,
        GetUserAudienceExpirationDateQueryVariables
      >({
        query: gql(getUserAudienceExpirationDate),
        fetchPolicy: 'network-only',
        variables: {
          UserID: id,
        },
      });

      const { data } = result || {};

      if (data) {
        setAudienceExpirationDate(
          data.getUserAudienceExpirationDate?.AudienceExpirationDate,
        );

      }

      setIsLoading(false);
    } catch (error) {
      window.Rollbar.error('Failed to get User First Name and Last Name', {
        error,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (client) {
          const { data, error } = await client.query<
            GetKeychainTalentQuery,
            GetKeychainTalentQueryVariables
          >(
            {
              query: gql(getKeychainTalent),
              fetchPolicy: 'network-only',
              variables: {
                TalentID: talentID,
              },
            });

          if (error) {
            window.Rollbar.error('6GetKeychainTalentQuery', {
              data,
              error,
            });
          }

          if (data?.getKeychainTalent) {
            setHasKeychain(true);
          } else {
            setHasKeychain(false);
          }

          if (talentIDParam) {
            getUserAudienceExpirationDateField(talentIDParam);
          }
        }

      } catch (err) {
        console.error('Current User not Found.', err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentID]);

  useEffect(() => {
    (async () => {
      try {

        const talentIDVariable = loggedInUser?.IsTalent ? loggedInUser?.UserID : talentID || 0;

        if (client) {
          const { data, error } = await client.query<
            GetTalentMediaKitCountQuery,
            GetTalentMediaKitCountQueryVariables
          >(
            {
              query: gql(getTalentMediaKitCount),
              fetchPolicy: 'network-only',
              variables: {
                TalentID: talentIDVariable || 0,
              },
            });

          if (error) {
            window.Rollbar.error('GetMediaKitCountQuery', {
              data,
              error,
            });
          }

          if (data?.getTalentMediaKitCount) {
            setTalentHasOneOrMoreMediaKit(data.getTalentMediaKitCount > 0);
          }

        }

      } catch (err) {
        console.error('Error trying to get MediaKit Count.', err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentID, loggedInUser]);

  useEffect((): void => {
    if (isSignedIn && !currentUser && client) {
      (async () => {
        try {
          const { data } = await client.query<
            GetCurrentUserQuery,
            GetCurrentUserQueryVariables
          >(
            {
              query: gql(getCurrentUserQuery),
              fetchPolicy: 'network-only'
            });

          if (data) {
            const { getCurrentUser } = data;

            if (!dbUser || dbUser !== getCurrentUser) {
              setCurrentUser(getCurrentUser);
            }

            if (!talentIDParam) {
              getUserAudienceExpirationDateField(getCurrentUser.UserID);
            }

          }

        } catch (error) {
          window.Rollbar.error('Failed to load current user from NavBar', {
            error,
          });
        }
      })();
    }
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!talentIDParam) {
      setTalentID(undefined);

      return;
    }

    if (loggedInUser && loggedInUser.IsTalentAdminOrManager) {
      (async () => {
        if (client) {
          const { data } = await client.query<
            GetTalentAdminAndTalentsQuery,
            GetTalentAdminAndTalentsQueryVariables
          >(
            {
              query: gql(getTalentAdminAndTalents),
              fetchPolicy: 'network-only',
              variables: {
                OrganizationID: loggedInUser.OrganizationID,
              },
            });

          if (data) {
            const talentAdminAndTalents = data?.getTalentAdminAndTalents?.map(user => {
              return {
                UserID: user?.UserID || null,
                OrganizationID: user?.OrganizationID || null,
              }
            }) || [];

            const isPartOfOrganization = talentAdminAndTalents.find(
              user =>
                user.UserID === talentIDParam &&
                user.OrganizationID === loggedInUser.OrganizationID,
            );

            if (isPartOfOrganization) {
              setTalentID(talentIDParam);
            }

          }
        }

      })();
    }
  }, [loggedInUser, talentIDParam]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    if (loggedInUser && loggedInUser.OrganizationID) {
      (async () => {
        try {
          if (client) {
            const { data, error } = await client.query<
              { getOrganization: Organization },
              GetOrganizationQueryVariables
            >({
              fetchPolicy: 'network-only',
              query: gql(getOrganization),
              variables: {
                OrganizationID: loggedInUser.OrganizationID as number,
              },
            });

            if (error || !data) {
              window.Rollbar.error('From GetOrganizationQuery', {
                error,
                data,
              });
            }

            if (data) {
              const { OrganizationName } = data.getOrganization;
              setOrganizationName(OrganizationName);

            }

          }

        } catch (error) {
          window.Rollbar.error('From GetOrganizationQuery', {
            error,
          });
        }
      })();
    }
  }, [loggedInUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const shouldHideNavbar =
    location.pathname.includes('keychain/profile') ||
    location.pathname.includes('login') ||
    location.pathname.includes('capturelink/') ||
    location.pathname.includes('media-kit-view/') ||
    location.pathname.includes('talent-roster/');

  const shouldShowNotificationsButton =
    !!loggedInUser &&
    (loggedInUser.IsTalentAdminOrManager || loggedInUser?.IsTalent);

  const shouldShowUserName =
    loggedInUser &&
    (loggedInUser.IsKeyAdmin || loggedInUser.IsTalentAdminOrManager);

  const shouldShowUserSettingsButton =
    loggedInUser &&
    (loggedInUser.IsKeyAdmin || loggedInUser.IsTalentAdminOrManager);

  const shouldShowLogoutButton = loggedInUser && loggedInUser.IsTalent;

  const shouldShowProfilePreview = loggedInUser && !!talentID;

  const shouldShowNavigator =
    (loggedInUser && loggedInUser.IsTalent) || !!talentID;

  if (shouldHideNavbar) {
    return <></>;
  }

  if (!loggedInUser) {
    return (
      <div className={styles.container}>
        <div className={styles.appBar}>
          <div className={styles.leftSide}>
            <img
              alt={t('alt-text.key-logo')}
              src="/assets/images/logo_white.svg"
              className={styles.logo}
              onClick={() => history.push('/')}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <AppVersionBanner />
      <div className={styles.content}>
        <div className={styles.appBar}>
          <div className={styles.leftSide}>
            <img
              alt={t('alt-text.key-logo')}
              src="/assets/images/logo_white.svg"
              className={styles.logo}
              onClick={() => history.push('/')}
            />
            <OrganizationsDropdown loggedInUserID={loggedInUser.UserID} organizationName={organizationName} />
          </div>
          <div className={styles.rightSide}>
            {shouldShowNotificationsButton && (
              <Notifications
                history={history}
                userID={loggedInUser.UserID}
                client={client}
              />
            )}
            {shouldShowUserName && (
              <div className={styles.navbarText}>
                {loggedInUser ? `${loggedInUser.UserName}` : ''}
              </div>
            )}
            {shouldShowUserSettingsButton && (
              <UserSettings
                history={history}
                userType={
                  // loggedInUser.IsKeyAdmin // OLD LEGACY CODE
                  //   ? PlatformUserType.KEY_ADMIN
                  //   : 
                  loggedInUser.IsTalentAdmin
                    ? PlatformUserType.ORG_ADMIN
                    : loggedInUser.IsManager
                      ? PlatformUserType.MANAGER
                      : loggedInUser.IsTalent
                        ? PlatformUserType.TALENT
                        : null
                }
                setShowLogoutConfirmationDialog={() =>
                  setShowLogoutConfirm(true)
                }
              />
            )}
            {shouldShowLogoutButton && (
              <button
                className={styles.logoutButton}
                onClick={() => setShowLogoutConfirm(true)}
              >
                <img
                  src="/assets/images/exit_to_app_light.svg"
                  alt={t('alt-text.logout')}
                />
                <span>{t('navigation.logout')}</span>
              </button>
            )}
          </div>
        </div>
        {shouldShowProfilePreview && (
          <ProfilePreview
            talentID={Number(talentID)}
            history={history}
            client={client}
          />
        )}
        {isLoading ? (
          <></>
        ) : (
          shouldShowNavigator &&
          !audienceExpirationDate && (
            <Navigator
              talentID={Number(talentID || loggedInUser?.UserID)}
              loggedInUserIsTalent={loggedInUser?.IsTalent}
              history={history}
              hasKeychain={hasKeychain}
              hasEmailAccess={loggedInUser.hasEmailAccess || false}
              talentHasOneOrMoreMediaKit={talentHasOneOrMoreMediaKit}
            />
          )
        )}
      </div>
      <ConfirmationDialog
        confirmText={t('confirmation-dialog.ok')}
        data={{
          title: t('navigation.logout'),
          content: t('navigation.logout-confirm'),
        }}
        isOpen={showLogoutConfirm}
        onClose={(): void => setShowLogoutConfirm(false)}
        onConfirm={(): void => {
          setShowLogoutConfirm(false);
          history.push('/logout');
        }}
      />
    </div>
  );
};

export default withRouter(Navbar);
