import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { IAuthContext } from '../interfaces/context';
import { AuthContext } from '../services/Auth/Auth';
import Loading from '../ui/Loading/Loading';
import QueryError from '../ui/QueryError/QueryError';
import {
  AFTER_LOGIN_PARAMS_KEY,
  AFTER_LOGIN_REDIRECT_KEY,
} from '../utils/constants';
import { GetCurrentUserQuery, GetCurrentUserQueryVariables } from 'API';
import { getCurrentUser } from 'graphql/queries';
import { gql, useQuery } from '@apollo/client';

const TalentAdminRoute = ({ component: Component, ...rest }): JSX.Element => {
  const context = useContext<IAuthContext>(AuthContext);
  const from = rest.path;
  const search = rest.location.search;

  if (!context.isSignedIn) {
    if (rest.dynamicURLKeyTerm) {
      const index = window.location.href.search(rest.dynamicURLKeyTerm);
      const dynamicFromPath = `/${window.location.href.substring(index)}`;

      sessionStorage.setItem(AFTER_LOGIN_REDIRECT_KEY, dynamicFromPath);
    } else {
      sessionStorage.setItem(AFTER_LOGIN_REDIRECT_KEY, from);
      sessionStorage.setItem(AFTER_LOGIN_PARAMS_KEY, search);
    }

    return <Redirect to="/login" path={from} />;
  }

  const talentID = Number(
    new URLSearchParams(window.location.search).get('talentID'),
  );

  try {
    const { data, error, loading } = useQuery<
      GetCurrentUserQuery,
      GetCurrentUserQueryVariables
    >(gql(getCurrentUser), {
      fetchPolicy: 'network-only',
    });

    if (loading) {
      return <Loading />;
    }

    if (error || !data) {
      if (error || !data || !data.getCurrentUser) {
        return (
          <>
            <QueryError
              error={error}
              parentName="TalentAdminOnlyRoute"
              privateMessage="From GetCurrentUserQuery"
            />
            <Redirect to="/" />
          </>
        );
      }
    }

    if (data?.getCurrentUser) {
      const {
        UserIsAdmin,
        OrganizationID,
      } = data.getCurrentUser;
      const isAdmin = UserIsAdmin;
      const adminOrganizationID = OrganizationID;
      if (!talentID) {
        return (
          <Route
            {...rest}
            render={(props): JSX.Element => <Component {...props} />}
          />
        );
      }

      try {
        const { data, error, loading } = useQuery<
          GetCurrentUserQuery,
          GetCurrentUserQueryVariables
        >(gql(getCurrentUser), {
          fetchPolicy: 'network-only',
          variables: { UserID: talentID },
        });
    
        if (loading) {
          return <Loading />;
        }
    
        if (error || !data) {
          if (error || !data || !data.getCurrentUser) {
            return (
              <>
                <QueryError
                  error={error}
                  parentName="TalentAdminOnlyRoute"
                  privateMessage="From GetCurrentUserQuery"
                />
                <Redirect to="/" />
              </>
            );
          }
        }

        const { OrganizationID} = data.getCurrentUser;
        const isAuthorized =
        OrganizationID === adminOrganizationID;

        return (
          <Route
            {...rest}
            render={(props): JSX.Element =>
              isAuthorized ? (
                <Component {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        );
      } catch (err) {
        return (
          <>
            <QueryError
              error={undefined}
              parentName="TalentAdminOnlyRoute"
              privateMessage="From GetCurrentUserQuery"
            />
            <Redirect to="/" />
          </>
        );
      }
    }
  } catch (err) {
    return (
      <>
        <QueryError
          error={undefined}
          parentName="TalentAdminOnlyRoute"
          privateMessage="From GetCurrentUserQuery"
        />
        <Redirect to="/" />
      </>
    );
  }

  return (
    <Route
      {...rest}
      render={(props): JSX.Element => <Component {...props} />}
    />
  );
};

export default TalentAdminRoute;
