import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { IAuthContext } from '../interfaces/context';
import { AuthContext } from '../services/Auth/Auth';
import Loading from '../ui/Loading/Loading';
import QueryError from '../ui/QueryError/QueryError';
import { AFTER_LOGIN_REDIRECT_KEY } from '../utils/constants';
import { gql, useQuery } from '@apollo/client';
import { GetCurrentUserQuery } from 'API';
import { getCurrentUser } from 'graphql/queries';

const TalentOnlyRoute = ({ component: Component, ...rest }): JSX.Element => {
  const context = useContext<IAuthContext>(AuthContext);
  const from = rest.path;

  const { data, error, loading } = useQuery<GetCurrentUserQuery>(
    gql(getCurrentUser), {
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.getCurrentUser) {
    return (
      <>
        <QueryError
          error={error}
          parentName="TalentOnlyRoute"
          privateMessage="From GetCurrentUserQuery"
        />
        <Redirect to="/" />
      </>
    );
  }

  if (!context.isSignedIn) {
    sessionStorage.setItem(AFTER_LOGIN_REDIRECT_KEY, from);

    return <Redirect to="/login" path={from} />;
  }

  const isTalent = data.getCurrentUser?.UserIsTalent ?? false;

  return (
    <Route
      {...rest}
      render={(props): JSX.Element =>
        isTalent ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default TalentOnlyRoute;