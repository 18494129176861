import AWS from 'aws-sdk';
import { PutObjectRequest, TagSet } from 'aws-sdk/clients/s3';

import AWSExports from '../../src/aws-exports';
import { getCurrentUser, AuthUser, fetchAuthSession } from 'aws-amplify/auth';
import { AwsCredentials } from 'aws-sdk/clients/gamelift';

class S3Storage {
  private _bucket;
  private _credentialsRefreshTimeout;
  // The token expires every 60 minutes so we'll renew every 50 minutes
  private readonly REFRESH_INTERVAL: number = 50 * 60 * 1000;
  private readonly MIN_REFRESH_INTERVAL: number = 30 * 1000;
  private readonly COGNITO_IDP_URL: string;
  private readonly API_VERSION: string = '2006-03-01';
  private readonly SIGNATURE_VERSION: string = 'v4';

  public constructor ({ region, userPoolId }) {
    this.COGNITO_IDP_URL = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
  }

  private _cancelCredentialRefresh = (): void => {
    if (this._credentialsRefreshTimeout) {
      clearTimeout(this._credentialsRefreshTimeout);
      this._credentialsRefreshTimeout = null;
    }
  };

  private _scheduleCredentialsRefresh = (
    interval = this.REFRESH_INTERVAL,
  ): void => {
    this._credentialsRefreshTimeout = setTimeout(async (): Promise<void> => {
      try {
        const { tokens } = await fetchAuthSession({ forceRefresh: true,  });
        if (tokens) {
          console.log('tokens exist', tokens);
          // TODO - removed refresh interval, is this okay?
        } else {
          console.log('Unable to get refresh token from getSignInUserSession.');
        }
      } catch (err) {
        console.log('Unable to refresh Token', err);
      }
    }, interval);
  };

  private _createS3 = ({ bucket, region, ...restParams }): AWS.S3 => {
    return new AWS.S3({
      apiVersion: this.API_VERSION,
      signatureVersion: this.SIGNATURE_VERSION,
      params: { Bucket: bucket },
      httpOptions: {
        timeout: 0,
      },
      region,
      ...restParams,
    });
  };

  private _initCredentials = async (): Promise<AwsCredentials | null> => {
    try {
      const credentials = (await fetchAuthSession()).credentials;

      AWS.config.credentials = credentials;

      return credentials as AwsCredentials;
    } catch (e) {
      return null;
    }
  };

  private _getKeyPrefix = (credentials, level = 'public'): string => {
    if (!credentials && level !== 'public') {
      throw new Error('Missing credentials');
    }

    let prefix;
    const identityId = credentials.identityId;

    switch (level) {
      case 'public':
        prefix = 'public';
        break;
      case 'protected':
        prefix = `protected/${identityId}`;
        break;
      case 'private':
        prefix = `private/${identityId}`;
        break;
    }

    return prefix;
  };

  private _putParams = ({
    credentials,
    level = 'public',
    key,
    object,
    bucket,
    tagging,
  }): PutObjectRequest => {
    const prefix = this._getKeyPrefix(credentials, level);

    return {
      Bucket: bucket,
      Key: `${prefix}/${key}`,
      Body: object,
      ContentType: 'binary/octet-stream',
      Tagging: tagging,
    };
  };

  public put = (
    key,
    object,
    { progressCallback, level, bucket, region, tagging, ...restParams },
  ): Promise<Function> =>
    new Promise(
      // eslint-disable-next-line no-async-promise-executor
      async (resolve, reject): Promise<void> => {
        try {
          const credentials = await this._initCredentials();

          const s3 = this._createS3({ bucket, region, ...restParams });
          const params = this._putParams({
            credentials,
            level,
            key,
            object,
            bucket,
            tagging,
          });

          if (credentials) {
            this._scheduleCredentialsRefresh();
          }

          s3.upload(params)
            .on('httpUploadProgress', progressCallback)
            .promise()
            .then((data): void => {
              this._cancelCredentialRefresh();
              resolve(data);
            })
            .catch((error): void => {
              this._cancelCredentialRefresh();
              reject(error);
            });
        } catch (error) {
          this._cancelCredentialRefresh();
          reject(error);
        }
      },
    );

  public createS3ServiceObject = async (bucket, region): Promise<AWS.S3> => {
    const credentials = await this._initCredentials();

    if (credentials) {
      this._scheduleCredentialsRefresh();
    }

    const S3ServiceObject = this._createS3({ bucket, region });

    this._bucket = bucket;

    return S3ServiceObject;
  };

  public getObjectTagging = async (
    key,
    S3ServiceObject: AWS.S3,
  ): Promise<TagSet> =>
    await new Promise((resolve, reject) => {
      S3ServiceObject.getObjectTagging(
        { Key: key, Bucket: this._bucket },
        (err, res) => {
          if (res) resolve(res.TagSet);
          else reject(err);
        },
      );
    });
}

export default new S3Storage({
  region: AWSExports.aws_project_region,
  userPoolId: AWSExports.aws_user_pools_id,
});
