import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Error404.module.scss';

const Error404: FC<{}> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.logo}>
      <div className={styles.errorWrapper}>
        <div className={styles.error404Head}>{t('errors.error404-head')}</div>{' '}
        <div className={styles.error404Body}>{t('errors.error404-body')}</div>
      </div>
    </div>
  );
};

export default Error404;
