import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { CreateTalentProps } from '../interfaces/props';
import Error404 from '../pages/Error404/Error404';
import Loading from '../ui/Loading/Loading';
import QueryError from '../ui/QueryError/QueryError';
import { gql, useQuery } from '@apollo/client';
import { GetTalentAdminQuery } from 'API';
import { getTalentAdmin } from 'graphql/queries';

const ValidateTalentAdminRoute = ({
  component: Component,
  ...rest
}): JSX.Element => {
  try {
    const { data, error, loading } = useQuery<
      GetTalentAdminQuery
      >(gql(getTalentAdmin), {
      fetchPolicy: 'network-only',
    });

    if (loading) {
      return <Loading />;
    }

    if (error || !data) {
      return (
        <>
          <QueryError
            error={error}
            parentName="TalentAdminOnlyRoute"
            privateMessage="From GetCurrentUserQuery"
          />
          <Redirect to="/" />
        </>
      );
    }

    if (data?.getTalentAdmin) {

      const createTalentProps: CreateTalentProps = {
        OrganizationID: data.getTalentAdmin.OrganizationID,
        OrganizationName: data.getTalentAdmin.OrganizationName,
      };
    
      return (
        <Route
          {...rest}
          render={(props): JSX.Element =>
            data.getTalentAdmin?.UserIsAdmin ||
            data.getTalentAdmin?.UserIsManager ? (
              <Component {...props} {...createTalentProps} />
            ) : (
              <Error404 />
            )
          }
        />
      );
    }

  } catch (err) {
    return (
      <>
        <QueryError
          error={undefined}
          parentName="TalentAdminOnlyRoute"
          privateMessage="From GetCurrentUserQuery"
        />
        <Redirect to="/" />
      </>
    );
  }

  // React.useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data, error, loading } = await useQuery<
  //         GetTalentAdminQuery
  //         >(gql(getTalentAdmin), {
  //         fetchPolicy: 'network-only',
  //       });

  //       if (loading) {
  //         setOrganizationLoading(true);
  //       }

  //       if (error || !data) {
  //         setOrganizationError(error);
  //       }

  //       if (data?.getTalentAdmin) {
  //         setOrganization(data.getTalentAdmin);
  //       }
  //     } catch (err) {
  //       setOrganizationError(err);
  //     }
  //   })();
  // },[])

  // if (organizationLoading) {
  //   return <Loading />;
  // }

  // if (organizationError) {
  //   return (
  //     <>
  //       <QueryError
  //         error={organizationError}
  //         parentName="ValidateTalentAdminRoute"
  //         privateMessage="From GetTalentAdminQuery"
  //       />
  //       <Redirect to="/" />
  //     </>
  //   );
  // }

  // const createTalentProps: CreateTalentProps = {
  //   OrganizationID: organization && organization.OrganizationID,
  //   OrganizationName: organization && organization.OrganizationName,
  // };

  // return (
  //   <Route
  //     {...rest}
  //     render={(props): JSX.Element =>
  //       organization.UserIsAdmin ||
  //       organization.UserIsManager ? (
  //         <Component {...props} {...createTalentProps} />
  //       ) : (
  //         <Error404 />
  //       )
  //     }
  //   />
  // );
  return <div />;
};

export default ValidateTalentAdminRoute;
