import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetKeyLinkInfoQueryVariables,
  GetKeyLinkInfoQuery,
  GetUploadedFanGroupQuery,
  GetUploadedFanGroupQueryVariables,
  GetUserFirstLastNameQuery,
  GetUserFirstLastNameQueryVariables,
} from '../../API';
import {
  getCurrentUser,
  getKeyLinkInfo,
  getUploadedFanGroup,
  getUserFirstLastName,
} from '../../graphql/queries';
import {
  BehaviorReport,
  BehaviorExportProps,
  FanGroupExportProps,
  FanGroupReport,
  SocialAudienceOverlapExportProps,
  SocialAudienceOverlapReport,
} from '../../interfaces/props';
import BasicPagination from '../../ui/BasicPagination/BasicPagination';
import GradientHeader from '../../ui/GradientHeader/GradientHeader';
import {
  getFanGroupReports,
  getBehaviorReports,
  getSocialAudienceOverlapReports,
} from '../../utils/storage';
import Loading from '../Loading/Loading';

import styles from './FanExportDashboard.module.scss';
import FanExportDashboardTable from './FanExportDashboardTable';
import { gql } from '@apollo/client';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';

const ROWS_PER_PAGE = 25;

const FanExportDashboard: FC<WithApolloClient<RouteComponentProps>> = ({
  client,
}): JSX.Element => {
  const { t } = useTranslation();
  const [exports, setExports] = useState<FanGroupExportProps[]>([]);
  const [fullExports, setFullExports] = useState<FanGroupExportProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [exportsCount, setExportsCount] = useState<number>(0);

  const [pageCount, setPageCount] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    setPageCount(Math.max(Math.ceil(exportsCount / ROWS_PER_PAGE), 1));
    setPageNumber(1);
  }, [exportsCount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pageNumber > 1) {
      setExports(
        [...fullExports].slice(
          ROWS_PER_PAGE * (pageNumber - 1),
          ROWS_PER_PAGE * pageNumber,
        ),
      );
    } else {
      setExports([...fullExports].slice(0, ROWS_PER_PAGE * pageNumber));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullExports, pageNumber]);

  useEffect((): void => {
    document.title = 'Export Dashboard | Key';

    (async (): Promise<void> => {
      let behaviorExport = [
        {
          title: '',
          fileName: '',
          lastModified: '',
          url: '',
        },
      ];
      let fanGroupExport = [
        {
          title: '',
          fileName: '',
          lastModified: '',
          url: '',
        },
      ];
      let socialEngagersExport = [
        {
          title: '',
          fileName: '',
          lastModified: '',
          url: '',
        },
      ];

      try {
        const behaviorReports = await getBehaviorReports();

        const getBehaviorReport = async (
          report: BehaviorReport,
        ): Promise<BehaviorExportProps> => {
          const behaviorExport: BehaviorExportProps = {
            title: report.title ?? report.fileName,
            lastModified: report.lastModified,
            fileName: report.fileName,
            url: report.url,
          };

          if (report.talentID) {
            try {
              const result = await client?.query<
                GetUserFirstLastNameQuery,
                GetUserFirstLastNameQueryVariables
              >({
                query: gql(getUserFirstLastName),
                fetchPolicy: 'network-only',
                variables: {
                  UserID: report.talentID,
                },
              });

              const { data } = result || {};

              const LastName = data?.getUserFirstLastName?.UserLastName || null;

              return {
                ...behaviorExport,
                title:
                  `${data?.getUserFirstLastName.UserFirstName} ${LastName &&
                    `${LastName} `}Behavior Export` ?? report.fileName,
              };
            } catch (error) {
              window.Rollbar.error('Failed to get Talent First and Last Name', {
                error,
              });
            }
          } else if (report.title) {
            return {
              ...behaviorExport,
              title: report.title,
            };
          }

          return behaviorExport;
        };

        const behaviorExports = await Promise.all(
          behaviorReports.map(async report => getBehaviorReport(report)),
        );

        behaviorExport = behaviorExports;
      } catch (error) {
        window.Rollbar.error('Failed to get Behavior Reports', {
          error,
        });
      }

      try {
        const fanGroupReports = await getFanGroupReports();

        const getFanGroupExport = async (
          report: FanGroupReport,
        ): Promise<FanGroupExportProps> => {
          const fanGroupExport: FanGroupExportProps = {
            title: report.fileName,
            lastModified: report.lastModified,
            fileName: report.fileName,
            url: report.url,
          };

          if (report.title) {
            return {
              ...fanGroupExport,
              title: report.title,
            };
          }

          if (report.keyLinkID) {
            try {
              const result = await client?.query<
                GetKeyLinkInfoQuery,
                GetKeyLinkInfoQueryVariables
              >({
                query: gql(getKeyLinkInfo),
                fetchPolicy: 'network-only',
                variables: {
                  KeyLinkID: report.keyLinkID,
                },
              });

              const { data } = result || {};

              return {
                ...fanGroupExport,
                title:
                  `${data?.getKeyLinkInfo?.KeyLinkName} Fan Group Export` ??
                  report.fileName,
              };
            } catch (error) {
              window.Rollbar.error('Failed to get keyLinkName', {
                error,
              });
            }
          }

          if (report.uploadedListID) {
            try {
              const result = await client?.query<
                GetUploadedFanGroupQuery,
                GetUploadedFanGroupQueryVariables
              >({
                query: gql(getUploadedFanGroup),
                fetchPolicy: 'network-only',
                variables: {
                  UploadGroupID: report.uploadedListID,
                },
              });

              const { data } = result || {};

              return {
                ...fanGroupExport,
                title:
                  `${data?.getUploadedFanGroup?.FanGroupName} Fan Group Export` ??
                  report.fileName,
              };
            } catch (error) {
              window.Rollbar.error('Failed to get uploadedFanGroupName', {
                error,
              });
            }
          }

          return fanGroupExport;
        };

        const fanGroupExports = await Promise.all(
          fanGroupReports.map(async report => getFanGroupExport(report)),
        );

        fanGroupExport = fanGroupExports;
      } catch (error) {
        window.Rollbar.error('Failed to get Fan Group Reports', {
          error,
        });
      }

      try {
        const socialAudienceOverlapReports = await getSocialAudienceOverlapReports();

        const getSocialEngagersReport = async (
          report: SocialAudienceOverlapReport,
        ): Promise<SocialAudienceOverlapExportProps> => {
          const socialEngagersExport: SocialAudienceOverlapExportProps = {
            title: report.title ?? report.fileName,
            lastModified: report.lastModified,
            fileName: report.fileName,
            url: report.url,
          };

          const fileNameWithSpaces = report.title?.replace('_', ' ');

          if (report.requestorID) {
            let requestorName = '';

            try {
              const result = await client?.query<
                GetCurrentUserQuery,
                GetCurrentUserQueryVariables
              >({
                query: gql(getCurrentUser),
                fetchPolicy: 'network-only',
                variables: {
                  UserID: report.requestorID,
                },
              });

              const { data } = result || {};

              requestorName = `${data?.getCurrentUser.UserFirstName} ${data?.getCurrentUser.UserLastName}`;

              return {
                ...socialEngagersExport,
                title:
                  `${fileNameWithSpaces} Social Engagers Export` ??
                  report.fileName,
                talentName: requestorName,
              };
            } catch (error) {
              window.Rollbar.error(
                'Failed to get User First Name and Last Name',
                {
                  error,
                },
              );
            }
          } else if (fileNameWithSpaces) {
            return {
              ...socialEngagersExport,
              title:
                `${fileNameWithSpaces} Social Engagers Export` ??
                report.fileName,
            };
          }

          return socialEngagersExport;
        };

        const socialEngagersExports = await Promise.all(
          socialAudienceOverlapReports.map(async report =>
            getSocialEngagersReport(report),
          ),
        );

        socialEngagersExport = socialEngagersExports;
      } catch (error) {
        window.Rollbar.error('Failed to get Social Engagers Reports', {
          error,
        });
      }

      setExportsCount(
        [...behaviorExport, ...fanGroupExport, ...socialEngagersExport].length,
      );

      setFullExports(
        [...behaviorExport, ...fanGroupExport, ...socialEngagersExport].sort(
          (reportA, reportB) =>
            new Date(reportB.lastModified).getTime() -
            new Date(reportA.lastModified).getTime(),
        ),
      );

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GradientHeader title="Export Dashboard" />
      <div className={styles.container}>
        {loading ? (
          <Loading />
        ) : exports.length === 0 ? (
          <div className={styles.emptyDashboard}>
            <img
              src="/assets/images/empty-dashboard.svg"
              alt={t('alt-text.empty-dashboard')}
            />
            <div>{t('fan-manager.fangroups.export-dashboard-empty')}</div>
          </div>
        ) : (
          <>
            <FanExportDashboardTable exports={exports} />
            {pageCount > 1 && (
              <BasicPagination
                pageCount={pageCount}
                page={pageNumber}
                onPageChange={(_, page) => {
                  setPageNumber(page);
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default withApollo(withRouter(FanExportDashboard));
