import { Menu, MenuItem, Button } from '@mui/material';
import clsx from 'clsx';
import { isToday, isYesterday, format } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import {
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  GetUnseenNotificationsCountQuery,
  NotificationRow as TNotificationRow,
  GetUnseenNotificationsCountQueryVariables,
  MarkNotificationsAsSeenMutation,
  MarkNotificationsAsSeenMutationVariables,
} from '../../API';
import { markNotificationsAsSeen } from '../../graphql/mutations';
import {
  getNotifications,
  getUnseenNotificationsCount,
} from '../../graphql/queries';
import { getDateFromSQLDateTime, getTimeStampOnly } from '../../utils/date';
import { WithApolloClient } from '@apollo/client/react/hoc';
import styles from './Notifications.module.scss';
import { gql } from '@apollo/client';

interface NotificationsProps {
  history: RouteComponentProps['history'];
  userID: number;
  lightMode?: boolean;
}

const Notifications: FC<WithApolloClient<NotificationsProps>> = ({
  history,
  userID,
  lightMode,
  client,
}): JSX.Element => {
  const { t } = useTranslation();

  const location = useLocation();

  const [notificationEl, setNotificationEl] = useState<HTMLElement | null>(
    null,
  );
  const [notifications, setNotifications] = useState<TNotificationRow[]>([]);
  const [unseenNotificationsCount, setUnseenNotificationsCount] = useState<
    number
  >(0);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState<boolean>(
    false,
  );

  const getUserNotifications = async (): Promise<void> => {
    try {
      const result = await client?.query<
        GetNotificationsQuery,
        GetNotificationsQueryVariables
      >({
        fetchPolicy: 'network-only',
        query: gql(getNotifications),
        variables: {
          UserID: userID,
          Limit: 5,
        },
      });

      if (result?.error) {
        window.Rollbar.error('GetNotificationsQuery', result);
      }

      const notifications = result?.data?.getNotifications ? result.data.getNotifications : [];

      setNotifications(notifications);
      setIsLoadingNotifications(false);
    } catch (error) {
      window.Rollbar.error('GetNotificationsQuery', { error });
    }
  };

  useEffect(() => {
    getUserNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, location, isLoadingNotifications]);

  const getUserUnseenNotificationsCount = async (): Promise<void> => {
    try {
      const result = await client?.query<
        GetUnseenNotificationsCountQuery,
        GetUnseenNotificationsCountQueryVariables
      >({
        query: gql(getUnseenNotificationsCount),
        fetchPolicy: 'network-only',
        variables: {
          UserID: userID,
        },
      });

      const { data, error } = result || {};

      if (error) {
        window.Rollbar.error('GetUnseenNotificationsCountQuery', {
          data,
          error,
        });
      }

      const notificationsCount = data?.getUnseenNotificationsCount
        ? data.getUnseenNotificationsCount
        : 0;

      setUnseenNotificationsCount(notificationsCount);
      setIsLoadingNotifications(false);
    } catch (error) {
      window.Rollbar.error('GetUnseenNotificationsCountQuery', { error });
    }
  };

  useEffect(() => {
    getUserUnseenNotificationsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, location, isLoadingNotifications]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.get('markAsSeen') === 'true') {
      getUserUnseenNotificationsCount();

      params.delete('markAsSeen');
      history.replace({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const markAllNotificationsAsSeen = async (): Promise<void> => {
    try {
      await client?.mutate<
        MarkNotificationsAsSeenMutation,
        MarkNotificationsAsSeenMutationVariables
      >({
        mutation: gql(markNotificationsAsSeen),
        variables: {
          UserID: userID,
        },
      });
      setIsLoadingNotifications(true);
    } catch (error) {
      window.Rollbar.error('Failed to mark notifications as seen', {
        error,
      });
    }

    getUserNotifications();
    getUserUnseenNotificationsCount();
  };

  const markRecentNotificationsAsSeen = async (): Promise<void> => {
    try {
      await client?.mutate<
        MarkNotificationsAsSeenMutation,
        MarkNotificationsAsSeenMutationVariables
      >({
        mutation: gql(markNotificationsAsSeen),
        variables: {
          UserID: userID,
          Limit: 5,
        },
      });
      setIsLoadingNotifications(true);
    } catch (error) {
      window.Rollbar.error('Failed to mark notifications as seen', {
        error,
      });
    }

    getUserNotifications();
    getUserUnseenNotificationsCount();
  };

  const handleMarkRecentNotificationsAsSeen = () => {
    setTimeout(() => {
      markRecentNotificationsAsSeen();
    }, 1000);
  };

  const handleNotificationClick = event => {
    setNotificationEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationEl(null);
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.notificationButton}
        onClick={event => {
          handleNotificationClick(event);
          handleMarkRecentNotificationsAsSeen();
        }}
      >
        <div className={styles.buttonImage}>
          <img
            src={
              lightMode
                ? '/assets/images/notification_white.svg'
                : '/assets/images/notification.svg'
            }
            height="30px"
            width="30px"
            alt={t('alt-text.notification-icon')}
          />
        </div>
        {unseenNotificationsCount > 0 ? (
          <div
            className={clsx(
              styles.notificationNumber,
              lightMode && styles.notificationNumberDisplayBanner,
            )}
          >
            {unseenNotificationsCount > 99 ? '99+' : unseenNotificationsCount}
          </div>
        ) : (
          ''
        )}
      </Button>
      <Menu
        classes={{
          paper: clsx(styles.notificationMenu, styles.menuPaper),
        }}
        id="customized-menu"
        anchorEl={notificationEl}
        keepMounted
        open={Boolean(notificationEl)}
        onClose={handleNotificationClose}
        elevation={0}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiMenu-list': {
            paddingTop: '2px',
          },
        }}
      >
        <div className={styles.notificationHeader}>
          <Button
            onClick={(): void => {
              history.push('/notification-center');
              handleNotificationClose();
            }}
          >
            <span className={styles.notificationHeaderButton}>
              {t('notification-menu.see-more')}
            </span>
          </Button>
          <span className={styles.verticalBar}> | </span>
          <Button
            className={styles.notificationHeaderButton}
            onClick={(): void => {
              markAllNotificationsAsSeen();
            }}
          >
            <div
              className={unseenNotificationsCount === 0 ? styles.disabled : ''}
            >
              {t('notification-menu.mark-all-as-seen')}
            </div>
          </Button>
          <Button
            className={styles.refreshButton}
            onClick={(): void => setIsLoadingNotifications(true)}
          >
            <div className={styles.refreshIcon}>
              <img
                src="/assets/images/refresh-notification.svg"
                height="15px"
                width="15px"
                alt={t('alt-text.refresh')}
              />
            </div>
          </Button>
        </div>
        {notifications.length === 0 ? (
          <div className={styles.noNotification}>
            {t('notification-menu.no-notifications')}
          </div>
        ) : (
          <>
            {isLoadingNotifications ? (
              <div className={styles.noNotification}>
                {t('notification-menu.loading')}
              </div>
            ) : (
              <>
                {notifications.map((notification, index) => (
                  <div className={styles.notificationItem} key={index}>
                    <MenuItem
                      onClick={(): void => {
                        history.push(notification.ClickRoute || '');
                        handleNotificationClose();
                      }}
                    >
                      {notification.SeenByUser === false ? (
                        <div className={styles.notificationDot}></div>
                      ) : (
                        ''
                      )}
                      <div className={styles.notificationListItem}>
                        <span>
                          {notification.TalentName} -{' '}
                          {notification.Notification}
                        </span>
                        <div className={styles.notificationTimestamp}>
                          {isToday(
                            getDateFromSQLDateTime(notification.Timestamp),
                          ) ? (
                            <div>
                              <span className={styles.today}>
                                {t('notification-center.row.today')}
                              </span>
                              <span>
                                {getTimeStampOnly(
                                  getDateFromSQLDateTime(
                                    notification.Timestamp,
                                  ),
                                )}
                              </span>
                            </div>
                          ) : isYesterday(
                            getDateFromSQLDateTime(notification.Timestamp),
                          ) ? (
                            <div>
                              <span className={styles.yesterday}>
                                {t('notification-center.row.yesterday')}
                              </span>
                              <span>
                                {getTimeStampOnly(
                                  getDateFromSQLDateTime(
                                    notification.Timestamp,
                                  ),
                                )}
                              </span>
                            </div>
                          ) : (
                            <div>
                              {format(
                                getDateFromSQLDateTime(notification.Timestamp),
                                `MM/dd/yyyy HH:mm:ss`,
                              )}
                            </div>
                          )}{' '}
                        </div>
                      </div>
                    </MenuItem>
                    {index !== 4 && <div className={styles.horizontalLine} />}
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </Menu>
    </div>
  );
};

export default Notifications;
