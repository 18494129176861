import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { ApolloClient, ApolloQueryResult, FetchResult } from '@apollo/client';
import { GraphQLError } from 'graphql';
import gql from 'graphql-tag';
import {
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Crop } from 'react-image-crop';
import { ReactStripeElements } from 'react-stripe-elements';

import {
  DeleteCardsForUserMutation,
  GetUserPaymentCardsQuery,
  SaveCardForUserMutation,
  SaveCardForUserMutationVariables,
} from '../API';
import { deleteCardsForUser, saveCardForUser } from '../graphql/mutations';
import { getUserPaymentCards } from '../graphql/queries';
import { i18nPromise } from '../i18n';
import { FontFamilyOptions, FontFamilyRes } from '../interfaces/common';
import { PaymentMethods, PaymentCard } from '../interfaces/props';
import { useAuthValue } from '../services/Auth/Auth';
import { getURLEnv } from '../utils';

import {
  KEYCHAIN_DEFAULT_AVATAR,
  USER_AVATAR_NAVBAR_COMPRESSION_DETAILS,
} from './constants';
import {
  getCroppedImg,
  getBlobURL,
  cleanupBlobURL,
  getImageURL,
} from './images';
import { getTalentIDFromUserAvatarPath, getUserAvatarPath } from './keychain';
import { displayToastMessage } from './notifications';
import { getAssetUrl } from './storage';

export const useI18n = (): boolean => {
  const [isI18nLoaded, setIsI18nLoaded] = useState<boolean>(false);

  useEffect((): void => {
    (async (): Promise<void> => {
      const i18nFunction = await i18nPromise;

      setIsI18nLoaded(!!i18nFunction);
    })();
  });

  return isI18nLoaded;
};

export const useAssetUrl = (
  key: string,
): [string, Dispatch<SetStateAction<string>>, string] => {
  const [fileUrl, setFileUrl] = useState<string>('');

  useEffect((): void => {
    (async (): Promise<void> => {
      const url = await getAssetUrl(key, '');

      setFileUrl(url);
    })();
  }, [key]);

  return [fileUrl, setFileUrl, key];
};

export const useAvatar = (
  avatarKey: string | null,
  options?: {
    reRenderOnEqualKey?: boolean;
    useCompressedImage?: boolean;
  },
): [string, (avatar: string | null) => void, string | null] => {
  const [count, setCount] = useState<number>(0);
  const [avatar, setAvatarState] = useState<string | null>(avatarKey);
  const [avatarUrl, setAvatarUrl] = useState<string>(KEYCHAIN_DEFAULT_AVATAR);

  useEffect((): (() => void) => {
    let mounted = true;

    (async (): Promise<void> => {
      const key = avatar?.trim();

      if (!key) {
        setAvatarUrl(KEYCHAIN_DEFAULT_AVATAR);

        return;
      }

      if (options?.useCompressedImage) {
        const talentID = getTalentIDFromUserAvatarPath(key);

        if (talentID) {
          const compressedImagePath = getUserAvatarPath(talentID, true);

          const compressedImageKey = getImageURL(
            compressedImagePath,
            USER_AVATAR_NAVBAR_COMPRESSION_DETAILS.fileType,
          );

          if (compressedImageKey) {
            const url = await getAssetUrl(
              compressedImageKey,
              KEYCHAIN_DEFAULT_AVATAR,
            );

            if (mounted) setAvatarUrl(url);

            return;
          }
        }
      }

      const url = await getAssetUrl(key, KEYCHAIN_DEFAULT_AVATAR);

      if (mounted) setAvatarUrl(url);
    })();

    return (): void => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar, count]);

  const setAvatar = (avatar: string | null): void => {
    options?.reRenderOnEqualKey && setCount(count + 1);
    setAvatarState(avatar);
  };

  return [avatarUrl, setAvatar, avatar];
};

export const useCohortImage = (
  cohortKey: string | null,
): [string, (imageString: string | null) => void, string | null] => {
  const [count, setCount] = useState<number>(0);
  const [cohortImage, setCohortImageState] = useState<string | null>(cohortKey);
  const [cohortImageUrl, setCohortImageUrl] = useState<string>(
    KEYCHAIN_DEFAULT_AVATAR,
  );

  useEffect((): (() => void) => {
    let mounted = true;

    (async (): Promise<void> => {
      const key = cohortImage?.trim();

      if (!key) {
        setCohortImageUrl(KEYCHAIN_DEFAULT_AVATAR);

        return;
      }

      const url = await getAssetUrl(key, KEYCHAIN_DEFAULT_AVATAR);

      if (mounted) setCohortImageUrl(url);
    })();

    return (): void => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortImage, count]);

  const setCohortImage = (avatar: string | null): void => {
    setCount(count + 1);
    setCohortImageState(avatar);
  };

  return [cohortImageUrl, setCohortImage, cohortImage];
};

export const useSegmentImage = (
  segmentKey: string | null,
): [string, (imageString: string | null) => void, string | null] => {
  const [count, setCount] = useState<number>(0);
  const [segmentImage, setSegmentImageState] = useState<string | null>(
    segmentKey,
  );
  const [segmentImageUrl, setSegmentImageUrl] = useState<string>(
    KEYCHAIN_DEFAULT_AVATAR,
  );

  useEffect((): (() => void) => {
    let mounted = true;

    (async (): Promise<void> => {
      const key = segmentImage?.trim();

      if (!key) {
        setSegmentImageUrl(KEYCHAIN_DEFAULT_AVATAR);

        return;
      }

      const url = await getAssetUrl(key, KEYCHAIN_DEFAULT_AVATAR);

      if (mounted) setSegmentImageUrl(url);
    })();

    return (): void => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentImage, count]);

  const setCohortImage = (avatar: string | null): void => {
    setCount(count + 1);
    setSegmentImageState(avatar);
  };

  return [segmentImageUrl, setCohortImage, segmentImage];
};

export const useFonts = ({
  defaultFile,
  fontFamily,
  otherFormats,
}: FontFamilyOptions): FontFamilyRes | null => {
  const [fontUrls, setFontUrls] = useState<FontFamilyRes | null>(null);

  useEffect((): (() => void) => {
    let mounted = true;

    (async (): Promise<void> => {
      let defaultURL = await getAssetUrl(defaultFile, '');
      const { origin, pathname } = new URL(defaultURL);

      defaultURL = `${origin}${pathname}`;

      const fontUrls = [defaultURL];

      if (otherFormats) {
        otherFormats.forEach((format): void => {
          fontUrls.push(defaultURL.replace(defaultFile, format));
        });
      }

      const result = {
        fontFamily: fontFamily,
        urls: fontUrls,
      };

      if (mounted) setFontUrls(result);
    })();

    return (): void => {
      mounted = false;
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [fontFamily]);

  return fontUrls;
};

export const useEventImage = (
  eventImageKey: string | null,
  eventImageTemp?: File,
): [
  string,
  Dispatch<SetStateAction<string | null>>,
  string | null,
  (newTempImage?: File) => void,
  File | undefined,
] => {
  const [imageTemp, setImageTemp] = useState<File | undefined>(eventImageTemp);
  const [imageTempURL, setImageTempURL] = useState<string | null>(
    imageTemp ? getBlobURL(imageTemp) : null,
  );
  const [eventImage, setEventImage] = useState<string | null>(eventImageKey);
  const [eventImageUrl, setEventImageUrl] = useState<string>('');

  const updateImageTemp = (newImageTemp?: File): void => {
    setImageTemp(newImageTemp);

    if (imageTempURL) URL.revokeObjectURL(imageTempURL);

    setImageTempURL(newImageTemp ? getBlobURL(newImageTemp) : null);
  };

  useEffect((): (() => void) => {
    let mounted = true;

    (async (): Promise<void> => {
      let url = '';

      if (imageTempURL) {
        url = imageTempURL;
      } else if (eventImage) {
        url = await getAssetUrl(eventImage, url);
      }

      if (mounted) setEventImageUrl(url);
    })();

    return (): void => {
      mounted = false;
    };
  }, [eventImage, imageTempURL]);

  return [eventImageUrl, setEventImage, eventImage, updateImageTemp, imageTemp];
};

export const usePaymentMethods = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: ApolloClient<any> | undefined,
  isSignedIn: boolean,
): {
  paymentMethods: PaymentMethods;
  paymentMethodsLoading: boolean;
  paymentMethodsError: readonly GraphQLError[] | null;
  setPaymentMethods: (
    value: ((prevState: PaymentMethods) => PaymentMethods) | PaymentMethods,
  ) => void;
  onCardAdded: (
    tokenResponse: ReactStripeElements.TokenResponse | undefined,
    zipCode: string,
  ) => Promise<void>;
  onCardDeleted: () => Promise<void>;
} => {
  const [data, setData] = useState<PaymentMethods>({});
  const [error, setError] = useState<readonly GraphQLError[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const isGetPaymentCardQueryResults = (
    data: GetUserPaymentCardsQuery | SaveCardForUserMutation,
  ): data is GetUserPaymentCardsQuery => {
    return (data as GetUserPaymentCardsQuery).getUserPaymentCards !== undefined;
  };

  const isSavePaymentCardMutationResults = (
    data: GetUserPaymentCardsQuery | SaveCardForUserMutation,
  ): data is SaveCardForUserMutation => {
    return (data as SaveCardForUserMutation).saveCardForUser !== undefined;
  };

  const getUserCreditCards = useCallback(
    (
      result:
        | ApolloQueryResult<GetUserPaymentCardsQuery>
        | FetchResult<SaveCardForUserMutation>,
    ): PaymentCard[] => {
      if (result.errors) {
        setError(result.errors);
        setLoading(false);

        return [];
      }

      const cards: PaymentCard[] = [];
      const { data } = result;

      // if (data) {
      //   if (
      //     isGetPaymentCardQueryResults(data) &&
      //     data.getUserPaymentCards.data
      //   ) {
      //     return data.getUserPaymentCards.data.cards;
      //   } else if (
      //     isSavePaymentCardMutationResults(data) &&
      //     data.saveCardForUser.data
      //   ) {
      //     return data.saveCardForUser.data.cards;
      //   }
      // }

      return cards;
    },
    [],
  );

  const getUserStripeAccountToken = useCallback(
    (
      result: ApolloQueryResult<GetUserPaymentCardsQuery>,
    ): string | null | undefined => {
      const { data } = result;

      return data && data.getUserPaymentCards.data
        ? data.getUserPaymentCards.data.account
        : null;
    },
    [],
  );

  useEffect((): (() => void) => {
    let mounted = true;

    if (!isSignedIn) {
      return (): void => {
        mounted = false;
      };
    }

    (async (): Promise<void> => {
      try {
        const result = await client?.query<GetUserPaymentCardsQuery>(
          {
            query: gql(getUserPaymentCards),
            fetchPolicy: 'network-only',
            variables: {
              Env: getURLEnv(),
            },
        });

        if (mounted) {
          setData({
            cards: getUserCreditCards(result as ApolloQueryResult<GetUserPaymentCardsQuery>),
            account: getUserStripeAccountToken(result as ApolloQueryResult<GetUserPaymentCardsQuery>),
          });
          setLoading(false);
        }
      } catch (error) {
        if (mounted) {
          setData({ cards: [], account: null });
          setLoading(false);
        }
      }
    })();

    return (): void => {
      mounted = false;
    };
  }, [getUserCreditCards, getUserStripeAccountToken, isSignedIn]);

  const onCardAdded = async (
    tokenResponse: ReactStripeElements.TokenResponse | undefined,
    zipCode: string,
  ): Promise<void> => {
    if (tokenResponse && tokenResponse.token) {
      setLoading(true);

      try {
        const result = await client?.mutate<
          SaveCardForUserMutation,
          SaveCardForUserMutationVariables
        >({
          mutation: gql(saveCardForUser),
          variables: {
            tokenID: tokenResponse.token.id,
            zipCode: zipCode,
            Env: getURLEnv(),
          },
        });

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore typing was pulled directly from type inferred from result but still throwing lint error
        setData({ cards: getUserCreditCards(result) });
      } catch (error) {
        displayToastMessage(t('payment-method.error-processing-payment'), {
          type: 'warning',
        });
        setData({ cards: [], account: null });
      }

      setLoading(false);
    }
  };

  const onCardDeleted = async (): Promise<void> => {
    setLoading(true);
    const result = await client?.mutate<DeleteCardsForUserMutation, {}>({
      mutation: gql(deleteCardsForUser),
    });

    const { errors } = result ?? {};

    if (errors) {
      setError(errors);
      setLoading(false);

      return;
    }

    setData({});
    setLoading(false);
  };

  return {
    paymentMethods: data,
    paymentMethodsLoading: loading,
    paymentMethodsError: error,
    setPaymentMethods: setData,
    onCardAdded,
    onCardDeleted,
  };
};

export const useRootOverflowHidden = (): void => {
  useEffect((): (() => void) => {
    const root = document.getElementById('root');
    let origStyle = 'visible';

    if (root) {
      origStyle = root.style.overflow || origStyle;
      root.style.overflow = 'hidden';
    }

    return (): void => {
      if (root) root.style.overflow = origStyle;
    };
  }, []);
};

export const useRefetchLimit = <D, V = {}>(
  limit: number,
): [
  (
    refetch: (v?: V) => Promise<ApolloQueryResult<D>>,
    v?: V,
  ) => Promise<ApolloQueryResult<D>> | false,
  number,
] => {
  const [triesLeft, setTriesLeft] = useState<number>(limit);

  const refetchFunction = (
    refetch: (v?: V) => Promise<ApolloQueryResult<D>>,
    variables?: V,
  ): Promise<ApolloQueryResult<D>> | false => {
    if (typeof refetch !== 'function') {
      window.Rollbar.error('refetch must be defined to use this hook');
    } else if (triesLeft > 0) {
      setTriesLeft((tries): number => tries - 1);

      return refetch(variables);
    }

    return false;
  };

  return [refetchFunction, triesLeft];
};

export const useCroppedImage = (
  initialCrop: Crop,
  rawFile: File,
): [
  File | null,
  (image: HTMLImageElement) => void,
  Crop,
  Dispatch<SetStateAction<Crop>>,
] => {
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [croppedImageURL, setCroppedImageURL] = useState<string | null>(null);
  const [currentCrop, setCurrentCrop] = useState<Crop>(initialCrop);
  const [image, setImage] = useState<HTMLImageElement | null>(null);

  const updateImageRef = (newImage: HTMLImageElement | null): void => {
    if (newImage && image !== newImage) {
      setImage(newImage);
    }
  };

  useEffect((): void => {
    if (!image) return;

    (async (): Promise<void> => {
      const cropResult = await getCroppedImg(image, currentCrop, rawFile);
      let newURL: string | null = null;

      if (cropResult) newURL = getBlobURL(cropResult);

      if (newURL !== croppedImageURL) {
        if (croppedImage) cleanupBlobURL(croppedImage);

        setCroppedImageURL(newURL);
      }

      setCroppedImage(cropResult);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCrop.height,
    currentCrop.width,
    currentCrop.x,
    currentCrop.y,
    image,
  ]);

  return [croppedImage, updateImageRef, currentCrop, setCurrentCrop];
};

export default function useOnclickOutside (initialVisibility: boolean) {
  const [isVisible, setIsVisible] = useState<boolean>(initialVisibility);
  const ref = useRef<any>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
}

export const unSelectFilterValues = (
  selectedFilterValues: Array<any>,
  id: number,
  setSelectedFilterValues: React.Dispatch<React.SetStateAction<any>>,
  removeFilter?: (filterID: number) => void,
  FilterID?: number,
) => {
  const tempArr = [...selectedFilterValues];

  const itemIndex = tempArr.findIndex(value => value.ID === id);

  if (itemIndex > -1) {
    tempArr.splice(itemIndex, 1);
    setSelectedFilterValues(tempArr);

    if (removeFilter && FilterID && tempArr.length === 0) {
      removeFilter(FilterID);
    }
  }
};
