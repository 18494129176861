import React, { ComponentType, FC, lazy, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { GetCurrentUserQuery, GetCurrentUserQueryVariables } from '../API';
import { getCurrentUser } from '../graphql/queries';
import FanExportDashboard from '../ui/FanGroups/FanExportDashboard';
import Loading from '../ui/Loading/Loading';

import TalentOnlyRoute from './TalentOnlyRoute';
import { gql } from '@apollo/client';
import { WithApolloClient } from '@apollo/client/react/hoc';

const Error404 = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/Error404/Error404'),
);

const FanManagerRoutes: FC<WithApolloClient<{}>> = ({
  client,
}): JSX.Element => {
  const params = new URLSearchParams(window.location.search);
  const talentIDParam = Number(params.get('talentID'));
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      const result = await client?.query<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
      >({
        query: gql(getCurrentUser),
        fetchPolicy: 'network-only',
      });

      const { data, error, loading } = result || {};

      if (error) {
        window.Rollbar.error('GetCurrentUserQuery', { data, error });
      }

      data && setIsAdmin(data?.getCurrentUser.UserIsAdmin);
      setIsLoading(loading || false);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Loading />;
  }

  if (isAdmin && !talentIDParam) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <TalentOnlyRoute
        exact
        path="/fanmanager/dashboard"
        component={FanExportDashboard}
      />
      <Route component={Error404} />
    </Switch>
  );
};

export default FanManagerRoutes;
