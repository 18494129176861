import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import styles from './Navigator.module.scss';

import flagsmith from 'flagsmith';

interface NavigatorProps {
  talentID: number;
  loggedInUserIsTalent?: boolean;
  history: RouteComponentProps['history'];
  hasKeychain: boolean;
  hasEmailAccess: boolean;
  talentHasOneOrMoreMediaKit?: boolean;
}

interface LinkProps {
  text: string;
  icon: string;
  url: string;
  onClick: (url: string) => void;
}

const Navigator: FC<NavigatorProps> = ({
  talentID,
  loggedInUserIsTalent,
  history,
  hasKeychain,
  hasEmailAccess,
  talentHasOneOrMoreMediaKit,
}): JSX.Element => {
  const { t } = useTranslation();

  const shouldDisableMediaKitTab = flagsmith.hasFeature('disable_media_kit');

  const goToCreateKeyLinkUrl = (url: string) => {
    history.push(
      `${url}/signup?tab=management${
        !loggedInUserIsTalent ? `&talentID=${talentID}` : ''
      }`,
    );
  };

  const onClick = (url: string) => {
    history.push(
      `${url}${!loggedInUserIsTalent ? `?talentID=${talentID}` : ''}`,
    );
  };

  return (
    <div className={styles.navigator}>
      <div className={styles.links}>
        <Link
          text={t('navigation.talent-profile')}
          icon="home-icon"
          url="/"
          onClick={onClick}
        />
        <Link
          text={t('navigation.fan-insights')}
          icon="fan-insights-icon"
          url="/fan-insights"
          onClick={onClick}
        />
        {hasEmailAccess && 
          <Link
            text={t('navigation.emails')}
            icon="emails-tab-icon"
            url="/emails"
            onClick={onClick}
          />
        }
        {hasKeychain ? (
          <Link
            text={t('navigation.key-links')}
            icon="keychain-icon"
            url="/keychain"
            onClick={onClick}
          />
        ) : (
          <Link
            text={t('navigation.key-links')}
            icon="keychain-icon"
            url="/keychain"
            onClick={goToCreateKeyLinkUrl}
          />
        )}
        {!shouldDisableMediaKitTab && ( 
        <Link 
          text={t('navigation.media-kit')}
          icon="media-kit-icon"
          url={talentHasOneOrMoreMediaKit ? '/media-kit-dashboard' : '/media-kit/new'}
          onClick={onClick}
        />
        )}
        {loggedInUserIsTalent && (
          <Link
            text={t('navigation.exports')}
            icon="export-icon-tab"
            url="/fanmanager/dashboard"
            onClick={onClick}
          />
        )}
        {loggedInUserIsTalent && (
          <Link
            text={t('navigation.account')}
            icon="account-icon"
            url="/profile"
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};

const Link: FC<LinkProps> = ({ text, icon, url, onClick }): JSX.Element => {
  const { t } = useTranslation();

  const location = useLocation();

  const isSelected = location.pathname === url;

  const dynamicIcon = isSelected ? icon : `${icon}-primary`;

  return (
    <button
      className={clsx(
        styles.link,
        isSelected && styles.bottomBorder,
        isSelected && styles.whiteText,
      )}
      onClick={() => onClick(url)}
    >
      <img
        src={`/assets/images/${dynamicIcon}.svg`}
        alt={t(`alt-text.${icon}`)}
      />
      <span>{text}</span>
    </button>
  );
};

export default Navigator;
