import { gql, useQuery } from "@apollo/client";
import { Button, Menu, MenuItem, ThemeProvider, createTheme } from "@mui/material";
import { getAdditionalOrganizations } from "graphql/queries";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from './Navbar.module.scss';
import { useAuthValue } from '../../services/Auth/Auth';
import clsx from "clsx";

const OrganizationsDropdown = (props) => {

    const { loggedInUserID, organizationName } = props;
    const { user, dbUser, setCurrentUser } = useAuthValue();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const history = useHistory();
    const handleOrganizationChange = (organizationID: number): void => {

        handleClose();

        if (dbUser) {
            setCurrentUser({
                ...dbUser,
                OrganizationID: organizationID,
            });
        }

        history.push(`/`);


    }

    const { data: additionalOrgs, error, loading } = useQuery(
        gql(getAdditionalOrganizations),
        {
            fetchPolicy: 'network-only',
            variables: {
                UserID: loggedInUserID,
            },
        },
    );

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
            background: {
                default: '#000',
                paper: '#000',
            },
            text: {
                primary: '#fff',
                secondary: '#fff',
            },
        },
    });

    if (additionalOrgs?.getAdditionalOrganizations && additionalOrgs?.getAdditionalOrganizations?.length > 1) {
        return (
            <div className={styles.additionalOrgContainer}>
                <ThemeProvider theme={darkTheme}>
                    <Button
                        id="additional-orgs-button"
                        aria-controls={open ? 'additional-orgs-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className={clsx(
                            styles.additionalOrgsButton,
                            open ? styles.additionalOrgsButtonOpen : ''
                        )}
                        sx={{
                            color: 'white',
                            minWidth: '36px',
                            // transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                    >
                        {organizationName} <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>
                    </Button>
                    <Menu
                        id="additional-orgs-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'additional-orgs-button',
                        }}
                        className={styles.additionalOrgsMenu}
                    >
                        {additionalOrgs?.getAdditionalOrganizations?.map((organization) => (
                            organization.OrganizationID !== dbUser?.OrganizationID &&
                            <MenuItem
                                onClick={() => handleOrganizationChange(organization.OrganizationID)}
                                key={loggedInUserID + "_" + organization.OrganizationID}
                                className={styles.additionalOrgMenuItem}>
                                <div>
                                    {organization.OrganizationName}
                                </div>
                            </MenuItem>
                        ))}
                    </Menu>
                </ThemeProvider >
            </div>
        )
    }
    else {
        return (
            <div
                className={clsx(
                    styles.navbarText,
                    styles.organizationName,
                    styles.organizationNameDisplayBanner,
                )}
            >
                {organizationName ? organizationName : ''}
            </div>
        );
    }
}

export default OrganizationsDropdown;