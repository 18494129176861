import { SocialMediaTypes } from '../interfaces/props';

import {
  KEYCHAIN_BACKGROUND_COMPRESSION_DETAILS,
  KEYCHAIN_DEFAULT_DOMAIN,
  USER_AVATAR_COMPRESSION_DETAILS_JPG,
  USER_AVATAR_COMPRESSION_DETAILS_PNG,
  USER_AVATAR_COMPRESSION_DETAILS_WEBP,
  USER_AVATAR_NAVBAR_COMPRESSION_DETAILS,
} from './constants';
import { compressAndUploadImage } from './images';
import { uploadImage } from './storage';

export const loadSocialMediaIcon = (socialMediaType: SocialMediaTypes) => {
  switch (socialMediaType) {
    case SocialMediaTypes.SPOTIFY:
      return '/assets/images/keychain-spotify-icon.svg';
    case SocialMediaTypes.TWITTER:
      return '/assets/images/keychain-twitter-icon.svg';
    case SocialMediaTypes.TIKTOK:
      return '/assets/images/keychain-tiktok-icon.svg';
    case SocialMediaTypes.YOUTUBE:
      return '/assets/images/keychain-youtube-icon.svg';
    case SocialMediaTypes.INSTAGRAM:
      return '/assets/images/keychain-insta-icon.svg';
    case SocialMediaTypes.FACEBOOK:
      return '/assets/images/keychain-fb-icon.svg';
    case SocialMediaTypes.TWITCH:
      return '/assets/images/keychain-twitch-icon.svg';
    case SocialMediaTypes.APPLEMUSIC:
      return '/assets/images/keychain-apple-music-icon.svg';
    case SocialMediaTypes.SNAPCHAT:
      return '/assets/images/keychain-snapchat-icon.svg';
    case SocialMediaTypes.AMAZONSTORE:
      return '/assets/images/keychain-amazon-icon.svg';
    case SocialMediaTypes.EMAIL:
      return '/assets/images/keychain-email-icon.svg';
    case SocialMediaTypes.THREADS:
      return '/assets/images/keychain-threads-icon.svg';
    case SocialMediaTypes.PINTEREST:
      return '/assets/images/keychain-pinterest-icon.svg';
    default:
      return '';
  }
};

export const buildSocialMediaURL = (
  socialMediaType: SocialMediaTypes,
  socialMediaUserID: string,
): string | undefined => {
  if (socialMediaUserID) {
    switch (socialMediaType) {
      case SocialMediaTypes.TWITTER:
        return `https://www.twitter.com/${socialMediaUserID}`;
      case SocialMediaTypes.TIKTOK:
        return `https://www.tiktok.com/@${socialMediaUserID}`;

      case SocialMediaTypes.SPOTIFY:
      case SocialMediaTypes.YOUTUBE:
        return socialMediaUserID.includes('https://')
          ? socialMediaUserID
          : `https://www.youtube.com/@${socialMediaUserID}`;

      case SocialMediaTypes.INSTAGRAM:
        return `https://www.instagram.com/${socialMediaUserID}`;
      case SocialMediaTypes.FACEBOOK:
        return socialMediaUserID.includes('https://')
          ? socialMediaUserID
          : `https://wwww.facebook.com/${socialMediaUserID}`;
      case SocialMediaTypes.TWITCH:
        return socialMediaUserID.includes('https://')
          ? socialMediaUserID
          : `https://${socialMediaUserID}`;
      case SocialMediaTypes.APPLEMUSIC:
        return socialMediaUserID.includes('https://')
          ? socialMediaUserID
          : `https://${socialMediaUserID}`;
      case SocialMediaTypes.SNAPCHAT:
        return socialMediaUserID.includes('https://')
          ? socialMediaUserID
          : `https://${socialMediaUserID}`;
      case SocialMediaTypes.AMAZONSTORE:
        return socialMediaUserID.includes('https://')
          ? socialMediaUserID
          : `https://${socialMediaUserID}`;
      case SocialMediaTypes.EMAIL:
        return `mailto:${socialMediaUserID}`;
      case SocialMediaTypes.THREADS:
        return `https://www.threads.net/@${socialMediaUserID}`;
      case SocialMediaTypes.PINTEREST:
        return socialMediaUserID.includes('https://')
          ? socialMediaUserID
          : `https://${socialMediaUserID}`;
    }
  }
};

export const getOldKeychainProfileURL = (profileURL: string): string => {
  const domain = 'mykeychain.co/';

  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return `${domain}${profileURL}`;
    case 'quality-assurance':
      return `${domain}${profileURL}-qa`;
    case 'development':
      return `${domain}${profileURL}-development`;
    case 'local':
      return `${domain}${profileURL}-development`;
    case 'testing':
      return '';
    default:
      return `${domain}${profileURL}-development`;
  }
};

export const getKeychainProfileURL = (
  profileURL: string,
  customDomain: string,
): string => {
  const domain = KEYCHAIN_DEFAULT_DOMAIN;

  if (customDomain && customDomain !== domain)
    return `${customDomain}/${profileURL}`;

  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return `${domain}/${profileURL}`;
    case 'quality-assurance':
      return `qa.${domain}/${profileURL}`;
    case 'development':
      return `dev.${domain}/${profileURL}`;
    case 'local':
      return `dev.${domain}/${profileURL}`;
    case 'testing':
      return '';
    default:
      return `dev.${domain}/${profileURL}`;
  }
};

export const getKeychainClubProfileURL = (profileURL: string): string => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return `https://keychain.club/${profileURL}`;
    case 'quality-assurance':
      return `https://qa.keychain.club/${profileURL}`;
    case 'development':
      return `https://dev.keychain.club/${profileURL}`;
    case 'local':
      return `https://dev.keychain.club/${profileURL}`;
    case 'testing':
      return '';
    default:
      return `https://dev.keychain.club/${profileURL}`;
  }
};

export const buildRedirectURL = (
  url: string,
  talentID?: number | null | undefined,
  sendToKeychain?: boolean,
  type?: string,
): { pathname: string; search: string; state?: { management: boolean } } => {
  return {
    pathname: url,
    search: talentID
      ? sendToKeychain
        ? type
          ? `?tab=keychain&talentID=${talentID}&type=${type}`
          : `?tab=keychain&talentID=${talentID}`
        : `?tab=capturelinks&talentID=${talentID}`
      : sendToKeychain
        ? type
          ? `?tab=keychain&type=${type}`
          : `?tab=keychain`
        : `?tab=capturelinks`,
    state: { management: true },
  };
};

export const getUserAvatarPath = (
  talentID: number,
  forNavbar?: boolean,
  original?: boolean,
  forOrganizations?: boolean
): string => {
  const defaultPath = 
    forOrganizations 
      ? `organizations/organization-${talentID}/avatar`
      : `users/user-${talentID}/avatar`;

  if (forNavbar) {
    return `${defaultPath}-small`;
  }

  if (original) {
    return `${defaultPath}-original`;
  }

  return defaultPath;
};

export const getKeychainDisplayPhotoPath = (
  talentID: number,
  forNavbar?: boolean,
  original?: boolean,
): string => {
  const defaultPath = `keychain/talent-${talentID}/display-photo/display-photo`;

  if (forNavbar) {
    return `${defaultPath}-small`;
  }

  if (original) {
    return `${defaultPath}-original`;
  }

  return defaultPath;
};

export const getKeychainPath = (talentID: number): string => {
  const defaultPath = `keychain/talent-${talentID}/backgroundImage`;

  return defaultPath;
};

export const getThumbnailPath = (talentID: number, keyLinkID: number): string =>
  `keychain/talent-${talentID}/keylink-${keyLinkID}/keylink-${keyLinkID}`;

export const getTalentIDFromUserAvatarPath = (path: string): number | null => {
  const numbers = path.match(/\d+/);

  if (!numbers) {
    return null;
  }

  return Number(numbers[0]);
};

export const uploadUserAvatarImages = async (
  talentID: number,
  image: File,
  progressCallback?: (percentage: number, name: string) => void,
  forOrganizations?: boolean,
): Promise<string> => {
  const defaultImagePath = 
    forOrganizations 
      ? getUserAvatarPath(talentID, undefined, undefined, true)
      : getUserAvatarPath(talentID);

  const defaultImageURL = await compressAndUploadImage(
    defaultImagePath,
    image,
    forOrganizations 
      ? USER_AVATAR_COMPRESSION_DETAILS_PNG
      : USER_AVATAR_COMPRESSION_DETAILS_JPG,
    progressCallback,
  );

  const navbarImagePath = getUserAvatarPath(talentID, true);

  await compressAndUploadImage(
    navbarImagePath,
    image,
    USER_AVATAR_NAVBAR_COMPRESSION_DETAILS,
    progressCallback,
  );

  const originalImagePath = getUserAvatarPath(talentID, false, true);

  await uploadImage(originalImagePath, image, progressCallback);

  return defaultImageURL;
};

export const uploadKeychainDisplayPhoto = async (
  talentID: number,
  image: File,
  progressCallback?: (percentage: number, name: string) => void,
): Promise<string> => {
  const defaultImagePath = getKeychainDisplayPhotoPath(talentID);

  const extention = image.name.split('.').pop();

  const defaultImageURL = await compressAndUploadImage(
    defaultImagePath,
    image,
    extention == 'jpg' || extention == 'jpeg'
      ? USER_AVATAR_COMPRESSION_DETAILS_JPG
      : extention === 'png'
        ? USER_AVATAR_COMPRESSION_DETAILS_PNG
        : USER_AVATAR_COMPRESSION_DETAILS_WEBP,
    progressCallback,
  );

  const navbarImagePath = getKeychainDisplayPhotoPath(talentID, true);

  await compressAndUploadImage(
    navbarImagePath,
    image,
    USER_AVATAR_NAVBAR_COMPRESSION_DETAILS,
    progressCallback,
  );

  const originalImagePath = getKeychainDisplayPhotoPath(talentID, false, true);

  await uploadImage(originalImagePath, image, progressCallback);

  return defaultImageURL;
};

export const uploadKeychainBackgroundImage = async (
  talentID: number,
  image: File,
) => {
  const defaultImagePath = getKeychainPath(talentID);

  const defaultImageURL = await compressAndUploadImage(
    defaultImagePath,
    image,
    KEYCHAIN_BACKGROUND_COMPRESSION_DETAILS,
  );

  return defaultImageURL;
};

export const getCLPath = (talentID: number, keyLinkID: number): string => {
  const defaultPath = `keychain/talent-${talentID}/cl-${keyLinkID}/display-photo/display-photo`;

  return defaultPath;
};

export const uploadCLBackgroundPhoto = async (
  talentID: number,
  keyLinkID: number,
  image: File,
) => {
  const defaultImagePath = getCLPath(talentID, keyLinkID);

  const defaultImageURL = await compressAndUploadImage(
    defaultImagePath,
    image,
    KEYCHAIN_BACKGROUND_COMPRESSION_DETAILS,
  );

  return defaultImageURL;
};
