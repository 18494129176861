import { ComponentType, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loading from '../ui/Loading/Loading';

import AuthedRoute from './AuthedRoute';
import FanManager from './FanManagerRoutes';
import OrganizationAdminRoute from './OrganizationAdminRoute';
import OrganizationRoute from './OrganizationRoute';
import UnauthedRoute from './UnauthedRoute';
import ValidateTalentAdminRoute from './ValidateTalentAdminRoute';
import { WithApolloClient } from '@apollo/client/react/hoc';

const BrandRoutes = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../routes/BrandRoutes'),
);
const NotificationCenter = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/NotificationCenter/NotificationCenter'),
);
const Error404 = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/Error404/Error404'),
);
const ExportsDashboard = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/ExportsDashboard/ExportsDashboard'),
);
const SocialAudienceOverlap = lazy(
  (): Promise<{ default: ComponentType<WithApolloClient<any>> }> =>
    import('../pages/SocialAudienceOverlap/SocialAudienceOverlap'),
);
const ForgotPassword = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/ForgotPassword/ForgotPassword'),
);
const Home = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/Home/HomeContainer'),
);

const KeychainRoutes = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../routes/KeychainRoutes'),
);

const EmailRoutes = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../routes/EmailsRoute'),
);
const BrandProfileRoutes = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../routes/BrandProfileRoutes'),
);
const KeyCaptureLinkRoutes = lazy(
  (): Promise<{ default: ComponentType<WithApolloClient<{}>> }> =>
    import('../routes/KeyCaptureLinkRoutes'),
);
const ValidateCaptureLinkRedirectURL = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../routes/ValidateCaptureLinkRedirectURL'),
);
const LoggingIn = lazy(
  (): Promise<{
    default: ComponentType<any>;
  }> => import('../pages/LoggingIn/LoggingIn'),
);
const LoggingOut = lazy(
  (): Promise<{
    default: ComponentType<{}>;
  }> => import('../pages/LoggingOut/LoggingOut'),
);
const Login = lazy(
  (): Promise<{ default: ComponentType<{}> }> => import('../pages/Login/Login'),
);
const ManageProfile = lazy(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/ManageProfile/ManageProfile'),
);
const ResetPassword = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/ResetPassword/ResetPassword'),
);
const FansTermsOfService = lazy(
  (): Promise<{ default: ComponentType<{}> }> =>
    import('../pages/TermsOfService/FansTermsOfService'),
);
const CreateTalentProfile = lazy(
  (): Promise<{ default: ComponentType }> =>
    import('../pages/CreateTalentProfile/CreateTalentProfile'),
);
const CreateUser = lazy(
  (): Promise<{
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/CreateUser/CreateUser'),
);
const CreateSocialBrandSearchRequest = lazy(
  (): Promise<{
    default: ComponentType<WithApolloClient<any>>;
  }> =>
    import(
      '../pages/CreateSocialBrandSearchRequest/CreateSocialBrandSearchRequest'
    ),
);
const EditSocialBrandSearchRequest = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> =>
    import(
      '../pages/CreateSocialBrandSearchRequest/EditSocialBrandSearchRequest'
    ),
);
const SocialOverlapInsightsReport = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> =>
    import(
      '../pages/InsightsContainer/FanInsights/SocialEngagers/SocialEngagers'
    ),
);
const SocialMediaReach = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/SocialMediaReach/SocialMediaReach'),
);

const TalentAdminFanManager = lazy(
  (): Promise<{
    default: ComponentType;
  }> => import('../pages/TalentAdminFanManager/TalentAdminFanManager'),
);
const ChangeEmail = lazy(
  (): Promise<{
    default: ComponentType;
  }> => import('../pages/ChangeEmail/ChangeEmail'),
);
const OrganizationPage = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/Organizations/OrganizationsPage'),
);

const ProfilePage = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/Organizations/ProfilePage'),
);

const TalentProfileAccess = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> => import('../pages/TalentProfileAccess/TalentProfileAccess'),
);
const AssignManagersToTalent = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<WithApolloClient<any>>;
  }> =>
    import(
      '../pages/Organizations/OrganizationSettings/TalentsOfOrganization/AssignManagersToTalent'
    ),
);

const FanInsights = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/InsightsContainer/InsightsContainer'),
);

const MediaKitDashboard = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/MediaKit/MediaKitDashboard'),
);

const SaveMediaKit = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/MediaKit/SaveMediaKit'),
);

const PublicMediaKit = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/MediaKit/PublicMediaKit'),
);

const PublicRoster = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/SharedRosters/PublicRoster'),
);

const ManageMediaKit = lazy(
  (): Promise<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: ComponentType<any>;
  }> => import('../pages/MediaKit/ManageMediaKit'),
);

const routing = (): JSX.Element => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <UnauthedRoute exact path="/talent/signup" component={Login} />
        <UnauthedRoute exact path="/admin/signup" component={Login} />
        <UnauthedRoute exact path="/manager/signup" component={Login} />
        <UnauthedRoute exact path="/login" component={Login} />
        <UnauthedRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <UnauthedRoute exact path="/reset-password" component={ResetPassword} />
        <AuthedRoute exact path="/change-email" component={ChangeEmail} />
        <AuthedRoute exact path="/social-media-reach" component={SocialMediaReach} />
        <AuthedRoute path="/fanmanager" component={FanManager} />
        <AuthedRoute path="/fan-insights" component={FanInsights} />
        <AuthedRoute path="/keychain" component={KeychainRoutes} />
        <AuthedRoute path="/media-kit/new" component={SaveMediaKit} />
        <AuthedRoute path="/media-kit/:mediakitid/edit" component={SaveMediaKit} />
        <AuthedRoute path="/media-kit/:mediakitid/manage" component={ManageMediaKit} />
        <Route path="/media-kit-view/:mediakituuid" component={PublicMediaKit} />
        <AuthedRoute path="/media-kit-dashboard" component={MediaKitDashboard} />
        <AuthedRoute path="/save-media-kit" component={SaveMediaKit} />
        <AuthedRoute path="/manage-media-kit" component={ManageMediaKit} />
        <Route path="/talent-roster/:talentrosteruuid" component={PublicRoster} />
        <AuthedRoute path="/emails" component={EmailRoutes} />
        <AuthedRoute path="/brandprofiles" component={BrandProfileRoutes} />
        <AuthedRoute path="/capturelinks" component={KeyCaptureLinkRoutes} />
        <AuthedRoute
          exact
          path="/capturelink/:captureLinkID"
          component={ValidateCaptureLinkRedirectURL}
        />
        <AuthedRoute path="/brand" component={BrandRoutes} />
        <OrganizationRoute
          path="/social-engagers/request/create"
          component={CreateSocialBrandSearchRequest}
        />
        <OrganizationRoute
          path="/social-engagers/request/edit/:requestID"
          component={EditSocialBrandSearchRequest}
        />
        <OrganizationRoute
          path="/social-engagers/request/:requestID"
          component={SocialOverlapInsightsReport}
          dynamicURLKeyTerm="social"
        />
        <OrganizationAdminRoute
          path="/organizations/profile-access"
          component={TalentProfileAccess}
        />
        <OrganizationAdminRoute
          path="/organizations/assign-managers"
          component={AssignManagersToTalent}
        />
        <OrganizationAdminRoute
          path="/organizations"
          component={OrganizationPage}
        />
        <AuthedRoute path="/profiles" component={ProfilePage} />
        <Route exact path="/logout" component={LoggingOut} />
        <Route exact path="/login/auth" component={LoggingIn} />
        <AuthedRoute
          exact
          path="/fans-terms-of-service/:tosView?"
          component={FansTermsOfService}
        />
        <ValidateTalentAdminRoute
          exact
          path="/admin/add-talent"
          component={CreateTalentProfile}
        />
        <OrganizationAdminRoute
          exact
          path="/users/add"
          component={CreateUser}
        />

        <AuthedRoute exact path="/" component={Home} />
        <AuthedRoute exact path="/profile" component={ManageProfile} />
        <OrganizationRoute exact path="/exports" component={ExportsDashboard} />
        <OrganizationRoute
          exact
          path="/social-engagers"
          component={SocialAudienceOverlap}
        />
        <AuthedRoute
          exact
          path="/notification-center"
          component={NotificationCenter}
        />
        <OrganizationRoute
          path="/fangroups"
          component={TalentAdminFanManager}
        />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};

export default routing;
