/* eslint-disable import/order */
import React, { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { Pagination, PaginationItem } from '@mui/lab';
import styles from './BasicPagination.module.scss';
import clsx from 'clsx';

interface BasicPaginationProps {
  page: number;
  pageCount: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  additonalRootClass?: string;
}

const BasicPagination: FC<BasicPaginationProps> = ({
  page,
  pageCount,
  onPageChange,
  additonalRootClass,
}): JSX.Element => {
  const isOnTablet = useMediaQuery('(max-width: 768px)');
  const isOnMobile = useMediaQuery('(max-width: 456px)');

  return (
    <div className={styles.container}>
      <Pagination
        count={pageCount}
        page={page}
        onChange={onPageChange}
        boundaryCount={1}
        siblingCount={isOnMobile ? 1 : isOnTablet ? 2 : 3}
        classes={{
          root: clsx(styles.root, additonalRootClass),
        }}
        renderItem={item => {
          if (item.type === 'previous') {
            return (
              <PaginationItem
                {...item}
                classes={{
                  root: styles.itemRoot,
                  ellipsis: styles.itemEllipsis,
                  focusVisible: styles.itemFocusVisible,
                  selected: styles.itemSelected,
                  page: clsx(styles.itemCustomPage, styles.itemPreviousPage),
                }}
              />
            );
          }

          if (item.type === 'next') {
            return (
              <PaginationItem
                {...item}
                classes={{
                  root: styles.itemRoot,
                  ellipsis: styles.itemEllipsis,
                  focusVisible: styles.itemFocusVisible,
                  selected: styles.itemSelected,
                  page: clsx(styles.itemCustomPage, styles.itemNextPage),
                }}
              />
            );
          }

          return (
            <PaginationItem
              {...item}
              classes={{
                root: styles.itemRoot,
                ellipsis: styles.itemEllipsis,
                focusVisible: styles.itemFocusVisible,
                selected: styles.itemSelected,
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default BasicPagination;
