import { StyledEngineProvider } from '@mui/material';

// eslint-disable-next-line import/no-named-as-default
import { ApolloProvider, InMemoryCache, NormalizedCacheObject, HttpLink, ApolloClient, ApolloLink, createHttpLink } from '@apollo/client';
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
//import { withClientState } from 'apollo-link-state';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
//import AWSAppSyncClient, { AUTH_TYPE, createAppSyncLink } from 'aws-appsync'; // TODO - check if this can be removed
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
// import { Rehydrated } from 'aws-appsync-react'; // TODO - check if this can be removed
import config from './amplifyconfiguration.json';

import flagsmith from 'flagsmith';
import React, { FC, useEffect, useState } from 'react';
//import { ApolloProvider } from 'react-apollo'; // TODO - do we need react-apollo
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Router, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { CurrentUser } from './API';

//import introspectionQueryResultData from './fragmentTypes.json';
import ErrorBoundary from './pages/ErrorBoundary/ErrorBoundary';
import Routes from './routes/MainRoutes';
import { AuthConsumer, AuthProvider } from './services/Auth/Auth';
import KeyLinkProvider from './services/KeyLink/KeyLink';
import Loading from './ui/Loading/Loading';
import Navbar from './ui/Navbar/Navbar';
import { history } from './utils/history';
import { useI18n } from './utils/hooks';
import Rollbar from 'rollbar';
import { t } from 'i18next';
//import { createFragmentRegistry } from '@apollo/client/cache';

const url = config.aws_appsync_graphqlEndpoint;

const region = config.aws_appsync_region;

Amplify.configure(config);
declare global {
  interface Window {
    dataLayer: any;
    FB: any;
    Rollbar: Rollbar;
  }
}

window.dataLayer = window.dataLayer || [];
const gaTrackingID = 'UA-154852434-1'; //process.env.REACT_APP_GOOGLE_ANALYTICS_CODE;

//Initializing GA, if work needs to be done on DEV you'd just need to put that in a variable here locally
//and check process.env to be development to workaround the fact it's false for all branches besides master.
if (!gaTrackingID) {
  console.warn(
    'No GA tracking ID found, if this is local, this message can be ignored',
  );
  // } else if (gaTrackingID === 'false') {
  //   console.debug(
  //     'GA tracking ID is false, if this is development or QA, this message can be ignored',
  //   );
} else {
  ReactGA.initialize(gaTrackingID);
}
interface ClientConfig {
  auth: any;
  offlineConfig?: { keyPrefix: string };
}

let signedInClient: ApolloClient<NormalizedCacheObject> | null = null;
let signedOutClient: ApolloClient<NormalizedCacheObject> | null = null;

const getClientConfig = async (isSignedIn: boolean): Promise<ClientConfig> => {
  if (isSignedIn) {
    try {
      return {
        auth: {
          jwtToken: async () => {
            const session = await fetchAuthSession();
            return session.tokens?.idToken?.toString() || '';
          },
          type: config.aws_appsync_authenticationType,
        },
        offlineConfig: {
          keyPrefix: 'keyOffline',
        },
      };

    } catch (err) {
      return {
        auth: {
          type: "API_KEY",
          apiKey: config.aws_appsync_apiKey,
        },
        offlineConfig: {
          keyPrefix: 'keyOffline',
        },
      };
    }
  }


  return {
    auth: {
      type: "API_KEY",
      apiKey: config.aws_appsync_apiKey,
    },
  };
};

const createClient = async (
  isSignedIn: boolean,
): Promise<ApolloClient<NormalizedCacheObject>> => {
  const clientConfig = (await getClientConfig(isSignedIn)).auth as AuthOptions;
  const tempSession = await fetchAuthSession();

  let tempToken = tempSession.tokens?.idToken?.toString() || '';

  const httpLink = new HttpLink({ uri: url });

  const client = new ApolloClient(
    {
      cache: new InMemoryCache(), // TODO - removed fragments here, can we add them back? on user type
      defaultOptions: {
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'ignore',
        },
      },

      link: ApolloLink.from([
        createAuthLink({ url, region, auth: clientConfig }),
        createSubscriptionHandshakeLink({ url, region, auth: clientConfig }, httpLink)
      ])

    },
  );

  return client;
};

const getClient = async (
  isSignedIn: boolean,
): Promise<ApolloClient<NormalizedCacheObject>> => {
  try {
    if (isSignedIn) {
      if (!signedInClient) {
        signedInClient = await createClient(isSignedIn);
      }

      return signedInClient;
    }

    if (!signedOutClient) {
      signedOutClient = await createClient(isSignedIn);
    }

    return signedOutClient;
  } catch (error) {
    window.Rollbar.error(
      'Error on creating new AWSAppSyncClient, redirecting to login...',
      {
        error,
      },
    );

    const url = new URL(
      `/login/auth?isEmailSignin=true`,
      window.location.origin,
    );

    window.location.replace(url.href);

    throw error;
  }
};

const HelmetWrapper: typeof Helmet = Helmet;

const AppData: FC<{
  isSignedIn: boolean;
  currentUser: CurrentUser | null;
}> = ({ isSignedIn, currentUser }): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);
  const location = useLocation();
  const cookieProDataDomainScript =
    process.env.REACT_APP_COOKIE_PRO_DATA_DOMAIN_SCRIPT;

  const searchTermKeychainPublicProfileURL = '/keychain/profile/';
  const searchTermCaptureLinkRedirectPage = '/capturelink/';
  const isKeychainPublicProfileURL = location.pathname.includes(
    searchTermKeychainPublicProfileURL,
  );
  const isCaptureLinkRedirectPage = location.pathname.includes(
    searchTermCaptureLinkRedirectPage,
  );

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV !== 'development' &&
      isSignedIn &&
      !isKeychainPublicProfileURL &&
      !isCaptureLinkRedirectPage
    ) {
      flagsmith.init({
        environmentID: `${process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID}`,
        cacheFlags: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      process.env.REACT_APP_ENV !== 'development' &&
      isSignedIn &&
      !isKeychainPublicProfileURL &&
      !isCaptureLinkRedirectPage
    ) {
      if (currentUser) {
        const userID = currentUser.UserEmail as string;

        flagsmith.identify(userID);
        flagsmith.setTrait('emailAddress', userID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    (async () => {
      try {
        const tempClient = await getClient(isSignedIn) as ApolloClient<any>;
        setClient(tempClient);
      } catch (err) {
        console.log('Error loading client and client config', err);
      }
    })();
  }, [isSignedIn]);

  if (client) {
    return (
      <DndProvider backend={HTML5Backend}>
        <ApolloProvider
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          client={client}
        >
          {/*  CookiePro Script for IAB TCF 2.0 compliance  */}
          {isKeychainPublicProfileURL && (
            <HelmetWrapper>
              <script
                src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
                type="text/javascript"
                charSet="UTF-8"
                data-domain-script={cookieProDataDomainScript}
              ></script>
              <script type="text/javascript">function OptanonWrapper() { }</script>
            </HelmetWrapper>
          )}
          <>
            <Navbar
              currentUser={currentUser}
              isSignedIn={isSignedIn}
              client={client}
            />
            <ErrorBoundary key={location.pathname}>
              <Routes />
            </ErrorBoundary>
          </>
        </ApolloProvider>
      </DndProvider>
    );

  } else {
    return <></>
  }
};

const App: FC<{}> = (): JSX.Element => {
  const isI18nLoaded = useI18n();

  return (
    <StyledEngineProvider injectFirst>
      <ToastContainer />
      <HelmetWrapper defaultTitle="Key Fan Relationship Platform">
        <meta name="og:title" content="Key Fan Relationship Platform" />
        <meta
          name="og:description"
          content="Bringing Talent and Fans Closer than Ever Before"
        />
        <meta
          name="description"
          content="Bringing Talent and Fans Closer than Ever Before"
        />
      </HelmetWrapper>
      {isI18nLoaded ? (
        <AuthProvider>
          <Router history={history}>
            <AuthConsumer>
              {({ isSignedIn, loading, dbUser }): JSX.Element =>
                loading ? (
                  <Loading />
                ) : (
                  <KeyLinkProvider>
                    <AppData isSignedIn={isSignedIn} currentUser={dbUser} />
                  </KeyLinkProvider>
                )
              }
            </AuthConsumer>
          </Router>
        </AuthProvider>
      ) : (
        <Loading />
      )}
    </StyledEngineProvider>
  );
};

export default App;
