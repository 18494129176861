import clsx from 'clsx';
import FileSaver from 'file-saver';
import React, { FC, MouseEvent } from 'react';

import { FanGroupExportProps } from '../../interfaces/props';

import styles from './FanExportDashboardTable.module.scss';

interface RowProps {
  ExportName: string;
  LastModified: string;
  FileName: string;
  Url: string;
}

interface FanExportDashboardTableProps {
  exports: FanGroupExportProps[];
}

const FanExportDashboardTable: FC<FanExportDashboardTableProps> = ({
  exports,
}): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div className={styles.row}>
            <div className={styles.column}>Export Name</div>
            <div className={styles.column}></div>
          </div>
        </div>
        <div className={styles.tableBody}>
          {exports.length > 0 ? (
            exports.map((exportgroup, index) => (
              <>
                <Row
                  ExportName={exportgroup.title}
                  LastModified={exportgroup.lastModified}
                  FileName={exportgroup.fileName}
                  Url={exportgroup.url}
                  key={index}
                />
                {index !== exports.length - 1 && (
                  <div className={styles.divider}></div>
                )}
              </>
            ))
          ) : (
            <div className={styles.emptyTable}></div>
          )}
          {}
        </div>
      </div>
    </div>
  );
};

const Row: FC<RowProps> = ({
  ExportName,
  LastModified,
  FileName,
  Url,
}): JSX.Element => {
  const download = (event: MouseEvent<HTMLElement>): void => {
    event.stopPropagation();

    fetch(Url).then((response): void => {
      if (!response.ok) {
        console.log('Error', response);
      }

      response.blob().then((blob): void => {
        // Test for Chrome on iOS due to issues with iOS 13/Chrome & a.click()
        if (!/CriOS\/[\d]+/.test(navigator.userAgent)) {
          FileSaver.saveAs(blob, `${FileName}`);
        } else {
          FileSaver.saveAs(blob);
        }
      });
    });
  };

  return (
    <div className={styles.row}>
      <div className={styles.column}>
        <div>{ExportName}</div>
        <div className={styles.exportTimestamp}>{LastModified}</div>
      </div>
      <div
        className={clsx(styles.column, styles.buttonContainer)}
        onClick={e => download(e)}
      >
        <div className={styles.downloadButton}>Download</div>
      </div>
    </div>
  );
};

export default FanExportDashboardTable;
