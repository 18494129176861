import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  ExitToApp as LogoutIcon,
  Home as HomeIcon,
  PermIdentity as UserIcon,
} from '@mui/icons-material';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { PlatformUserType } from '../../interfaces/props';

import styles from './UserSettings.module.scss';

interface UserSettingsProps {
  history: RouteComponentProps['history'];
  userType: PlatformUserType | null;
  organizationID?: number;
  lightMode?: boolean;
  setShowLogoutConfirmationDialog: () => void;
}

const UserSettings: FC<UserSettingsProps> = ({
  history,
  userType,
  lightMode,
  setShowLogoutConfirmationDialog,
}): JSX.Element => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  if (!userType) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <div>
        <Button
          className={styles.button}
          onClick={event => {
            handleAnchorClick(event);
          }}
        >
          <img
            className={styles.buttonImage}
            src={
              lightMode
                ? '/assets/images/arrow-down-white.svg'
                : '/assets/images/arrow-down-gradient-color.svg'
            }
            alt={t('alt-text.create-icon')}
          />
        </Button>
        <Menu
          classes={{
            paper: clsx(styles.socialMenuPaper, styles.menuPaper),
          }}
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleAnchorClose}
          elevation={0}
          // getContentAnchorEl={null} FIX THIS
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <>
            {
              // userType === PlatformUserType.KEY_ADMIN ? ( // OLD LEGACY CODE
              //   <>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <HomeIcon />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.admin-dashboard')}</p>
              //       </div>
              //     </MenuItem>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/talents');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <img
              //           alt={t('navigation.roster')}
              //           src="/assets/images/search-icon.svg"
              //         />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.roster')}</p>
              //       </div>
              //     </MenuItem>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/organizations');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <img
              //           alt={t('navigation.accounts-icon')}
              //           src="/assets/images/accountsIcon.svg"
              //         />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.accounts')}</p>
              //       </div>
              //     </MenuItem>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/singletalents');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <UserIcon />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.single-accounts')}</p>
              //       </div>
              //     </MenuItem>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/cohorts');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <img
              //           alt={t('navigation.cohorts')}
              //           src="/assets/images/cohorts-nav.svg"
              //         />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.cohorts')}</p>
              //       </div>
              //     </MenuItem>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/reporting-dashboard');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <img
              //           alt={t('navigation.reporting-dashboard')}
              //           src="/assets/images/reporting-dashboard-nav.svg"
              //         />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.reporting-dashboard')}</p>
              //       </div>
              //     </MenuItem>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/activity-log');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <img
              //           alt={t('navigation.activity-dashboard')}
              //           src="/assets/images/activity-dashboard-nav.svg"
              //         />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.activity-dashboard')}</p>
              //       </div>
              //     </MenuItem>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/all-capture-links');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <img
              //           alt={t('navigation.all-capture-links')}
              //           src="/assets/images/all-capture-links-nav.svg"
              //         />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.all-capture-links')}</p>
              //       </div>
              //     </MenuItem>
              //     <MenuItem
              //       classes={{
              //         root: clsx(
              //           styles.menuItemRoot,
              //           styles.adminDashboardOption,
              //           styles.menuOption,
              //         ),
              //       }}
              //       onClick={(): void => {
              //         history.push('/segments');
              //         handleAnchorClose();
              //       }}
              //     >
              //       <ListItemIcon
              //         classes={{
              //           root: clsx(styles.listIcon, styles.iconsBorder),
              //         }}
              //       >
              //         <img
              //           alt={t('navigation.segments')}
              //           src="/assets/images/segments-nav.svg"
              //         />
              //       </ListItemIcon>
              //       <div className={styles.optionsText}>
              //         <p>{t('navigation.segments')}</p>
              //       </div>
              //     </MenuItem>
              //   </>
              // ) : (
              <>
                <MenuItem
                  classes={{
                    root: clsx(
                      styles.menuItemRoot,
                      styles.adminDashboardOption,
                      styles.menuOption,
                    ),
                  }}
                  onClick={(): void => {
                    history.push('/');
                    handleAnchorClose();
                  }}
                >
                  <ListItemIcon
                    classes={{
                      root: clsx(styles.listIcon, styles.iconsBorder),
                    }}
                  >
                    <img alt=" Roster" src="/assets/images/talent-roster.svg" />
                  </ListItemIcon>
                  <div className={styles.optionsText}>
                    <p>Roster</p>
                  </div>
                </MenuItem>
                <MenuItem
                  classes={{
                    root: clsx(
                      styles.menuItemRoot,
                      styles.adminDashboardOption,
                      styles.menuOption,
                    ),
                  }}
                  onClick={(): void => {
                    history.push('/brandprofiles');
                    handleAnchorClose();
                  }}
                >
                  <ListItemIcon
                    classes={{
                      root: clsx(styles.listIcon, styles.iconsBorder),
                    }}
                  >
                    <img
                      alt={t('navigation.brand-profiles')}
                      src="/assets/images/brand-profiles.svg"
                    />
                  </ListItemIcon>
                  <div className={styles.optionsText}>
                    <p>{t('navigation.brand-profiles')}</p>
                  </div>
                </MenuItem>
                <MenuItem
                  classes={{
                    root: clsx(
                      styles.menuItemRoot,
                      styles.adminDashboardOption,
                      styles.menuOption,
                    ),
                  }}
                  onClick={(): void => {
                    history.push('/exports');
                    handleAnchorClose();
                  }}
                >
                  <ListItemIcon
                    classes={{
                      root: clsx(styles.listIcon, styles.iconsBorder),
                    }}
                  >
                    <img
                      alt={t('navigation.export-dashboard')}
                      src="/assets/images/export-dashboard.svg"
                    />
                  </ListItemIcon>
                  <div className={styles.optionsText}>
                    <p> {t('navigation.export-dashboard')}</p>
                  </div>
                </MenuItem>

                {(userType === PlatformUserType.ORG_ADMIN || userType === PlatformUserType.MANAGER) && (
                  <MenuItem
                    classes={{
                      root: clsx(
                        styles.menuItemRoot,
                        styles.adminDashboardOption,
                        styles.menuOption,
                      ),
                    }}
                    onClick={(): void => {
                      userType === PlatformUserType.ORG_ADMIN ? history.push('/organizations') : history.push('/profiles');
                      handleAnchorClose();
                    }}
                  >
                    <ListItemIcon
                      classes={{
                        root: clsx(styles.listIcon, styles.iconsBorder),
                      }}
                    >
                      <img
                        alt={t('navigation.organization-settings')}
                        src="/assets/images/admin-profile-icon.svg"
                      />
                    </ListItemIcon>
                    <div className={styles.optionsText}>
                      <p> {t('navigation.organization-settings')}</p>
                    </div>
                  </MenuItem>
                )}
                <MenuItem
                  classes={{
                    root: clsx(
                      styles.menuItemRoot,
                      styles.adminDashboardOption,
                      styles.menuOption,
                    ),
                  }}
                  onClick={(): void => {
                    history.push('/profile');
                    handleAnchorClose();
                  }}
                >
                  <ListItemIcon
                    classes={{
                      root: clsx(styles.listIcon, styles.iconsBorder),
                    }}
                  >
                    <img
                      alt={t('navigation.account-info')}
                      src="/assets/images/account-info-circle.svg"
                    />
                  </ListItemIcon>
                  <div className={styles.optionsText}>
                    <p> {t('navigation.account-info')}</p>
                  </div>
                </MenuItem>
              </>
              // )
            }
            <MenuItem
              classes={{
                root: clsx(styles.menuItemRoot, styles.menuOption),
              }}
              onClick={(): void => {
                setShowLogoutConfirmationDialog();
              }}
            >
              <ListItemIcon
                classes={{
                  root: clsx(styles.listIcon, styles.iconsBorder),
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <div className={styles.optionsText}>
                <p> {t('navigation.logout')}</p>
              </div>
            </MenuItem>
          </>
        </Menu>
      </div>
      <span className={styles.menuScrollbarSpacer}></span>
    </div>
  );
};

export default UserSettings;
